import { Button, Col, Empty, Popconfirm, Row, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TreeUtils from '../../../../tree-utils'
import { TreeDataWrapper } from '../DrawerTilesetExplorer/CustomStyled'

const dhx = window.dhx

const FolderShortcut = ({
  projectStore,
  sketchingStore,
}) => {
  const { t } = useTranslation()
  let treeContainer = useRef(null)
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keySelected, setKeySelected] = useState('')
  const [isLoadInit, setLoadInit] = useState(false)
  const [treeDhx, setTreeDhx] = useState(null)
  const showPopconfirm = () => {
    setOpen(true)
  }
  const handleOk = async () => {
    setConfirmLoading(true)
    setOpen(false)

    const currentUserId = projectStore.projectDetail.currentUser._id
    const _projectMetadata = projectStore.projectDetail.metadata || {}
    const sketchDefault = _projectMetadata.sketchDefault || []
    const _defaultSketch = {
      userId: currentUserId,
      keyFolderAddSketchDefault: keySelected || '',
    }

    const index = sketchDefault.findIndex(item => item.userId === currentUserId)
    if (index > -1) {
      sketchDefault[index].keyFolderAddSketchDefault = keySelected || ''
    } else {
      sketchDefault.push(_defaultSketch)
    }

    _projectMetadata.sketchDefault = sketchDefault

    try {
      const res = await projectStore.updateProjectMetadata({
        metadata: _projectMetadata,
        store: 'metadata',
      })
      projectStore.projectDetail.metadata = res.metadata
    } catch (err) {
      console.error(err)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const traversalTree = (data, parentKey, keyParentOpens) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          parentKey,
          items: traversalTree(item.children, item.key, keyParentOpens),
          value: item.title,
          icon: {
            folder: 'dxi dxi-folder',
            openFolder: 'dxi dxi-folder-open',
            file:
              item.type === 'FOLDER'
                ? 'dxi dxi-folder'
                : 'dxi dxi-file-outline',
          },
          id: item.key,
          opened: keyParentOpens.includes(item.key),
        }
      }
      return {
        ...item,
        parentKey,
        value: item.title,
        icon: {
          folder: 'dxi dxi-folder',
          openFolder: 'dxi dxi-folder-open',
          file:
            item.type === 'FOLDER' ? 'dxi dxi-folder' : 'dxi dxi-file-outline',
        },
        id: item.key,
        opened: keyParentOpens.includes(item.key),
      }
    })
  }
  const [treeData, setTreeData] = useState()
  const getKeyStatuTree = treeDataProject => {
    const clonedTreeData = JSON.parse(JSON.stringify(treeDataProject))
    const defaultSketch =
      projectStore.projectDetail?.metadata?.sketchDefault?.find(
        item => item.userId === projectStore.projectDetail.currentUser._id
      )

    let keyDefaultChecked = ''
    let keyParentOpens = []

    if (sketchingStore.currentEditSketch?.id) {
      const keySketchInTreeData = TreeUtils.searchParentTreeNode(
        clonedTreeData,
        'sketchId',
        sketchingStore.currentEditSketch?.id
      )
      if (keySketchInTreeData) {
        keyDefaultChecked = keySketchInTreeData.key
        keyParentOpens =
          TreeUtils.searchTreeNodeForParents(
            clonedTreeData,
            'key',
            keyDefaultChecked
          )?.map(x => x.key) || []
      }
    } else if (defaultSketch?.keyFolderAddSketchDefault) {
      keyDefaultChecked = defaultSketch.keyFolderAddSketchDefault
      keyParentOpens =
        TreeUtils.searchTreeNodeForParents(
          clonedTreeData,
          'key',
          keyDefaultChecked
        )?.map(x => x.key) || []
    } else if (TreeUtils.searchSketchFolder(clonedTreeData)) {
      keyDefaultChecked = TreeUtils.searchSketchFolder(clonedTreeData).key
    }

    return {
      keyParentOpens,
      keyDefaultChecked,
    }
  }

  useEffect(() => {
    const treeDataProject = JSON.parse(
      JSON.stringify(projectStore.projectDetail.treeData || [])
    )
    const keyStatuTree = getKeyStatuTree(treeDataProject) || []
    setTreeData(
      traversalTree(
        TreeUtils.filterTreeFolders(treeDataProject),
        null,
        keyStatuTree.keyParentOpens
      ) || []
    )
    sketchingStore.setKeyTreeNodeSaveSketch(keyStatuTree.keyDefaultChecked)
    //setLoadInit(true);
  }, [projectStore.projectDetail.treeData])

  useEffect(() => {
    if (treeContainer && treeData?.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!isLoadInit) {
        const tree = new dhx.Tree(treeContainer, {})
        // tree.data.parse(treeData || []);

        tree.events.on('itemClick', function (id) {
          setKeySelected(id)
          sketchingStore.setKeyTreeNodeSaveSketch(id)
        })

        if (sketchingStore.keyTreeNodeSaveSketch) {
          tree.events.fire('ItemClick', [sketchingStore.keyTreeNodeSaveSketch])
        }
        setTreeDhx(tree)
        setLoadInit(true)
      }
    }
  }, [treeContainer, treeData, isLoadInit])

  useEffect(() => {
    if (treeData?.length > 0 && isLoadInit && treeDhx) {
      treeDhx.data.parse(treeData || [])
    }
  }, [treeData, isLoadInit, treeDhx])

  useEffect(() => {
    if (sketchingStore.currentEditSketch?.id && isLoadInit && treeDhx) {
      const treeDataProject = JSON.parse(
        JSON.stringify(projectStore.projectDetail.treeData || [])
      )
      const keyStatuTree = getKeyStatuTree(treeDataProject)
      const parentsOld =
        TreeUtils.searchTreeNodeForParents(
          treeDataProject,
          'key',
          sketchingStore.keyTreeNodeSaveSketch
        )?.map(x => x.key) || []
      parentsOld.map(key => {
        treeDhx.collapse(key)
      })
      keyStatuTree.keyParentOpens.map(key => {
        treeDhx.expand(key)
      })
      sketchingStore.setKeyTreeNodeSaveSketch(keyStatuTree.keyDefaultChecked)
    }
  }, [sketchingStore.currentEditSketch, isLoadInit, treeDhx])

  return (
    <Spin spinning={false} tip={t('loading-tree')}>
      <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Popconfirm
          title="Set default forder add new sketch"
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}>
          <Button
            type="primary"
            onClick={showPopconfirm}
            disabled={!keySelected}>
            Set default
          </Button>
        </Popconfirm>
      </Col>
      <Row style={{ paddingTop: 8 }}>
        {treeData && treeData.length ? (
          <TreeDataWrapper width="100%">
            <div
              style={{ minWidth: 270 }}
              ref={el => (treeContainer = el)}></div>
          </TreeDataWrapper>
        ) : (
          <Row justify="center">
            <Empty description={<span>{t('no-data')}</span>} />
          </Row>
        )}
      </Row>
    </Spin>
  )
}

export default inject(
  'projectStore',
  'sketchingStore',
  'projectSettingStore',
  'usersStore'
)(observer(FolderShortcut))
