/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo, useContext } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import DefaultTemplate from '../../layout/DefaultTemplate'
import { inject, observer } from 'mobx-react'
import PDetailAddResources from './ModalAddResources'
import ModalUpdateResources from './ModalUpdateResources'
import ModalDataAttribute from './ModalDataAttribute'
import DrawerTilesetExplorer from './DrawerTilesetExplorer'
import DrawerProjectDashBoard from './DrawerProjectDashBoard'
import PhotoViewer360 from './PhotoViewer360/PhotoViewer360'
import { toJS } from 'mobx'
import cesiumNavMixin from '../../../assets/cesium-navigation/index'
import '../../../assets/cesium-navigation/index.css'
import './style.less'
import { assetUrl, bingMapKey, cesiumToken } from '../../../config'
import DrawerCaptureViews from './DrawerCaptureViews'
import DrawerProjectInfo from './DrawerProjectInfo'
import TimeSlider from './TimeSlider'
import FollowAlignmentTool from '../../helper/FollowAlignmentTool'
import AttributesPanel from './ObjectDetail/AttributesPanel'
import { notification, message } from 'antd'
import MeasureDistance from '../../helper/MeasureDistance'
import MeasurePoint from '../../helper/MeasurePoint'
import MeasureArea from '../../helper/MeasureArea'
import MeasurePolyline from '../../helper/MeasurePolyline'
import MoveTile from '../../helper/MoveTile'
import PickLocation from '../../helper/PickLocation'
import Gps from '../../helper/Gps'
import DrawSketch from './DrawSketch'
import SketchView from './SketchView'
import ImageryView from './ImageryView'
import CityDB3DView from './CityDB3DView'
import GeoJsonView from './GeoJsonView'
import I3SDataProvider from './I3SDataProvider'
import SettingControl from '../../helper/SettingControl'
import SettingRenderResolutionControl from '../../helper/SettingRenderResolutionControl'
import RecordDataControl from '../../helper/RecordDataControl'
import DrawerUserNavigationSetting from './DrawerUserNavigationSetting'
import TopicListDrawer from '../TopicPage/TopicListDrawer'
import TopicEditorDrawer from '../TopicPage/TopicEditorDrawer'
import QueryDrawer from '../QueryPage/'
import Drawer3DView from '../TopicPage/Drawer3DView/Drawer3DView'
import SelectObjectModelControl from './DrawerGanttPanel/SelectObjectModelControl'
import Topic3DLocationControl from '../TopicPage/Topic3DLocationControl/Topic3DLocationControl'
import Topic3DObjectsControl from '../TopicPage/Topic3DObjectsControl'
import TopicLocationView from '../TopicPage/TopicLocationView'
import ObjectDetail from './ObjectDetail'
import DrawerFeedbackAnswer from '../FeedbackEditorPage/DrawerFeedbackAnswer'
import FeedbackAnswerGenericVisualization from '../FeedbackEditorPage/FeedbackGenericVisualization'
import FeedbackformVisualization from '../FeedbackEditorPage/FeedbackformVisualization'
import DrawerProjectSetting from '../ProjectSettingsPage/DrawerProjectSetting'
import DrawerFeedbackEditor from '../FeedbackEditorPage/DrawerFeedbackEditor'
import DrawerWorkflow from '../WorkflowPage/DrawerWorkflow'
import DrawerProjectTeams from '../ProjectTeamsPage/DrawerProjectTeams'
import DrawerProjectSketch from '../ProjectSketchPage/DrawerProjectSketch'
import LocationControl from '../FeedbackEditorPage/LocationControl'
import HiddenAreaView from './DrawerHiddenTiles/HiddenAreaView'
import DocFile from '../../../assets/form-elems/Resource DOC.png'
import JPGFile from '../../../assets/form-elems/Resource JPG.png'
import PDFFile from '../../../assets/form-elems/Resource PDF.png'
import PNGFile from '../../../assets/form-elems/Resource PNG.png'
import PPTFile from '../../../assets/form-elems/Resource PPT.png'
import TIFFile from '../../../assets/form-elems/Resource TIFF.png'
import TXTFile from '../../../assets/form-elems/Resource TXT.png'
import XLSFile from '../../../assets/form-elems/Resource XLS.png'
import HTTPlinks from '../../../assets/form-elems/Resource Link.png'
import GenericFile from '../../../assets/form-elems/generic-doc.png'
import _ from "lodash";
import { isIOS, isSafari } from "react-device-detect";
import ClippingPlaneVertical from '../../helper/ClippingPlaneVertical'
import ClippingPlaneHorizontal from '../../helper/ClippingPlaneHorizontal'
import ClippingPlaneAlignment from '../../helper/ClippingPlaneAlignment'
import DrawHiddenArea from './DrawerHiddenTiles/DrawHiddenArea'
import {
  setColorTile,
  setTileConditions,
  getCurrentModel,
  findModelByUrl,
  hpr4ZoomTo,
  createImageFileFromBase64,
  getCenterScreenCoordinates,
  merge_array,
  getModelUrBySrc,
  CalRotationModel,
  goViewpoint,
  getImageryLayerFeatures,
  loadtModelOnPlane,
  transformECFModel,
  setCZMLStyle,
  setxDCamera,
  getCurrentCamera,
  setColorPointCloud,
  isCloudPointXDEngineEllipsoid,
  isIFCEllipsoid,
  isE57XDEngineEllipsoid,
  checkSketchMeshModel
} from '../../helper/CesiumUtils'
import Webcam from 'react-webcam'
import { Viewer, Cesium3DTileset, SkyAtmosphere, SkyBox, Clock, PostProcessStage, AmbientOcclusion, CzmlDataSource, Entity, BillboardGraphics, KmlDataSource, GeoJsonDataSource } from 'resium'
import { InfoCircleOutlined } from '@ant-design/icons'
import { viewerDragDropMixinxd } from '../../../lib/viewerDragDropMixin'
import {
  Matrix4,
  IonResource,
  Cartesian3,
  Cartesian4,
  Transforms,
  Cesium3DTileColorBlendMode,
  ScreenSpaceEventType,
  ScreenSpaceEventHandler,
  PostProcessStageLibrary,
  Color,
  Math as CesiumMath,
  HeadingPitchRange,
  CameraEventType,
  KeyboardEventModifier,
  BingMapsImageryProvider, ShadowMode,
  BingMapsStyle,
  buildModuleUrl,
  ProviderViewModel,
  createDefaultImageryProviderViewModels,
  viewerCesium3DTilesInspectorMixin,
  JulianDate,
  Rectangle,
  Cesium3DTileFeature,
  PointCloudShading,
  CesiumTerrainProvider,
  EllipsoidTerrainProvider,
  knockout,
  Ellipsoid,
  Cartographic,
  defined, VerticalOrigin, HorizontalOrigin, DistanceDisplayCondition, PinBuilder, Resource,
  sampleTerrainMostDetailed,
  createGooglePhotorealistic3DTileset,
  Cesium3DTileStyle,
  Credit
} from 'cesium'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import uuid from 'uuid'
import { ProjectRequest, ModelAttributeRequest } from '../../../requests'
import { useMediaQuery } from 'react-responsive'
import DrawerHiddenTiles from './DrawerHiddenTiles'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import IFCSetting from '../../helper/IFCSetting'
import AmbientOccSetting from '../../helper/AmbientOccSetting'
import DropMultipleFiles3DView from './DropMultipleFiles3DView'
import { saveProjectCurrentViewpoint, getTopic4D, loadImage, getVisileDataTree, addViewPointCameraData, generateUsername, checkModelIsReady, checkOldViewPoint } from '../../../lib/projectLib'
import DrawerTopicReport from '../TopicPage/DrawerTopicReport/DrawerTopicReport'
import UndergroundSetting from '../../helper/UndergroundSetting'
import DrawerFeatureSetting from './DrawerFeatureSetting'
import XDTwinGuideModal from './XDTwinGuideModal/XDTwinGuideModal'
import TransparentSetting from '../../helper/TransparentSetting'
import FogSetting from '../../helper/FogSetting'
import LightSetting from '../../helper/LightSetting'
import ImporterSetting from '../../helper/ImporterSetting'
import BackgroundColorSetting from '../../helper/BackgroundColorSetting'
import ModalAddProjectTemplate from './ModalAddProjectTemplate/index';
import DrawerFeedbackReport from './DrawerFeedbackReport/DrawerFeedbackReport'
import TreeUtils from '../../../tree-utils'
import Utils from '../../../utils'
import { useTranslation } from 'react-i18next';
import LicenseExpirationNotificationModal from './LicenseExpirationNotificationModal'
import axios from 'axios'
import { apiUrl } from './../../../config'
import DrawerCustomAttribute from './DrawerCustomAttribute'
import fileStore from '../../../stores/fileStore'
import DrawerGenericReport from './DrawerGenericReport'
import DrawerProjectLink from '../ProjectLinkPage/DrawerProjectLink'
import { SocketContext } from '../../../socket-context';
import DrawerUserGroup from './DrawerUserGroup'
import GroupUtils from '../../../group-utils'
import queryString from 'query-string'
import ModalProcessingModel3D from './ModalProcessingModal3D/ModalProcessingModel3D'
import DrawerGanttPanel from './DrawerGanttPanel'
import DrawerCalculation from './DrawerCalculation/DrawerCalculation'
import { onClearAlignmentCachingObject } from './DrawerGanttPanel/ModalLinkGanttToSavedQuery/utils'
import StickyLogo from '../../elements/StickyLogo'

const Cesium = require('cesium')

const ProjectDetailPage = props => {
  const {
    commonStore,
    uiStore,
    projectStore,
    capturesStore,
    schedulingStore,
    sketchingStore,
    usersStore,
    topicStore,
    feedbackStore,
    workflowStore,
    adminStore,
    history, location,
    projectSettingStore,
    organizationStore,
    alignmentStore,
    polylineCutToolStore,
    authStore,
    modelStore,
    measureStore,
    userGroupStore,
    projectTeamsStore,
    objectQueryStore,
    projectGanttStore,
    calculationStore
  } = props
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const viewerRef = useRef(null)
  const webcamRef = useRef(null)
  const [measurePoints, setMeasurePoints] = useState(false)
  const [measureDistance, setMeasureDistance] = useState(false)
  const [measureArea, setMeasureArea] = useState(false)
  const [measurePolyline, setMeasurePolyline] = useState(false)
  const [moveTile, setMoveTile] = useState(false)
  const [beforeVisibleTileset, setBeforeVisibleTileset] = useState([])
  const [beforeVisibleSketch, setBeforeVisibleSketch] = useState([])
  const [gpsView, setGpsView] = useState(false)
  const [drawingSketch, setDrawingSketch] = useState(false)
  const [sketchView, setSketchView] = useState(false)
  const [drawingPolylineCut, setDrawingPolylineCut] = useState(false)
  const [hiddenAreaView, setHiddenAreaView] = useState(false)
  const [imageryView, setImageryView] = useState(false)
  const [pickLocView, setPickLocView] = useState(false)
  const [fogView, setFogView] = useState(false)
  const [settingView, setSettingView] = useState(false)
  const [cityDB3DView, setCityDB3DView] = useState(false)
  const [geoJsonView, setGeoJsonView] = useState(false)
  const [i3SDataProvider, setI3SDataProvider] = useState(false)
  const [redirectToLogin, setRedirecToLogin] = useState(false)
  const [selectedTerrain, setSelectedTerrain] = useState(false)
  const [topicLocationView, setTopicLocationView] = useState(false)
  const [feedbackVisualization, setFeedbackVisualization] = useState(false)
  const [feedbackformVisualization, setFeedbackformVisualization] = useState(false)
  const [orginalTileViews, setOrginalTileViews] = useState([])
  const [settingRenderResolutionView, setSettingRenderResolutionView] = useState(false)
  const [renderRecordDataView, setRenderRecordDataView] = useState(false)
  const [IFCSettingModal, setIFCSettingModal] = useState(false)
  const [UndergroundSettingModal, setUndergroundSettingModal] = useState(false)
  const [TransparentSettingModal, setTransparentSettingModal] = useState(false)
  const [FogSettingModal, setFogSettingModal] = useState(false)
  const [LightSettingModal, setLightSettingModal] = useState(false)
  const [ImporterSettingModal, setImporterSettingModal] = useState(false)
  const [backgroundColorSettingModal, setBackgroundColorSettingModal] = useState(false)
  const [AmbientOccSettingModal, setAmbientOccSettingModal] = useState(false)
  const [clippingView, setClippingView] = useState(false)
  const [dragDrop3DView, setDragDrop3DView] = useState(false)
  const [tempDisableRequestRender, setTempDisableRequestRender] = useState(false)
  const [ambientSetting, setAmbientSetting] = useState({
    intensity: 1.0,
    bias: 0.4,
    lengthCap: 0.3,
    stepSize: 2,
    frustumLength: 100.0,
    blurStepSize: 2
  })
  const [cesium3DtileChildrenHidden, setCesium3DtileChildrenHidden] = useState([])
  const [renderTopic3DLocation, setRenderTopic3DLocation] = useState(false)
  const [renderTopic3DObjects, setRenderTopic3DObjects] = useState(false)
  const [renderSelectObjectModelControl, setRenderSelectObjectModelControl] = useState(false)
  
  const [renderAddLocationFeedbackEditor, setRenderAddLocationFeedbackEditor] = useState(false)
  const [objectInfo, setObjectInfo] = useState();
  const [refPointView, setRefPointView] = useState(false)

  // const delayedSaveProjectCurrentViewpoint = useRef(_.debounce((projectStore, currentUserId, viewerRef, timeSlider, modelHiden) => saveProjectCurrentViewpoint(currentUserId, viewerRef, timeSlider, modelHiden), 500)).current;
  // const fragmentShader = [
  //   "in vec2 v_textureCoordinates ;",
  //   "uniform sampler2D colorTexture;",
  //   "out vec4 vFragColor;",
  //   "void main() {",
  //   //"vec4 textureColor = texture2D(u_texture, v_textureCoordinates);",
  //   "vec4 inputColor = texture(colorTexture, v_textureCoordinates);",
  //   "if (inputColor.r == 0.0 && inputColor.g == 0.0 && inputColor.b ==1.0 && inputColor.a ==1.0) ",
  //   "vFragColor = czm_packDepth(texture(colorTexture, v_textureCoordinates).r);",
  //   //"gl_FragColor = vec4(inputColor.r, inputColor.g, inputColor.b, 0.0);",
  //   //"discard;",
  //   "else",

  //   "vFragColor = inputColor;",
  //   "}"
  // ].join('\n');

  const [cityDB3DViewLink, setCityDB3DViewLink] = useState(false)
  const [geoJsonViewLink, setGeoJsonViewLink] = useState(false)
  const [imageryViewLink, setImageryViewLink] = useState(false)
  // const [i3SDataProviderLink, setI3SDataProviderLink] = useState(false)
  const [loadInit, setLoadInit] = useState(false)
  const socket = useContext(SocketContext);
  const [modalProcessingModel3D, setModalProcessingModel3D] = useState(false)
  // Firefox 1.0+

  function startReRender() {
    if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget) {
      if (viewerRef.current.cesiumElement.scene.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
    }
  }
  var silhouetteGreen = PostProcessStageLibrary.createEdgeDetectionStage()
  silhouetteGreen.uniforms.color = Color.LIME
  silhouetteGreen.uniforms.length = 0.01

  // const Sentinel2 = new IonImageryProvider({ assetId: 3954 })
  // const defautHpr = new HeadingPitchRange(0, -Math.PI / 2, 500)
  // const defaultColor = new Color(1, 1, 1, 1)
  var intervalID = 0;
  var intervalLicenses = 0;
  var intervalVisibleData = 0;
  var intervalUpdateLas = 0;
  var intervalUpdateLastSettings = 0;
  var intervalCheckPointCloudReady = 0;

  const checkXDEnginePointCloudReady = () => {
    const modelList = projectStore?.modelList || [];
    const waittingLists = modelList.filter( c => c.type ==='cloudpoint' && c.status === "waitting_process") || [];
    if(waittingLists?.length > 0){
      const ids = waittingLists.map(c => (c.id || c?._id));
      projectStore.checkXDEnginePointCloudReady(ids)
    }
  }

  const updateModel = (model) => {
    for (let index = 0; index < projectStore.modelList.length; index++) {
      const m = projectStore.modelList[index];
      if (m._id === model._id) {
        projectStore.modelList[index] = model
      }
    }
  }

  const updatePlaneMatix = async (model, projectData, tile, isProjectLink, isZoomToModel) => {
    // let noTrans = false
    // if !model, model is Deleted, model=landxmlBackground, or if model is landxml, ifc check !crs.data(model has converted) then return
    if (!model || model.isDeleted) return
    if (!tile) {
      let fkey = model._id + '-tile'
      tile = projectStore.tileViews.find(t => t.key === fkey)
      if (!tile) return false
      if (!tile.ref) return false
      if (!tile.ref.current) return false
      if (!tile.ref.current?.cesiumElement?.tilesLoaded) return false

      tile = tile.ref.current.cesiumElement
    }

    const coordinateSystemCode = (projectData?.tilesetData?.coordinateSystem && projectData?.tilesetData?.coordinateSystem?.code) || projectData?.coordinateSystem
    const adjustPointCloudElevation = async (pmodel, pcoordinateSystemCode) => {
      let _model = pmodel
      _model.data.isRecalculateModelCenter = false
      //projectStore.setLoadingProgress(true)

      // check, if it's the first time, if don't have fileOrigo, then calculate, but in case of rebuild, don't recalculate
      if (!_model.data.saveMatrix.fileOrigo || (JSON.stringify(_model.data.saveMatrix.fileOrigo) === JSON.stringify({ x: 0, y: 0, z: 0 }))) {
        let matrix4 = tile.root.computedTransform
        var currentTranslation = new Cartesian3();
        Matrix4.getTranslation(matrix4, currentTranslation);
        var catographic = Cartographic.fromCartesian(currentTranslation, Ellipsoid.WGS84);
        var long = catographic.longitude * Cesium.Math.DEGREES_PER_RADIAN;
        var lat = catographic.latitude * Cesium.Math.DEGREES_PER_RADIAN;
        var height = catographic.height;
        var geoidCode = projectData.elevationSystem ? projectData.elevationSystem : 'None'

        if (geoidCode !== 'None') {
          await projectStore.calculateHeighCenterModelFlowGeoid(lat, long, height, geoidCode).then(async response => {
            if (response.Status === "OK") {
              var matTransform = Matrix4.fromTranslation(new Cartesian3(0, 0, response.Heigh - height));
              var matFinal = new Matrix4();
              Matrix4.multiply(matrix4, matTransform, matFinal);
              Matrix4.getTranslation(matFinal, currentTranslation);
            }
          }).catch(error => {
            console.log(error)
          })
        }

        var fileOrigo = new Cartesian3();
        fileOrigo.x = currentTranslation.x;
        fileOrigo.y = currentTranslation.y;
        fileOrigo.z = currentTranslation.z;
        var xyzLocal = [0, 0, 0]

        var fileorigocartographic = Cartographic.fromCartesian(fileOrigo);
        // if project setting as 4326
        if ((['4326', '4756'].includes(pcoordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
          xyzLocal = [
            fileorigocartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.height
          ]
        } else {
          await projectStore.convertWGS84ToProjectPlaneCoordinate(
            fileorigocartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.height,
            fileorigocartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN,
            fileorigocartographic.height,
            pcoordinateSystemCode,
            projectData.elevationSystem ? projectData.elevationSystem : 'None'
          ).then(res => {
            if (res.Status === 'OK') {
              xyzLocal = [res.Point[1], res.Point[0], res.Point[2]]
            }
          })
        }

        _model.data.saveMatrix.fileOrigo = fileOrigo
        _model.data.saveMatrix.xyzLocal = xyzLocal
        _model.crs.LocalOrg = [0, 0, 0]
        _model.crs.ModelCenter = [0, 0, 0]
      }

      return await projectStore.update3DMODELS(pmodel._id, _model).then(res => {
        updateModel(res.data)
        return res.data
      }).catch(error => {
        console.log(error)
        return false
      }).finally(() => {
        projectStore.setLoadingProgress(false)
      })

    }
    //if model kmz, geojson, geotifff, landxmlbackground, ifcEllipsoid, pointcloud with epsg !==projectepsg => do nothing
    if (!['kmz', 'geojson', 'geotiff', 'landxmlBackground'].includes(model.type) && !isIFCEllipsoid(model) && !isCloudPointXDEngineEllipsoid(model) && !isE57XDEngineEllipsoid(model)) {
      if (!model.data.ext && model.type === 'unknown' && model.data.isIdenticalMatrix) {
        let xyzLocal = new Cartesian3.fromArray(model.data.saveMatrix.xyzLocal)
        if ((['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree') && JSON.stringify(model.data.saveMatrix.xyzLocal) !== JSON.stringify([0, 0, 0])) {
          xyzLocal = new Cartesian3.fromDegrees(model.data.saveMatrix.xyzLocal[1], model.data.saveMatrix.xyzLocal[0], model.data.saveMatrix.xyzLocal[2])
        }
        if (model.data.saveMatrix.fileOrigo && xyzLocal) {
          const t = transformECFModel(model.data.saveMatrix.fileOrigo, xyzLocal);

          if (t && tile?.root?.transform)
            tile.root.transform = t.modelMatrix;
        }
      } else if (projectData.tilesetData?.RefPoint) {
        var refProject = Cartesian3.fromDegrees(projectData.tilesetData.RefPoint[1], projectData.tilesetData.RefPoint[0], projectData.tilesetData.RefPoint[2])
        var refLocalProject = Cartesian3.fromArray(projectData.tilesetData.refLocalProject ? projectData.tilesetData.refLocalProject : [0, 0, 0])

        //For mesh model, clountpoint first load need get fileOrigo, xyzLocal, LocalOrg, ModelCenter
        if (model.data.isRecalculateModelCenter && !Matrix4.equals(tile.root.computedTransform, Matrix4.IDENTITY)) {
          //projectStore.setLoadingProgress(true)

          //if model is cloundpoint        
          if ((model.data.ext === '.las' || model.data.ext === '.laz') && !model.crs.version) {
            let resp = await adjustPointCloudElevation(model, coordinateSystemCode)
            if (resp) {
              model = resp
            }
          } else {
            let matrix4 = tile.root.computedTransform
            let _data = {
              initPos: [model.crs.initPos.cartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.height],
              modelMatrix: [matrix4[0], matrix4[4], matrix4[8], matrix4[12], matrix4[1], matrix4[5], matrix4[9], matrix4[13], matrix4[2], matrix4[6], matrix4[10], matrix4[14], matrix4[3], matrix4[7], matrix4[11], matrix4[15]].join(',')
            }
            // const _xyzLocal = model?.data?.saveMatrix?.xyzLocal ;

            // if (_xyzLocal[0] !== 0 || _xyzLocal[1] !== 0 || _xyzLocal[2] !== 0) {
            //   let vecInputFromDialog2 = new Cartesian3.fromArray(_xyzLocal);
            //   console.log('vecInputFromDialog2',vecInputFromDialog2);
            //   let projectEpsgCode = projectStore.projectDetail?.tilesetData?.coordinateSystem?.code ? projectStore.projectDetail?.tilesetData?.coordinateSystem?.code : '4326';
            //   projectStore.setLoadingProgress(true);
            //   await projectStore.convertProjectPlaneToWGS84(vecInputFromDialog2.y, vecInputFromDialog2.x, vecInputFromDialog2.z, projectEpsgCode, projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
            //   ).then(response => {
            //     if (response.Status === "OK" && response.Point) {
                 
            //       let _fileorigo = Cartesian3.fromDegrees(response.Point[1], response.Point[0], response.Point[2])
            //       // Convert Cartesian3 to Cartographic
            //       const cartographic = Cesium.Cartographic.fromCartesian(_fileorigo);
            //       _data.initPos = [cartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN, cartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN, cartographic.height]

            //       console.log('cartographic', toJS(cartographic));
            //       console.log('_data.initPos', toJS(_data.initPos));
            //       // newData.crs.initPos.cartographic = cartographic;
            //     } 
            //   })
            // }

            if ((!['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'metre')) {
              let xyzLocal = [0, 0, 0]
              let fileOrg = [0, 0, 0]
              let LocalOrg = [0, 0, 0]
              let ModelCenter = [0, 0, 0]
              let getMeshModelInfo = false

              _data.projRefPoint = projectData.tilesetData.RefPoint
              _data.epsg = coordinateSystemCode
              _data.elevationSystem = projectData.elevationSystem ? projectData.elevationSystem : 'None'

              //projectStore.setLoadingProgress(true)
              await projectStore.getMeshModelInfo(_data).then(async response => {
                if (response.Status === "OK") {
                  getMeshModelInfo = true
                  xyzLocal =  [response.LocalOrg[1], response.LocalOrg[0], response.LocalOrg[2]]
                  fileOrg = response.fileOrg
                  LocalOrg =  response.LocalOrg
                  ModelCenter = response.ModelCenter
                }
              }).catch(error => {
                console.log(error)
              })

              //if Mesh model nằm ngoài vùng epsg getMeshModelInfo sẽ lỗi, nếu lỗi sẽ call getMeshModelInfoOnWGS84 để tính model center
              if (!getMeshModelInfo) {
                delete _data.projRefPoint;
                delete _data.epsg;
                delete _data.elevationSystem;
                //projectStore.setLoadingProgress(true)
                await projectStore.getMeshModelInfoOnWGS84(_data).then(async response => {
                  if (response.Status === "OK") {
                    xyzLocal = [0, 0, 0]
                    fileOrg = [model.crs.initPos.position.y, model.crs.initPos.position.x, model.crs.initPos.position.z]
                    LocalOrg = [0, 0, 0]
                    ModelCenter = response.ModelCenter
                    message.info(t('model-is-not-in-project-coordinate-or-elevation-system'), 5)
                  }
                }).catch(error => {
                  console.log(error)
                })
              }

              let _model = model
              _model.data.isRecalculateModelCenter = false
              // _model.data.saveMatrix.fileOrigo = Cartesian3.fromDegrees(fileOrg[1], fileOrg[0], fileOrg[2])
              // _model.data.saveMatrix.xyzLocal = xyzLocal
              // _model.crs.LocalOrg = [LocalOrg[1], LocalOrg[0], LocalOrg[2]]
              // _model.crs.ModelCenter = ModelCenter

              //projectStore.setLoadingProgress(true)
              await projectStore.update3DMODELS(model._id, _model).then(res => {
                updateModel(res.data)
              }).catch(error => {
                console.log(error)
              })
              projectStore.setLoadingProgress(false)
            } else {// for prj WGS84 4326              
              await projectStore.getMeshModelInfoOnWGS84(_data).then(async response => {
                if (response.Status === "OK") {
                  let _model = model
                  _model.data.isRecalculateModelCenter = false
                  _model.data.saveMatrix.xyzLocal = [model.crs.initPos.cartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.height]
                  _model.data.saveMatrix.fileOrigo = Cesium.Cartesian3.fromDegrees(model.crs.initPos.cartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN, model.crs.initPos.cartographic.height)
                  _model.crs.LocalOrg = [_model.data.saveMatrix.fileOrigo.x, _model.data.saveMatrix.fileOrigo.y, _model.data.saveMatrix.fileOrigo.z]
                  _model.crs.ModelCenter = response.ModelCenter

                  await projectStore.update3DMODELS(model._id, _model).then(res => {
                    updateModel(res.data)
                  }).catch(error => {
                    console.log(error)
                  })
                }
              }).catch(error => {
                console.log(error)
              })
              projectStore.setLoadingProgress(false)
            }
          }
        }

        //Check if model has fileOrigo then calculate tile.modelMatrix follow origo
        if (model.data?.saveMatrix?.fileOrigo) {
          if ((model.sourceType === 'external' && model.type === 'unknown')) {
            //model.data.orginalMatrix = 
          } else {
            let checkRef = false
            if ((!['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'metre') && projectData.tilesetData.georeferenced) {
              checkRef = true
            }

            if (checkRef) {
              if (!projectData.tilesetData.refLocalProject && model.crs.LocalOrg) {
                refLocalProject = Cartesian3.fromArray(model.crs.LocalOrg)
              }
              if (model.crs.ModelCenter && model.crs.LocalOrg && model.data.saveMatrix.xyzLocal) {
                let _modelmatrix = loadtModelOnPlane(refProject, refLocalProject,
                  Cartesian3.fromArray(model.crs.LocalOrg),
                  Cartesian3.fromArray(model.data.saveMatrix.xyzLocal),
                  Cartesian3.fromArray(model.crs.ModelCenter),
                  model.data.saveMatrix.hpr.h,
                  model.data.saveMatrix.hpr.p,
                  model.data.saveMatrix.hpr.r,
                  model.data.saveMatrix.scale,
                  model,
                  projectData.headingRotation)
                if (_modelmatrix && !isNaN(_modelmatrix[0]) && tile?.root?.transform) {
                  tile.root.transform = _modelmatrix;
                  // Save model placement into 3DTile JSON export 
                  let _modelMatrix = _.values(_modelmatrix);
                  if (!_.isEqual(model.data.transform, _modelMatrix)) {
                    let _newData = model.data;
                    _newData.transform = _modelMatrix;
                    await projectStore.update3DMODELS(model._id, { data: _newData })
                  }
                }
              }
            } else { // for project 4326 or khác 4326 nhưng không có georeferenced (là project mà ko có landxml or ifc coodinates)
              const t = CalRotationModel(model.data.saveMatrix.fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), model.data.saveMatrix.hpr.h, model.data.saveMatrix.hpr.p, model.data.saveMatrix.hpr.r, model.data.saveMatrix.scale)
              if (t && tile?.root?.transform) {
                tile.root.transform = t
                // Save model placement into 3DTile JSON export
                let _modelMatrix = _.values(t);
                if (!_.isEqual(model.data.transform, _modelMatrix)) {
                  let _newData = model.data;
                  _newData.transform = _modelMatrix;
                  await projectStore.update3DMODELS(model._id, { data: _newData })
                }
              }
            }
          }
        }
      } else {
        // no ref point yet and model is cloud point
        if (model.data.ext === '.las' || model.data.ext === '.laz') {
          if (model.data.isRecalculateModelCenter && !Matrix4.equals(tile.root.computedTransform, Matrix4.IDENTITY)) {
            let resp = await adjustPointCloudElevation(model, coordinateSystemCode)
            if (resp) {
              model = resp
            }
          }
          const t = CalRotationModel(model.data.saveMatrix.fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), model.data.saveMatrix.hpr.h, model.data.saveMatrix.hpr.p, model.data.saveMatrix.hpr.r, model.data.saveMatrix.scale)
          if (t && tile?.root?.transform) {
            let deferred = defer();
            try {
              tile.root.transform = t
            } catch (error) {
              deferred.reject(new Cesium.DeveloperError('Failed to load'));
              console.log('error', error)
            }
          }
        }
      }
    }

    //reset new model id (case close edit for new model)    
    if (model._id === projectStore.newModelId) {
      const viewer = viewerRef.current?.cesiumElement
      if (viewer) {
        let deferred = defer();
        try {
          if (!isZoomToModel) {
            viewer.zoomTo(tile)
          }
        } catch (error) {
          deferred.reject(new Cesium.DeveloperError('Failed to load'));
          console.log('error', error)
          alert(t('model-is-not-ready'))
        }
      }
      projectStore.setCurrentModelId(projectStore.newModelId)
      projectStore.setNewModelId(false)
    }

    // load Alignment
    if (model?.data?.Alignment || model.crs?.Alignment) {
      let alignmentKey = model._id + '-alignment'
      const Alignment = projectStore.Alignment.find(t => {
        return t && t.key === alignmentKey
      })
      if (!Alignment) {
        let uriAlignment = '/Alignment.czml'
        const aref = React.createRef()
        const onAlignmentLoadByModel = function (source) {
          onAlignmentLoad(source, alignmentKey, isProjectLink)
        }
        projectStore.setAlignment([...projectStore.Alignment,
        <CzmlDataSource
          prop
          key={alignmentKey}
          ref={aref}
          data={assetUrl + model.hash + uriAlignment}
          onLoad={onAlignmentLoadByModel}
        />
        ])
      } else {
        projectStore.setReloadAlignment(true);
      }
    }
  }

  function defer() {
    var deferred = {};
    var promise = new Promise(function (resolve, reject) {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    deferred.promise = promise;
    return deferred;
  }
  //effect headingRotation change in project setting
  useEffect(() => {
    if (!projectStore.changeHeadingRotation) return
    projectStore.modelList.forEach(model => {
      updatePlaneMatix(model, projectStore.projectDetail)
    });
    projectStore.setChangeHeadingRotation(false)
  }, [projectStore.changeHeadingRotation])

  //Effect for rebuild model
  useEffect(() => {
    if (!projectStore.rebuildModel) return
    projectStore.modelList.forEach(model => {
      updatePlaneMatix(model, projectStore.projectDetail)
    });
    projectStore.setRebuildModel(false)
    startReRender()
  }, [projectStore.rebuildModel])

  //Effect for add new model model
  useEffect(() => {
    if (!projectStore.newModelId) return
    // const isZoomToModel = projectStore?.previousCameraPosition?.length
    const isZoomToModel = projectStore?.previous3DViewSetting
    projectStore.modelList.forEach(model => {
      updatePlaneMatix(model, projectStore.projectDetail, false, false, isZoomToModel)
    });
    let count
    if (!projectStore?.previous3DViewSetting) {
      projectStore.setZoomToModel(projectStore.newModelId)
    }
    function updateVisibility(models, newModelId) {
      return models.map(model => {
        if (model.modelId === newModelId) {
          return { ...model, isVisible: true };
        }
        return model;
      });
    }
    const _visibleTilesets = updateVisibility(projectStore.visibleTilesets,projectStore.newModelId);
    projectStore.setVisibleTilesets(_visibleTilesets);
    function requestRenderBug() {
      count += 1
      if (!viewerRef && !viewerRef.current && !viewerRef.current.cesiumElement && !viewerRef?.current?.cesiumElement?.scene?.isDestroyed()) {
        viewerRef.current.cesiumElement.scene.requestRender();
        count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
      }
    }
    let timeout = projectStore.newModelId ? setTimeout(requestRenderBug, 1000) : false;
  }, [projectStore.newModelId])

  //Effect for reload Alignment when save edit model or rebuild model
  useEffect(() => {
    if (!projectStore.reloadAlignment) return
    projectStore.Alignment.forEach(a => {
      onAlignmentReload(a)
    })
    projectStore.setReloadAlignment(false)
  }, [projectStore.reloadAlignment])

  useEffect(() => {
  }, [adminStore.currentFeatureSetting, adminStore.systemFeatureSetting, adminStore.projectFeatureSetting])
  //Effect for updating storage 
  useEffect(() => {
    if (projectStore.projectDetail?._id && usersStore.currentUser?._id) {
      projectStore.updateProjectStorage(projectStore.projectDetail._id)
    }
  }, [capturesStore.captureList.length, sketchingStore.arrSketches.length, topicStore.topicList.length, feedbackStore.feedbackVisualization.length, feedbackStore.feedbackformNormalVisualization.length, projectStore.modelList.length])

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const canPicking = () => {
    return (!measureStore.measureMode &&
      !projectStore.showEditLocation &&
      !sketchingStore.drawMode &&
      commonStore.manualGpsState !== 1 &&
      (!projectStore.clippingMode || projectStore.clippingPickDone) &&
      !topicStore.isShowTopicEditor &&
      !feedbackStore.isShownFeedbackAnswer &&
      !topicStore.isTopic3DLocationVisible &&
      !feedbackStore.shownFeedbackAnswer.show &&
      //!projectStore.showHiddenTilesList &&
      !topicStore.isTopic3DObjectsVisible &&
      !feedbackStore.showAddLocationControl) &&
      !polylineCutToolStore.drawMode &&
      !projectGanttStore.selectObjectModel.open
  }

  const openObjectInfo = async (pickedObject, position) => {
    if (isValidCanvas() && canPicking()) {
      try {
        if (!(checkingFeatureRole("feature_object_info"))) return
        if (!pickedObject) {
          return
        }
        const viewer = viewerRef?.current?.cesiumElement;
        // projectStore.setSelectedAttrData();
        projectStore.setClickModelLink(false)
        if (pickedObject.content && pickedObject.content.tileset) {
          if (projectStore.showHiddenTilesList) return;
          if (pickedObject?.content?.tileset?.id) {
            if (projectStore.listAllModel3DSLink.find(x => x.id === pickedObject?.content?.tileset?.id)) {
              projectStore.setClickModelLink(true)
            }
          }
          let model = projectStore.findModelByUrl(pickedObject.content.tileset?.resource?.url);
          if (!model) {
            model = projectStore.findModelByUrlLink(pickedObject.content.tileset?.resource?.url)
          }
          if (pickedObject?.content?.tile?.i3sNode?.resource?.queryParameters?.modelId && !model) {
            let modelId = pickedObject.content.tile.i3sNode.resource.queryParameters.modelId
            model = projectStore.modelList.find(x => x.id === modelId)
          }
          if (!model) return;
          if (model.type === 'ifc' || model.type === 'landxml' || model.type === 'landxmlBackground' || model.type === 'cad') {
            if (!(pickedObject instanceof Cesium3DTileFeature)) return;
            const GUID = pickedObject?.getProperty('GUID');
            if (!GUID || GUID === 'None') return
            unselectFeatures(projectStore.selectedFeature);
            pickedObject.guid = GUID
            selectFeatures([pickedObject]);
            uiStore.setShowAttrPanel(true);
            projectStore.setLoadingAttrData(true);
            // projectStore.setSelectedAttrData({
            //   model3D: model.id,
            //   GUID
            // });
            if (model.crs?.Version || model.crs?.tilesetVersion) { //model.crs?.tilesetVersion for oldmodel, new model change to Version
              const version = model.crs?.Version || model.crs?.tilesetVersion;
              let fileid = model.hash ? (model.hash?.split("/").length > 1 ? model.hash.split("/")[1].trim() : model.hash.split("/")[0].trim()) : undefined;
              const { data: attrData } = await modelStore.getTreeGuid({ guid: GUID, fileid, level: 10, modelType: model.type });
              modelStore.setModelAttribute({ id: model._id, version: version==='IFCReader 4.0' ? 2 :  1, GUID, modelType: model.type })
              attrData.model3D = model._id
              projectStore.setSelectedAttrData(attrData || []);
            } else {
              const { data: attrData } = await ModelAttributeRequest.getAttributByGUID(GUID, model._id);
              modelStore.setModelAttribute({ id: model._id, version: 0, GUID })
              attrData.model3D = model._id
              projectStore.setSelectedAttrData(attrData || []);
            }
          } else if (model.data.isI3sModel) {
            const attrData = {
              pTitle: model.name,
              pKey: model._id,
              model3D: model._id,
              refId: model._id,
              children: []
            };
            const pickedPosition = viewer.scene.pickPosition(position);
            const i3sNode = pickedObject.content.tile.i3sNode;
            if (i3sNode) {
              await i3sNode.loadFields()?.then(function () {
                const fields = i3sNode.getFieldsForPickedPosition(
                  pickedPosition
                );
                if (Object.keys(fields).length > 0) {
                  for (const fieldName in fields) {
                    if (i3sNode.fields.hasOwnProperty(fieldName)) {
                      attrData.children.push({
                        key: uuid(),
                        title: `${fieldName} = ${fields[fieldName]}`
                      })
                    }
                  }
                }
              })
              uiStore.setShowAttrPanel(true);
              projectStore.setSelectedAttrData(attrData);
            }
          } else {
            const attrData = {
              pTitle: model.name,
              pKey: model._id,
              model3D: model._id,
              refId: model._id,
              children: []
            };
            if (pickedObject instanceof Cesium3DTileFeature) {
              const propertyNames = pickedObject.getPropertyIds();
              for (let i = 0; i < propertyNames.length; ++i) {
                const name = propertyNames[i];
                const value = pickedObject.getProperty(name);
                if (value) {
                  attrData.children.push({
                    key: uuid(),
                    title: `${name} = ${value}`
                  })
                }
              }
            }
            if (model.type !== 'model3d') {
              uiStore.setShowAttrPanel(true);
              projectStore.setSelectedAttrData(attrData);
              modelStore.setModelAttribute({ id: model._id, version: 0, GUID: model._id })
            } else {
              uiStore.setShowAttrPanel(true);
              projectStore.setSelectedAttrData(attrData);
            }
          }
        } else if ((pickedObject.id && pickedObject.id._id) || (checkSketchMeshModel(pickedObject))) {
          let model = projectStore.modelList.find(x => x._id === pickedObject.id.layerId)
          if (!model) {
            model = projectStore.listAllModel3DSLink.find(x => x.id === pickedObject.id.layerId)
            if (model) {
              projectStore.setClickModelLink(true)
            }
          }
          const alignmentId = pickedObject.id.isAlignment ? (pickedObject.id.parentId || pickedObject.id.id) : ''
          const pickedObjectID = checkSketchMeshModel(pickedObject) ? pickedObject.id : pickedObject.id.id
          const sketch = sketchingStore.arrSketches.find(x => x._id === pickedObject.id._id || pickedObjectID.indexOf(x.id) === 0);

          if (sketch) {
            let elementSelected = []
            if (sketchingStore.sketchLibraryElements && sketch.typeLibrary && sketch.typeLibrary.checkedKeys && sketch.typeLibrary.checkedKeys.length > 0) {
              if (sketchingStore.sketchLibraryElements.length > 0) {
                let data = sketchingStore.sketchLibraryElements
                let _checkedKey = sketch.typeLibrary.checkedKeys
                _checkedKey.map(key => {
                  let node = TreeUtils.searchTreeNode(data, 'key', key);
                  if (node) {
                    elementSelected.push(toJS(node))
                  }
                })
              }
            }
            const objectData = [
              {
                key: uuid(), title: t('general'),
                children: [
                  { key: uuid(), title: t('name'), value: `${sketch.name || ''}` },
                  { key: uuid(), title: t('description'), value: `${sketch.description || ''}` }
                ]
              },
              {
                key: uuid(), title: t('dimensions'),
                children: [
                  { key: uuid(), title: t('height'), value: `${sketch.extrudedHeight || 0}` },
                  { key: uuid(), title: t('width'), value: `${sketch.width || 0}` },
                  { key: uuid(), title: t('rotation'), value: `${sketch.rotation || 0}` }
                ]
              }
            ]
            // sketch library multi selected 
            if (sketch.typeLibrary && sketch.typeLibrary.checkedKeys && sketch.typeLibrary.checkedKeys.length > 0) {
              elementSelected.map(library => {
                objectData.push({
                  key: uuid(), title: t('library-element-information'),
                  children: [
                    { key: uuid(), title: t('name'), value: `${library.title || ''}` },
                    { key: uuid(), title: t('type'), value: `${library.type || ''}` },
                    { key: uuid(), title: t('description'), value: `${library.description || ''}` },
                    // { key: uuid(), title: t('image'), value: `${library.image?.url || ''}` },
                  ]
                })
              })
            }
            if (projectStore.showHiddenTilesList) return
            const attrData = {

              pTitle: sketch.name,
              pKey: sketch.id,
              refId: sketch.id,
              sketchId: sketch.id,
              children: [
                { key: uuid(), title: `${t('name')} = ${sketch.name || ''}` },
                { key: uuid(), title: `${t('description')} = ${sketch.description || ''}` },
                { key: uuid(), title: `${t('start-date')} = ${sketch.startDate ? moment(sketch.startDate).format('YYYY-MM-DD HH:mm:ss') : ''}` },
                { key: uuid(), title: `${t('end-date')} = ${sketch.endDate ? moment(sketch.endDate).format('YYYY-MM-DD HH:mm:ss') : ''}` }
              ],
              type: 'sketch',
              data: sketch,
              objectData
            };
            uiStore.setShowAttrPanel(true);
            projectStore.setSelectedAttrData(attrData);
          }
          else if (model) {
            const targetEntity = pickedObject.id;
            const primitive = pickedObject.primitive;
            const objectId = targetEntity.name;
            if (projectStore.showHiddenTilesList) {

              const citygmlMap = projectStore.city3DDB
              const layer = citygmlMap._layers.find(x => x.id === model._id)
              var highlightThis = {}
              highlightThis[objectId] = new Color(0.4, 0.4, 0, 1);
              if (layer) {
                if (layer.isHighlighted(objectId)) {
                  layer.unHighlight([objectId]);
                }
                else {
                  layer.highlight(highlightThis);
                }

              }

            }
            projectStore.setLoadingAttrData(true)

            const info = await projectStore.getGMLObjectInfo(objectId, model).catch(() => {
              projectStore.setLoadingAttrData(false)
            })
            if (info && info.message === 'Success') {
              const cityObjects = info.data.CityObjects
              const cityObjectKey = Object.keys(cityObjects) ? Object.keys(cityObjects)[0] : ''
              if (cityObjectKey) {
                const cityObject = cityObjects[cityObjectKey]
                const type = cityObject.type
                const attributes = cityObject.attributes
                const attrData = {
                  pTitle: model.name,
                  pKey: model._id,
                  model3D: model._id,
                  refId: model._id,
                  children: []
                };
                if (type) {
                  attrData.children.push({
                    key: uuid(),
                    title: `${t('type')} = ${type}`
                  })
                }
                for (const key in attributes) {
                  if (Object.hasOwnProperty.call(attributes, key)) {
                    const a = attributes[key];
                    attrData.children.push({
                      key: uuid(),
                      title: `${key} = ${a}`
                    })
                  }
                }
                uiStore.setShowAttrPanel(true);
                projectStore.setSelectedAttrData(attrData);
                modelStore.setModelAttribute({ id: model._id, version: 0, GUID: model._id })
              }

            }
          }
          else if (alignmentId) {
            const modelId = pickedObject.id.alignmentKey
            if (modelId) {
              uiStore.setShowAttrPanel(true);
              projectStore.setLoadingAttrData(true);
              const GUID = pickedObject.id?.parentId || pickedObject.id?.id
              let _idModel = modelId.split("-") ? modelId.split("-")[0] : modelId
              let model = projectStore.modelList.find(x => x._id === _idModel)
              if ((model?.crs?.Version || model.crs?.tilesetVersion) && GUID) { //model.crs?.tilesetVersion for oldmodel, new model change to Version
                let fileid = model.hash ? (model.hash?.split("/").length > 1 ? model.hash.split("/")[1].trim() : model.hash.split("/")[0].trim()) : undefined;
                const { data: attrData } = await modelStore.getTreeGuid({ guid: GUID, fileid, level: 10, modelType: model.type });
                modelStore.setModelAttribute({ id: model._id, version: 1, GUID, modelType: model.type })
                attrData.model3D = model._id
                projectStore.setSelectedAttrData(attrData || []);
              } else {
                const { data: attrData } = await ModelAttributeRequest.getAttributByGUID(alignmentId, modelId.substr(0, 24));
                modelStore.setModelAttribute({ id: model._id, version: 0, GUID })
                attrData.model3D = model._id
                projectStore.setSelectedAttrData(attrData || []);
              }
            }

          }
        }else return ;
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (commonStore.pickedObject && commonStore.pickedObject.object && commonStore.pickedObject.position) {
      openObjectInfo(commonStore.pickedObject.object, commonStore.pickedObject.position)
      commonStore.setpickedObject()
    }
  }, [commonStore.pickedObject])

  useEffect(() => {
    if (!uiStore.showAttrPanel) {
      unselectFeatures(projectStore.selectedFeature)
    }
  }, [uiStore.showAttrPanel])

  const unselectFeatures = features => {
    if (!features || !features.length) {
      return;
    }
    features.map(feature => {
      if (feature) {
        let model = projectStore.modelList.find(x => x._id === feature.tileset.id)
        feature.color = Color.clone(Color.WHITE.withAlpha(model?.data?.transparency ? (1 - model?.data?.transparency) : 1), feature.color);
      }
    })
    const viewer = viewerRef.current.cesiumElement;
    if (viewer.scene.requestRenderMode) {
      viewer.scene.requestRender();
    }
    if (features === projectStore.selectedFeature) {
      projectStore.setSelectedFeature(undefined);
    }
  }
  const selectFeatures = features => {
    setFeaturesColor(features, Color.YELLOW)
    projectStore.setSelectedFeature(features);
  }

  const setFeaturesColor = (features, color) => {
    features.map(feature => {
      if (feature)
        feature.color = Color.clone(color, feature.color);
    })
    const viewer = viewerRef.current.cesiumElement;
    if (viewer.scene.requestRenderMode) {
      viewer.scene.requestRender();
    }
  }
  const onAlignmentReload = (czmlModel) => {
    try {
      const czmlDataSource = czmlModel?.ref?.current.cesiumElement
      if (czmlDataSource) {
        onAlignmentLoad(czmlDataSource, czmlModel.key)
      }
    } catch (error) {

    }
  }
  const onAlignmentLoad = (czmlDataSource, alignmentKey, isProjectLink) => {
    var matENUtoECEF = new Matrix4();
    const tilekey = alignmentKey.replace('-alignment', '')
    //Transforms.eastNorthUpToFixedFrame(refProject, Ellipsoid.WGS84, matENUtoECEF);
    let _ftile
    if (isProjectLink) {
      _ftile = findTile(tilekey, projectStore.tileLinkViews, isProjectLink)
    } else {
      _ftile = findTile(tilekey, projectStore.tileViews)
    }
    if (!_ftile) return
    const hideFeatures = projectStore.findHideFeaturesByUrl(_ftile?.resource?.url)
    matENUtoECEF = _ftile.root.transform
    let dbmodel = projectStore.modelList.find(
      x => x._id === tilekey
    )
    if (!dbmodel) {
      dbmodel = projectStore.listAllModel3DSLink.find(
        x => x.id === tilekey
      )
    }
    if (!dbmodel) return
    const localOrg = dbmodel.crs.LocalOrg

    var pointENU = new Cartesian3();
    var entities = czmlDataSource.entities.values
    const lineDict = {}
    const readTickLine = (entity) => {
      if (defined(entity.polyline) && entity.id.length !== 32) {
        const number = parseInt(entity.name)
        const segments = Object.keys(lineDict).map(x => parseInt(x))

        const closest = segments.reduce(function (prev, curr) {
          return (Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev);
        });
        if (lineDict[closest]) {
          entity.parentId = lineDict[closest].id
          entity.alignmentKey = alignmentKey
        }
      }
    }

    const extractID = (inputString) => {
      const extractedId = inputString.replace(/-alignment$/, "");
      return extractedId;
    }

    const readAligmentLine = (entity) => {
      if (defined(entity.polyline) && entity.id.length === 32) {
        const id = entity.id
        const number = parseFloat(entity.name)
        var rounded = parseInt(number / 10, 10) * 10;
        entity.alignmentKey = alignmentKey
        if (!lineDict[id]) {
          lineDict[rounded] = {
            id, number
          }
        }
        entity.alignmentType = 'segment' // các đoạn polyline
        entity.modelId = extractID(alignmentKey)
        projectStore.setAlignmentFeatures([...projectStore.alignmentFeatures, entity])
      }
      else {
        readTickLine(entity)
        entity.alignmentType = 'tick' // trùng với label nhưng có 2 điểm
      }
    }

    const readLabel = (entity) => {
      if (defined(entity.label)) {
        const id = entity.id
        const number = parseInt(entity.name)
        const segments = Object.keys(lineDict).map(x => parseInt(x))

        var closest = segments.reduce(function (prev, curr) {
          return (Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev);
        });
        if (lineDict[closest]) {
          entity.parentId = lineDict[closest].id
          entity.alignmentKey = alignmentKey
        }
      }
    }

    const cc = 0.1
    for (let i = 0; i < entities.length; i++) {
      var entity = entities[i];
      if (defined(entity)) {
        entity.isAlignment = true
        if (defined(entity.polyline)) {
          if (!entity.polyline.oPositions) {
            entity.polyline.oPositions = JSON.stringify(entity.polyline.positions.getValue())
          }
          const originalPositions = JSON.parse(entity.polyline.oPositions)
          readAligmentLine(entity)
          for (let j = 0; j < entity.polyline.positions._value.length; j++) {
            entity.polyline.positions._value[j].x = originalPositions[j].x
            entity.polyline.positions._value[j].y = originalPositions[j].y
            entity.polyline.positions._value[j].z = originalPositions[j].z
            pointENU.x = originalPositions[j].x - localOrg[0];
            pointENU.y = originalPositions[j].y - localOrg[1];
            pointENU.z = originalPositions[j].z - localOrg[2] + cc;
            Matrix4.multiplyByPoint(matENUtoECEF, pointENU, entity.polyline.positions._value[j]);
          }
        }
        else if (defined(entity.label)) {
          if (!entity.label.oPositions) {
            entity.label.oPositions = JSON.stringify(entity.position.getValue(0))
          }
          readLabel(entity)
          const originalPositions = JSON.parse(entity.label.oPositions)
          entity.position._value.x = originalPositions.x
          entity.position._value.y = originalPositions.y
          entity.position._value.z = originalPositions.z
          entity.alignmentType = 'label' // label ex 10, 15..

          pointENU.x = entity.position.getValue(0).x - localOrg[0];
          pointENU.y = entity.position.getValue(0).y - localOrg[1];
          pointENU.z = entity.position.getValue(0).z - localOrg[2] + cc;
          Matrix4.multiplyByPoint(matENUtoECEF, pointENU, entity.position._value);
        }
        setCZMLStyle(entity)
      }
    }
    const Alignment = projectStore.Alignment.find(t => {
      return t && t.key === alignmentKey
    })
    if (!Alignment) return
    let visibleTileViewsLink = [];
    for (var i = 0; i < projectStore.listProjectLink.length; i++) {
      let projectLink = projectStore.listProjectLink[i]
      projectStore.listAllModel3DSLink.map(item => {
        if (item.link?.id === projectLink.link?.id) {
          if (projectLink.isVisible) {
            visibleTileViewsLink.push({ modelId: item.id + '-tile', isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceTypem, isTempHidden: item.isTempHidden, isVisibleClip: true, })
          }
        }
      })
    }

    let visibleTileViews = projectStore.visibleTilesets.map(item => {
      if (item.isVisible) {
        return { modelId: item.modelId + '-tile', isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceTypem, isTempHidden: item.isTempHidden, isVisibleClip: true, }
      }
    })
    let model = visibleTileViews.find(t => {
      return t && t.modelId === tilekey + '-tile'
    })
    if (!model) {
      model = visibleTileViewsLink.find(t => {
        return t && t.modelId === tilekey + '-tile'
      })
    }
    const isIdInList = (id, list) => {
      return list.some(obj => obj.modelId === id);
    }
    if (model) {
      if (isIdInList(model._id, userGroupStore.nodeNoAccessRights)) {
        Alignment.ref.current.cesiumElement.show = false
        const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
        entitiesCZML.forEach(e => {
          e.show = false
        })
        Alignment.show = false
      } else {
        Alignment.ref.current.cesiumElement.show = model.isVisible
        const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
        entitiesCZML.forEach(e => {
          e.show = hideFeatures.indexOf(e.id) === -1 && hideFeatures.indexOf(e.parentId) === -1
        })
        Alignment.show = model.isVisible
      }
    }
    else {
      Alignment.show = false
      Alignment.ref.current.cesiumElement.show = false
    }

  }

  const onTileLoad = (tile, modelData) => {
    var model
    let isProjectLink = false;
    if (modelData.link) {
      model = projectStore.findModelByUrlLink(tile?.resource?.url, 'ModelTileset.json')
      isProjectLink = true
    } else {
      model = projectStore.findModelByUrl(tile?.resource?.url)
    }
    if (!model || model.isDeleted) return
    tile.id = model._id // need for hiden area check cut polygon
    if (modelData.link) {
      updatePlaneMatix(model, modelData.link, tile, isProjectLink, true)
    } else {
      updatePlaneMatix(model, projectStore.projectDetail, tile, false, true)
    }

    tile.tileLoad.addEventListener(function (tile) {
      processTileFeatures(tile, model);
    });
  }

  const processTileFeatures = (tile, model) => {
    const content = tile.content;
    const innerContents = content.innerContents;
    if (innerContents) {
      const length = innerContents.length;
      for (let i = 0; i < length; ++i) {
        processContentFeatures(innerContents[i], model);
      }
    } else {
      processContentFeatures(content, model);
    }
  }

  const processContentFeatures = (content, model) => {
    const featuresLength = content.featuresLength;
    for (let i = 0; i < featuresLength; ++i) {
      const feature = content.getFeature(i);
      loadFeature(feature, model);
    }
  }

  const loadFeature = (feature, model) => {
    const GUID = feature.getProperty('GUID');
    const modelId = model.id || model._id
    if (modelId) {
      const modelFeatures = projectStore.modelFeatures?.[modelId] ? projectStore.modelFeatures[modelId] : {}
      if (modelFeatures[GUID]) {
        modelFeatures[GUID].push(feature)
      } else {
        modelFeatures[GUID] = [feature]
      }
      projectStore.updateModelFeatures(modelId, modelFeatures, GUID);
    }
  }

  const findTile = (tileId, tileViews, isProjectLink) => {
    let fkey
    if (isProjectLink) {
      fkey = tileId + '-tile' + '-link'
    } else {
      fkey = tileId + '-tile'
    }
    let tile = tileViews.find(t => t.key == fkey)
    if (!tile) return false
    if (!tile.ref) return false
    if (!tile.ref.current) return false

    return tile.ref.current.cesiumElement
  }

  const doSelectTile = (tile, model) => {
    if (!tile) return
    if (
      projectStore.viewMode !== 'Default mode' ||
      projectStore.showEditLocation
    )
      return
    silhouetteGreen.selected = [tile]
    let modelUrl = tile?.resource?.url || tile?.tileset?.resource?.url
    if (projectStore.currentTile) {
      if (modelUrl !== projectStore.currentTile?._url)
        setColorTile(projectStore.currentTile, false, projectStore.findHideFeaturesByUrl(projectStore.currentTile._url))
    }

    if (tile.style) {
      if (typeof tile.style.color === 'undefined') {
        // setColorTile(tile, settings.commonConfig.defaultColorTile)
        setColorTile(tile, false, projectStore.findHideFeaturesByUrl(modelUrl))
      } else {
        if (tile.style && tile.style.color && tile.style.color.expression !== "color('white', 0)") { //if tileset not in hidden visiable (projectStore.visibleTilesets)
          setColorTile(tile, false, projectStore.findHideFeaturesByUrl(modelUrl))
        }
      }
    } else {
      setColorTile(tile, false, projectStore.findHideFeaturesByUrl(modelUrl))
    }
    projectStore.setCurrentTile(tile)
    return
  }

  const onClickTileset = (m, tile) => {
    if (projectStore.showHiddenTilesList) { // case mode select hidden tile 
      var feature = viewerRef.current.cesiumElement.scene.pick(m.position);
      if (feature && feature?.content?.tile?.boundingSphere) {
        let hidetile = {
          modelid: '',
          name: '',
          defaultColor: new Color(),
          url: '',
          bbox: {},
          isDeleted: false
        }

        const model = projectStore.modelList.find(
          x => x._id === feature.content.tileset.id
        )
        if (model) {
          hidetile.modelid = model._id
          hidetile.name = model.name
        }

        hidetile.url = feature.content._resource._url
        hidetile.defaultColor = new Color(feature.content.tile.color.red, feature.content.tile.color.green, feature.content.tile.color.blue, feature.content.tile.color.alpha)

        let tilehidden = projectStore.hiddenTileSelected.find(function (data) { return data.url === feature.content._resource._url })
        if (tilehidden) { // if exist
          //reset tile   
          feature.content.tile.color = tilehidden.defaultColor;
          let _child = cesium3DtileChildrenHidden.find(x => x.url === feature.content._resource._url)
          feature.content.tile.children = _child ? _child.children : []
          //remove it from the store HiddenTileSelected
          projectStore.setHiddenTileSelected(projectStore.hiddenTileSelected.filter(x => x.url !== feature.content._resource._url));
          let newHidden = cesium3DtileChildrenHidden.splice(cesium3DtileChildrenHidden.findIndex(item => item.url === feature.content._resource._url), 1)
          setCesium3DtileChildrenHidden(newHidden)
        } else { // case not exist add to hiddenTileSelected
          let _bsphere = feature.content.tile
          let boundingBoxMin = new Cartesian3(
            _bsphere.boundingSphere.center.x - _bsphere.boundingSphere.radius,
            _bsphere.boundingSphere.center.y - _bsphere.boundingSphere.radius,
            _bsphere.boundingSphere.center.z - _bsphere.boundingSphere.radius
          )
          let boundingBoxMax = new Cartesian3(
            _bsphere.boundingSphere.center.x + _bsphere.boundingSphere.radius,
            _bsphere.boundingSphere.center.y + _bsphere.boundingSphere.radius,
            _bsphere.boundingSphere.center.z + _bsphere.boundingSphere.radius
          )
          // kiểm tra và xóa tất cả con nằm trong bbox này
          var tileNotInBBoxJustClick = projectStore.hiddenTileSelected.filter(
            function (data) {
              return !(
                data.bbox.minx >= boundingBoxMin.x &&
                data.bbox.miny >= boundingBoxMin.y &&
                data.bbox.minz >= boundingBoxMin.z &&
                data.bbox.maxx <= boundingBoxMax.x &&
                data.bbox.maxy <= boundingBoxMax.y &&
                data.bbox.maxz <= boundingBoxMax.z &&
                data.modelid === feature.content.tileset.id
              )
            }
          )
          projectStore.setHiddenTileSelected(tileNotInBBoxJustClick)

          // check tile has children
          if (feature.content.tile.children.length > 0) {
            let childrenTile = {
              url: feature.content._resource._url,
              children: feature.content.tile.children
            }
            let _childrenlist = cesium3DtileChildrenHidden
            _childrenlist.push(childrenTile)
            setCesium3DtileChildrenHidden(_childrenlist)
          }

          //store hidden tile new
          hidetile.bbox = {
            minx: boundingBoxMin.x,
            miny: boundingBoxMin.y,
            minz: boundingBoxMin.z,
            maxx: boundingBoxMax.x,
            maxy: boundingBoxMax.y,
            maxz: boundingBoxMax.z
          }
          projectStore.setHiddenTileSelected([
            ...projectStore.hiddenTileSelected,
            hidetile,
          ])
        }
      }
    } else {
      if (sketchingStore.drawMode) return
      if (polylineCutToolStore.drawMode) return
      if (!tile || !tile.primitive) return
      // doSelectTile(tile.primitive)
      let modelUrl = tile?.resource?.url || tile?.tileset?.resource?.url
      let model = findModelByUrl(modelUrl, projectStore.modelList)
      if (!model) {
        doSelectTile(tile.primitive)
        return
      }
      if (projectStore.currentTile) {
        if (modelUrl === projectStore.currentTile?._url) {
          doSelectTile(tile.primitive, model)
          return
        }
      }
      projectStore.setCurrentModelId(model._id)
    }
  }

  const find3DTileHidden = (tile) => {
    if (projectStore.hiddenTileSelected.length > 0) {
      if (projectStore.showHiddenTilesList) { //case if mode choose hide tile
        let _tile = projectStore.hiddenTileSelected.find(x => x.url === tile._contentResource._url)
        if (_tile) {
          if (_tile.isDeleted) {
            //if key isDeleted, reset tile
            tile.color = _tile.defaultColor;
            let _child = cesium3DtileChildrenHidden.find(x => x.url === tile._contentResource._url)
            tile.children = _child ? _child.children : []
            //if key isDeleted, remove it form store hidden
            projectStore.setHiddenTileSelected(projectStore.hiddenTileSelected.filter(x => x.url !== _tile.url));
          } else {
            if (tile.children.length > 0) {
              let xx = cesium3DtileChildrenHidden.find(x => x.url === tile._contentResource._url)
              if (!xx) {
                let x1 = {
                  url: tile._contentResource._url,
                  children: tile.content.tile.children
                }
                let t = cesium3DtileChildrenHidden
                t.push(x1)
                setCesium3DtileChildrenHidden(t)
              }
            }

            tile.color = new Color(1, 0, 0, 1); //red
            // tile.color = {
            //   evaluateColor: function (feature, result) {           
            //     return Color.clone(new Color(1, 0, 0, 1), result)
            //       }
            // };            

            tile.children = []
          }
        }
      } else { //normal mode
        let _tile = projectStore.hiddenTileSelected.find(x => x.url === tile._contentResource._url)
        if (_tile) {
          if (tile.children.length > 0) {
            let xx = cesium3DtileChildrenHidden.find(x => x.url === tile._contentResource._url)
            if (!xx) {
              let x1 = {
                url: tile._contentResource._url,
                children: tile.content.tile.children
              }
              let t = cesium3DtileChildrenHidden
              t.push(x1)
              setCesium3DtileChildrenHidden(t)
            }
          }
          tile.color = Color.TRANSPARENT;
          // tile.color = {
          //   evaluateColor: function (feature, result) {
          //     return Color.clone(Color.TRANSPARENT, result)
          //       }
          // };

          tile.children = []
          if (projectStore.isRerenderFeatureTile) {
            startReRender()
            setTimeout(() => {
              projectStore.setIsRerenderFeatureTile(false)
            }, 1000);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail)) return
    if (viewerRef && viewerRef.current && viewerRef.current.cesiumElement) {
      viewerRef.current.cesiumElement.shadows = commonStore.enableShadows
      if (viewerRef.current.cesiumElement.shadowMap && commonStore.enableShadows) {
        viewerRef.current.cesiumElement.shadowMap.darkness = projectStore.shadowDarkness
        viewerRef.current.cesiumElement.shadowMap.size = projectStore.shadowAccuracy
        viewerRef.current.cesiumElement.shadowMap.softShadows = projectStore.softShadows
        viewerRef.current.cesiumElement.shadowMap.maximumDistance = projectStore.shadowDistance || 1
      }
    }
    startReRender()
  }, [commonStore.enableShadows, projectStore.shadowDarkness, projectStore.shadowAccuracy, projectStore.softShadows, projectStore.shadowDistance, loadInit])

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail)) return
    if (viewerRef && viewerRef.current && viewerRef.current.cesiumElement) {
      const lightSetting = projectSettingStore.systemProjectSetting?.lightSetting;
      const improvedAtmosphere = lightSetting?.improvedAtmosphere && lightSetting?.advanced;
      // const improvedAtmosphere = lightSetting?.advanced;
      const activeLighting = lightSetting?.activeLighting;
      const scene = viewerRef.current.cesiumElement?.scene;
      // if (!scene || !improvedAtmosphere) return;
      if (!scene) return;
      const globe = scene.globe;
      scene.skyAtmosphere = new Cesium.SkyAtmosphere();
      if (projectStore.skyColor === 'camera' || projectStore.skyColor === 'masking') {
        return
      }
      const sunLightColor = new Color.fromCssColorString(lightSetting?.color || '#fffffa');
      const sunLightIntensity = !isNaN(lightSetting?.intensitySunLight) ? lightSetting?.intensitySunLight : 2.0;
      const sunLight = new Cesium.SunLight({
        color: sunLightColor,
        intensity: sunLightIntensity
      });
      // if (commonStore.enableShadows) {
        scene.light = sunLight;
      // }

      scene.highDynamicRange = improvedAtmosphere;
      globe.enableLighting = improvedAtmosphere;
      scene.skyAtmosphere.hueShift = 0;
      scene.skyAtmosphere.saturationShift = improvedAtmosphere ? 0.25 : 0
      scene.skyAtmosphere.brightnessShift = improvedAtmosphere ? (activeLighting ? 0.4 : 0.25) : 0;
      scene.skyAtmosphere.atmosphereLightIntensity = (!isNaN(lightSetting?.intensitySkyLight) ? lightSetting?.intensitySkyLight : 5.0) * 10

      // default value
      scene.skyAtmosphere.atmosphereRayleighCoefficient.x = parseFloat(5.5) * 1e-6;;
      scene.skyAtmosphere.atmosphereRayleighCoefficient.y = parseFloat(13) * 1e-6;;
      scene.skyAtmosphere.atmosphereRayleighCoefficient.z = parseFloat(28.4) * 1e-6;
      const v = parseFloat(21) * 1e-6;
      scene.skyAtmosphere.atmosphereMieCoefficient = new Cesium.Cartesian3(
        v,
        v,
        v
      );
      scene.skyAtmosphere.atmosphereRayleighScaleHeight = parseFloat(10000);
      scene.skyAtmosphere.atmosphereMieScaleHeight = parseFloat(3200);
      scene.skyAtmosphere.atmosphereMieAnisotropy = parseFloat(0.9);

    }
    startReRender()
  }, [projectSettingStore.systemProjectSetting?.lightSetting?.color,
  projectSettingStore.systemProjectSetting?.lightSetting?.intensitySkyLight,
  projectSettingStore.systemProjectSetting?.lightSetting?.intensitySunLight,
  projectSettingStore.systemProjectSetting?.lightSetting?.improvedAtmosphere,
  projectSettingStore.systemProjectSetting?.lightSetting?.advanced,
  projectSettingStore.systemProjectSetting?.lightSetting?.activeLighting,
  projectSettingStore.systemProjectSetting?.lightSetting?.type, loadInit])

  function scaleScreenSpaceError(value, scale) {
    return value / scale
  }

  useEffect(() => {
    if (projectStore.maximumScreenSpaceError && projectStore.projectDetail?._id && viewerRef.current.cesiumElement) {
      let scene = viewerRef.current.cesiumElement.scene;

      projectStore.modelList.forEach(model => {
        let _ftile = findTile(model._id, projectStore.tileViews)
        if (_ftile) {
          if (model.sourceType === 'external' && model.type !== 'terrain') {
            const defaultDetailLevel = model.data?.cesiumToken ? 1 : 16
            let spaceErrorValue = scaleScreenSpaceError(projectStore.maximumScreenSpaceError, model.data?.detailLevel || defaultDetailLevel)
            if (spaceErrorValue < 1) {
              spaceErrorValue = 1
            }
            _ftile.maximumScreenSpaceError = spaceErrorValue

          }
          switch (model.type) {
            case 'landxml':
            case 'ifc':
            case 'cad':
            case 'model3d':
            case 'kmz':
            case 'geotiff':
            case 'cloudpoint':
            case 'e57':
              _ftile.maximumScreenSpaceError = projectStore.maximumScreenSpaceError
              break
            default:
              break
          }
        }

      })
      scene.requestRender()
    }
  }, [projectStore.maximumScreenSpaceError])

  useEffect(() => {
    if (projectStore.geometricErrorScale && projectStore.projectDetail?._id && viewerRef.current.cesiumElement) {
      let scene = viewerRef.current.cesiumElement.scene;
      const metadata = projectSettingStore.systemProjectSetting || projectStore.getCurrentProjectSetting()?.renderResolution
      let maximumAttenuation = (metadata?.renderResolution?.maximumAttenuation || projectStore.maximumAttenuation) || 5
      let eyeDomeLightingStrength = (metadata?.renderResolution?.eyeDomeLightingStrength || projectStore.eyeDomeLightingStrength) || 1
      let eyeDomeLightingRadius = (metadata?.renderResolution?.eyeDomeLightingRadius || projectStore.eyeDomeLightingRadius) || 1
      let geometricErrorScale = (metadata?.renderResolution?.geometricErrorScale || projectStore.geometricErrorScale) || 1
      // console.log('set eyeDome')
      // console.log(metadata?.renderResolution)
      let pointCloudShading = new PointCloudShading({
        eyeDomeLighting: true,
        eyeDomeLightingStrength: eyeDomeLightingStrength,
        eyeDomeLightingRadius: eyeDomeLightingRadius,
        attenuation: true,
        maximumAttenuation: maximumAttenuation,
        geometricErrorScale: geometricErrorScale
      })
      projectStore.modelList.forEach(model => {
        let _ftile = findTile(model._id, projectStore.tileViews)
        if (_ftile) {
          if (model.sourceType === 'external' && model.type !== 'terrain') {
            _ftile.pointCloudShading = pointCloudShading

          }
          switch (model.type) {
            case 'landxml':
            case 'ifc':
            case 'cad':
            case 'model3d':
            case 'kmz':
            case 'geotiff':
            case 'cloudpoint':
            case 'e57':
              _ftile.pointCloudShading = pointCloudShading
              break
            default:
              break
          }
        }

      })
      scene.requestRender()
    }
  }, [projectStore.geometricErrorScale, projectStore.maximumAttenuation, projectStore.eyeDomeLightingRadius, projectStore.eyeDomeLightingStrength])
  const onClickFeature = (tile, model) => {
    if (tile && tile.id && canPicking()) {
      const attrData = {
        pTitle: model.name,
        pKey: model._id,
        KmlDataSource: true,
        model3D: model._id,
        children: []
      };

      const id = tile.id._id ? tile.id._id : '';
      attrData.children.push({
        key: uuid(),
        title: `Feature Id = ${id}`
      })

      const propertyNames = tile.id.properties && tile.id.properties.propertyNames ? tile.id.properties.propertyNames : []
      for (let i = 0; i < propertyNames.length; ++i) {
        const name = propertyNames[i];
        const value = tile.id.properties[name]._value;
        if (value) {
          attrData.children.push({
            key: uuid(),
            title: `${name} = ${value}`
          })
        }
      }
      uiStore.setShowAttrPanel(true);
      projectStore.setSelectedAttrData(attrData);
    }
  }
  const TilesetByModel = async (model, setTileViews, tileViews, modelLink, haveGoogleIonTitleSet) => {
    if (model.ref || !model || model.isDeleted || ((model.sourceType === 'WMS' || model.sourceType === 'WFS' || model.sourceType === 'City3DDB') && model.type === 'unknown') || model.type === 'geotiff') {
      return
    }
    // sometime model lost ref check If it already exists, don't load it again to avoid errors "Encountered two children with the same key,"
    let _ftile = findTile(model._id, tileViews) //sometime model lost ref??   
    if (_ftile) return
    // if (_ftile && model.type !== 'kmz') {
    //   if (model.type === 'model3d' || model.type === 'cloudpoint' || model.type === 'terrain') {
    //     if (_ftile && !_ftile?.resource) return
    //     if (_ftile && _ftile?.resource?.url && _ftile?.resource?.url.includes(model.data.ionAssetId)) return
    //   } else {
    //     if (_ftile && _ftile?.resource?.url && _ftile?.resource?.url.includes(model.hash)) return
    //     if (_ftile && _ftile instanceof Cesium.Entity) return
    //   }
    // }
    const metadata = projectStore.getCurrentProjectSetting()

    model.ref = true
    const mref = React.createRef()
    const defaultDetailLevel = model.data?.cesiumToken ? 1 : 16
    let maximumScreenSpaceErrorData = (metadata?.renderResolution?.maximumScreenSpaceError || projectStore.maximumScreenSpaceError) || 16
    maximumScreenSpaceErrorData = scaleScreenSpaceError(maximumScreenSpaceErrorData, model.data?.detailLevel || defaultDetailLevel)
    // if (maximumScreenSpaceErrorData < 1) {
    //   maximumScreenSpaceErrorData = 1
    // }

    let maximumAttenuation = (metadata?.renderResolution?.maximumAttenuation || projectStore.maximumAttenuation) || 5
    let geometricErrorScale = (metadata?.renderResolution?.geometricErrorScale || projectStore.geometricErrorScale) || 1
    let eyeDomeLightingStrength = (metadata?.renderResolution?.eyeDomeLightingStrength || projectStore.eyeDomeLightingStrength) || 1
    let eyeDomeLightingRadius = (metadata?.renderResolution?.eyeDomeLightingRadius || projectStore.eyeDomeLightingRadius) || 1
    let projectMaximumScreenSpaceError = (metadata?.renderResolution?.maximumScreenSpaceError || projectStore.maximumScreenSpaceError) || 16

    let v = model.data?.api_ver
    let uri = '/tileset.json'
    // if (v == 3 && model.type !== 'landxmlBackground' && model.type !== 'cad') uri = '/ModelTileset.json'
    if (v === 3 && model.type !== 'landxmlBackground') uri = '/ModelTileset.json';
    if (isIFCEllipsoid(model)) uri = '/tileset.json';
    const iOption = {}
    if (moment(model.createdAt).isBefore('2020-10-31')) {
      iOption.accessToken = cesiumToken;
    }
    if (model.data && model.data.cesiumToken) {
      iOption.accessToken = model.data.cesiumToken;
    }
    let isShow = true
    if (modelLink) {
      let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds()
      if (!visibleTileViewIds.includes(model._id)) {
        isShow = false
      }
    } else {
      const isIdInList = (id, list) => {
        return list.some(obj => obj.modelId === id);
      }
      if (isIdInList(model._id, userGroupStore.nodeNoAccessRights)) {
        isShow = false;
      } else {
        const visibleTilesets = projectStore?.visibleTilesets || []
        let isExist = visibleTilesets.find(c => c.modelId === model._id)
        if (isExist) {
          isShow = isExist.isVisible
        } else {
          isShow = false
        }
      }
    }
    let tileKey = model._id + '-tile'
    if (modelLink) {
      tileKey = tileKey + '-link'
    }
    switch (model.type) {
      case 'landxmlBackground':
      case 'landxml':
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            style={new Cesium3DTileStyle({
              color: {
                evaluateColor: function (feature, result) {
                  if (result && result.alpha !== undefined) {
                    result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                  } else if (!result) {
                    result = {
                      blue: 1,
                      green: 1,
                      red: 1,
                      alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    }
                  }
                  return result
                }
              }
            })}
            prop
            show={isShow}
            key={tileKey}
            url={assetUrl + model.hash + uri}
            ref={mref}
            onReady={(e) => onTileLoad(e, model)}
            // dynamicScreenSpaceError={true}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            //debugShowGeometricError={true}
            onClick={onClickTileset}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={1}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          />,
        ])

        return
      case 'kmz':
        setTileViews([
          ...tileViews,
          <KmlDataSource
            key={tileKey}
            type={'kml'}
            ref={mref}
            id={model._id}
            show={isShow}
            data={model.src}
            onReady={(e) => onTileLoad(e, model)}
            onLoad={g => {
              for (var i = 0; i < g.entities.values.length; i++) {
                var entity = g.entities.values[i];
                entity.modelId = model._id
                entity.modelType = 'kml'
              }
              if (viewerRef?.current?.cesiumElement?._cesiumWidget) {
                if (viewerRef.current.cesiumElement?.scene?.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
              }
            }}
            onClick={(e) => {
              var pickedObject = viewerRef.current.cesiumElement.scene.pick(e.position)
              onClickFeature(pickedObject, model)
            }}
            clampToGround={model.data.clampToGround === true}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])

        return
      case 'cad':
      case 'ifc':
      case 'external-plugin':
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            style={new Cesium3DTileStyle({
              color: {
                evaluateColor: function (feature, result) {
                  if (result && result.alpha !== undefined) {
                    result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                  } else if (!result) {
                    result = {
                      blue: 1,
                      green: 1,
                      red: 1,
                      alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    }
                  }
                  return result
                }
              }
            })}
            key={tileKey}
            url={assetUrl + model.hash + uri}
            ref={mref}
            show={isShow}
            onReady={(e) => onTileLoad(e, model)}
            onClick={onClickTileset}
            // dynamicScreenSpaceError={true}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            //debugShowGeometricError={true}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={1}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          />
        ])
        return
      case 'model3d':
        if (!model.data?.srcTileset) return
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            style={new Cesium3DTileStyle({
              color: {
                evaluateColor: function (feature, result) {
                  if (result && result.alpha !== undefined) {
                    result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                  } else if (!result) {
                    result = {
                      blue: 1,
                      green: 1,
                      red: 1,
                      alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    }
                  }
                  return result
                }
              }
            })}
            key={tileKey}
            show={isShow}
            pointCloudShading={new PointCloudShading({
              eyeDomeLighting: true,
              eyeDomeLightingStrength: eyeDomeLightingStrength,
              eyeDomeLightingRadius: eyeDomeLightingRadius,
              attenuation: true,
              maximumAttenuation: maximumAttenuation,
              geometricErrorScale: geometricErrorScale
            })}
            url={model.data.srcTileset}
            ref={mref}
            onReady={(e) => onTileLoad(e, model)}
            onClick={onClickTileset}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id

              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])
        return
      case 'cloudpoint':
        if (!model.data?.srcTileset) return
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            show={isShow}
            pointCloudShading={new PointCloudShading({
              eyeDomeLighting: true,
              eyeDomeLightingStrength: eyeDomeLightingStrength,
              eyeDomeLightingRadius: eyeDomeLightingRadius,
              attenuation: true,
              maximumAttenuation: maximumAttenuation,
              geometricErrorScale: geometricErrorScale
            })}
            url={model.data.srcTileset}
            ref={mref}
            onReady={(e) => onTileLoad(e, model)}
            onClick={onClickTileset}
            classificationType={Cesium.ClassificationType.CESIUM_3D_TILE}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            style={setColorPointCloud(model?.type, model?.style, projectStore.gpsMode, false, model?.data)}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])
        return
      case 'e57':
        if (!model.data?.srcTileset) return
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            show={isShow}
            pointCloudShading={new PointCloudShading({
              eyeDomeLighting: true,
              eyeDomeLightingStrength: eyeDomeLightingStrength,
              eyeDomeLightingRadius: eyeDomeLightingRadius,
              attenuation: true,
              maximumAttenuation: maximumAttenuation,
              geometricErrorScale: geometricErrorScale
            })}
            url={model.data.srcTileset}
            ref={mref}
            onReady={(e) => onTileLoad(e, model)}
            onClick={onClickTileset}
            classificationType={Cesium.ClassificationType.CESIUM_3D_TILE}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            style={setColorPointCloud(model?.type, model?.style, projectStore.gpsMode, false, model?.data)}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])
        return
      case 'geojson':
        if (!model.src) return
        let geo_url = model.src
        if (model.data.username && model.data.password && model.data.isUseCredential) {
          const Authorization = btoa(`${model.data.username.trim()}:${model.data.password}`)
          geo_url = new Resource({
            url: geo_url,
            headers: {
              'Authorization': `Basic ${Authorization}`,
              'Access-Control-Allow-Origin': 'https://localhost:3000',
            }
          })
        }

        setTileViews([
          ...tileViews,
          <GeoJsonDataSource
            key={tileKey}
            show={isShow}
            type={'geojson'}
            ref={mref}
            id={model._id}
            data={geo_url}
            onClick={(e) => {
              var pickedObject = viewerRef.current.cesiumElement.scene.pick(e.position)
              onClickFeature(pickedObject, model)
            }}
            onLoad={g => {
              let terrainSamplePositions = []
              for (var i = 0; i < g.entities.values.length; i++) {
                var entity = g.entities.values[i];
                entity.modelId = model._id
                entity.modelType = 'geojson'
                if ((entity.point || entity.billboard) && model.data.style && model.data.style.point) {
                  if (entity.point) {
                    if (model.data.style.point.pixelSize) entity.point.pixelSize = model.data.style.point.pixelSize
                    entity.point.color = model.data.style.point.color ? new Color.fromCssColorString(model.data.style.point.color).withAlpha(Utils.flipInt(model.data.style.point.alpha)) : Color.WHITE
                    entity.point.outlineColor = model.data.style.point.outlineColor ? new Color.fromCssColorString(model.data.style.point.outlineColor) : Color.BLACK
                    entity.point.outlineWidth = model.data.style.point.outlineWidth ? model.data.style.point.outlineWidth : 0
                  } else {
                    if (entity.billboard.image._value.tagName === 'CANVAS') {
                      entity.billboard.image = new PinBuilder().fromColor(new Color.fromCssColorString(model.data.style.point.color || 'white').withAlpha(Utils.flipInt(model.data.style.point.alpha)), model.data.style.point.pixelSize || entity.billboard.image._value.width)
                    } else {
                      entity.billboard.color = new Color.fromCssColorString(model.data.style.point.color || 'white').withAlpha(Utils.flipInt(model.data.style.point.alpha))
                      if (model.data.style.point.pixelSize) {
                        entity.billboard.width = model.data.style.point.pixelSize
                        entity.billboard.height = model.data.style.point.pixelSize
                      }
                    }
                  }
                } else if (entity.polyline && model.data.style && model.data.style.line) {
                  if (model.data.style.line.width) entity.polyline.width = model.data.style.line.width
                  entity.polyline.material = model.data.style.line.color ? new Color.fromCssColorString(model.data.style.line.color).withAlpha(Utils.flipInt(model.data.style.line.alpha)) : Color.WHITE
                } else if (entity.polygon && model.data.style && model.data.style.polygon) {
                  entity.polygon.material = model.data.style.polygon.color ? new Color.fromCssColorString(model.data.style.polygon.color).withAlpha(Utils.flipInt(model.data.style.polygon.alpha)) : Color.WHITE
                  entity.polygon.outlineColor = model.data.style.polygon.outlineColor ? new Color.fromCssColorString(model.data.style.polygon.outlineColor) : Color.BLACK

                  entity.polygon.height = model.data.style.polygon.height ? Number(model.data.style.polygon.height) : 0
                  entity.polygon.extrudedHeight = model.data.style.polygon.extrudedHeight ? Number(model.data.style.polygon.extrudedHeight) : 0
                  var position = entity.polygon.hierarchy.getValue().positions[0];
                  terrainSamplePositions.push(Cartographic.fromCartesian(position));
                }
              }
              if (model.data.clampToGround) {
                sampleTerrainMostDetailed(viewerRef.current.cesiumElement.terrainProvider, terrainSamplePositions).then(() => {
                  for (var i = 0; i < g.entities.values.length; i++) {
                    var entity = g.entities.values[i];
                    var entity = g.entities.values[i];
                    var terrainHeight = terrainSamplePositions[i].height;
                    entity.polygon.height = (model.data.style.polygon.height ? Number(model.data.style.polygon.height) : 0) + terrainHeight;
                    var height = parseFloat(entity.polygon.height.getValue());
                    entity.polygon.extrudedHeight = (model.data.style.polygon.extrudedHeight ? Number(model.data.style.polygon.extrudedHeight) : 0) + terrainHeight;
                  }
                });
              }

              if (viewerRef?.current?.cesiumElement?._cesiumWidget) {
                if (viewerRef.current.cesiumElement?.scene?.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
              }
            }}
            clampToGround={model.data.clampToGround === true}
            onError={(err) => {
              console.log("Error load GeoJsonDataSource: " + model.name)
            }}
          />
        ])

        return
      default:
        break
    }
    if (model.sourceType === 'external' && !model.data?.isI3sModel) {
      if (model.data.ionAssetId) {
        if (model.type !== 'terrain') {
          setTileViews([
            ...tileViews,
            <Cesium3DTileset
              style={new Cesium3DTileStyle({
                color: {
                  evaluateColor: function (feature, result) {
                    if (result && result.alpha !== undefined) {
                      result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    } else if (!result) {
                      result = {
                        blue: 1,
                        green: 1,
                        red: 1,
                        alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                      }
                    }
                    return result
                  }
                }
              })}
              key={tileKey}
              ref={mref}
              show={isShow}
              //backFaceCulling={false}
              url={IonResource.fromAssetId(model.data.ionAssetId, iOption)}
              pointCloudShading={new PointCloudShading({
                eyeDomeLighting: true,
                eyeDomeLightingStrength: eyeDomeLightingStrength,
                eyeDomeLightingRadius: eyeDomeLightingRadius,
                attenuation: true,
                maximumAttenuation: maximumAttenuation,
                geometricErrorScale: geometricErrorScale
              })}
              onClick={onClickTileset}
              maximumScreenSpaceError={maximumScreenSpaceErrorData}
              onTileVisible={tile => {
                tile.content.tileset.id = model._id
                find3DTileHidden(tile)
                // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
                //   tile.content._model.color = new Color((0), (0), 1, 1)
                //   tile.content._model.colorBlendMode = 1
                // }
              }}
              colorBlendAmount={0.7}
              colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
              onReady={(e) => onTileLoad(e, model)}
            />,
          ])
        }
      } else if (model.data?.is3DTilesPointcloud) {
        let tUrl = getModelUrBySrc(model.src)
        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            show={isShow}
            pointCloudShading={new PointCloudShading({
              eyeDomeLighting: true,
              eyeDomeLightingStrength: eyeDomeLightingStrength,
              eyeDomeLightingRadius: eyeDomeLightingRadius,
              attenuation: true,
              maximumAttenuation: maximumAttenuation,
              geometricErrorScale: geometricErrorScale
            })}
            url={tUrl}
            ref={mref}
            onReady={(e) => onTileLoad(e, model)}
            onClick={onClickTileset}
            classificationType={Cesium.ClassificationType.CESIUM_3D_TILE}
            maximumScreenSpaceError={projectMaximumScreenSpaceError}
            style={setColorPointCloud(model?.type, model?.style, projectStore.gpsMode, model.data?.is3DTilesPointcloud, model?.data)}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = Color.BLUE
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])
      } else if (model.data.isPhotorealistic3DTile) {
        try {
          const googleTileset = await createGooglePhotorealistic3DTileset(process.env.REACT_APP_GOOGLE_API_KEY);
         
          setTileViews([
            ...tileViews,
            <Cesium3DTileset
              style={new Cesium3DTileStyle({
                color: {
                  evaluateColor: function (feature, result) {
                    if (result && result.alpha !== undefined) {
                      result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    } else if (!result) {
                      result = {
                        blue: 1,
                        green: 1,
                        red: 1,
                        alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                      }
                    }
                    return result
                  }
                }
              })}
              key={tileKey}
              ref={mref}
              url={googleTileset.resource.url}
              show={isShow}
              onClick={onClickTileset}
              maximumScreenSpaceError={maximumScreenSpaceErrorData}
              showCreditsOnScreen={false}
              onTileVisible={tile => {
                tile.content.tileset.id = model._id
                find3DTileHidden(tile)
                // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
                //   tile.content._model.color = new Color((0), (0), 1, 1)
                //   tile.content._model.colorBlendMode = 1
                // }
              }}
              onReady={(e) => {
                if(googleTileset._credits.length > 0) {
                  if (viewerRef?.current?.cesiumElement && !haveGoogleIonTitleSet){
                    viewerRef?.current?.cesiumElement?.creditDisplay.addStaticCredit(googleTileset._credits[googleTileset._credits.length-1])
                  }
                }
                onTileLoad(e, model)
              }}
              colorBlendAmount={0.7}
              colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
            />,
          ])
        } catch (error) {
          console.log(`Error loading Photorealistic 3D Tiles tileset.${error}`);
        }
      } else {
        let tUrl = getModelUrBySrc(model.src)
        if (model.data.credential) {
          tUrl = new Resource({
            url: model.src,
            headers: {
              'Authorization': `Basic ${model.data.credential}`,
              'Access-Control-Allow-Origin': '*',
            }
          })
        }

        setTileViews([
          ...tileViews,
          <Cesium3DTileset
            style={new Cesium3DTileStyle({
              color: {
                evaluateColor: function (feature, result) {
                  if (result && result.alpha !== undefined) {
                    result.alpha = model?.data?.transparency ? (1 - model?.data.transparency) : 1
                  } else if (!result) {
                    result = {
                      blue: 1,
                      green: 1,
                      red: 1,
                      alpha: model?.data?.transparency ? (1 - model?.data.transparency) : 1
                    }
                  }
                  return result
                }
              }
            })}
            key={tileKey}
            ref={mref}
            show={isShow}
            url={tUrl}
            // backFaceCulling={false}
            maximumScreenSpaceError={maximumScreenSpaceErrorData}
            skipLevelOfDetail={true}
            immediatelyLoadDesiredLevelOfDetail={true}
            onClick={onClickTileset}
            onTileVisible={tile => {
              tile.content.tileset.id = model._id
              find3DTileHidden(tile)
              // if (model.isRender && projectStore.isMasking && commonStore.manualGpsState !== 1) {
              //   tile.content._model.color = new Color((0), (0), 1, 1)
              //   tile.content._model.colorBlendMode = 1
              // }
            }}
            onReady={(e) => onTileLoad(e, model)}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.HIGHLIGHT}
          />,
        ])
      }
    }
    if (model.sourceType === 'local' && model.type === 'unknown') {
      // other file type
      let fileIcon = renderIconFile(model.data.ext?.toLowerCase())
      if (fileIcon && model.crs?.initPos?.cartographic && model.src) {
        const cartographic = new Cartographic(model.crs?.initPos?.cartographic.longitude, model.crs?.initPos?.cartographic.latitude, model.crs?.initPos?.cartographic.height)
        if (cartographic)
          setTileViews([
            ...tileViews,
            <Entity
              // id={tileKey}
              id={model._id}
              key={tileKey}
              show={isShow}
              ref={mref}
              onClick={() => {
                if (['.jpg', '.jpeg', '.png', '.gif'].includes(model.data.ext?.toLowerCase())) {
                  projectStore.setLoadingProgress(true)
                  loadImage(model.src).then(img => {
                    projectStore.setLoadingProgress(false)
                    fileStore.setPhotoViewer360({ ...model, imageSize: img })
                  }).catch(() => {
                    projectStore.setLoadingProgress(false)
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: model.src,
                    }).click();
                  })
                } else {
                  Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: model.src,
                  }).click();
                }
              }}
              position={Cartographic.toCartesian(cartographic)}
              type="document"
            >
              <BillboardGraphics
                image={fileIcon}
                verticalOrigin={VerticalOrigin.BOTTOM}
                horizontalOrigin={HorizontalOrigin.CENTER}
                distanceDisplayCondition={new DistanceDisplayCondition(0.0, 6000)}
                disableDepthTestDistance={Number.POSITIVE_INFINITY}
                color={Color.WHITE}
              />
            </Entity>])
      }
    }
  }

  const renderIconFile = (extension) => {

    switch (extension) {
      case '.doc':
      case '.docx':
        return DocFile;
      case '.jpg':
      case '.jpeg':
        return JPGFile;
      case '.pdf':
        return PDFFile;
      case '.png':
        return PNGFile;
      case '.ppt':
      case '.pptx':
        return PPTFile;
      case '.tiff':
        return TIFFile;
      case '.txt':
        return TXTFile;
      case '.xls':
      case '.xlsx':
        return XLSFile;
      case 'httplinks':
        return HTTPlinks;
      default:
        return GenericFile;
    }
  }

  const getCameraData = async (name, viewlist, treeData) => {
    if (viewerRef.current != null && viewerRef?.current?.cesiumElement?.camera) {
      let camera = viewerRef.current.cesiumElement.camera
      let camData = {}
      camData.position = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      }
      camData.positionCartographic = {
        lng: camera.positionCartographic.longitude,
        lat: camera.positionCartographic.latitude,
        elevation: camera.positionCartographic.height
      }
      camData.orientation = {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: camera.roll
      }
      camData.direction = {
        x: camera.direction.x,
        y: camera.direction.y,
        z: camera.direction.z,
      }
      camData.up = {
        x: camera.up.x,
        y: camera.up.y,
        z: camera.up.z,
      }
      camData.timeSlider = schedulingStore.currentViewingTime || moment(new Date())
      if (topicStore.isShowLoading) {
        projectStore.setLoadingProgress(true)
      }
      // let lastviewpoint = parseInt(viewlist.length > 0 ? viewlist.slice(-1)[0].name.substr(0, viewlist.slice(-1)[0].name.indexOf(' ')) : 0)
      let lastviewpoint = parseInt(viewlist?.length > 0 ? viewlist.length : 0)
      if (isNaN(lastviewpoint)) {
        lastviewpoint = 1
      } else {
        lastviewpoint = lastviewpoint + 1
      }

      const files = await getImageFromCanvasLess(
        `${lastviewpoint < 10 ? ('0' + lastviewpoint) : lastviewpoint} ${name}.png`, 265, 175, 80
      )
      projectStore.setLoadingProgress(false)
      const file = files.data[0]
      let newCapture = {
        name: (lastviewpoint < 10 ? ('0' + lastviewpoint) : lastviewpoint) + ' ' + name,
        cameraData: camData,
        project: props.match.params.projectId,
        image: file,
        treeData
      }
      return newCapture
    }
    return false
  }

  const isValidCanvas = () => {
    return viewerRef.current &&
      viewerRef.current.cesiumElement &&
      viewerRef.current.cesiumElement._cesiumWidget &&
      viewerRef.current.cesiumElement.scene
  }

  // const getImageFromCanvas = async filename => {
  //   if (viewerRef.current !== null) {
  //     startReRender()
  //     viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = false
  //     viewerRef.current.cesiumElement.render()
  //     const canvas = viewerRef.current.cesiumElement.canvas
  //     const base64Image = canvas.toDataURL('image/png')
  //     const imageFile = await createImageFileFromBase64(base64Image, filename)
  //     const formData = new FormData()
  //     formData.append('files', imageFile)
  //     viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = projectStore.showAmbientOcclusion
  //     return ProjectRequest.uploadModel(formData)
  //   }
  //   return Promise.reject()
  // }

  const getImageFromCanvasLess = async (filename, width, height, quality) => {
    if (viewerRef.current !== null) {
      startReRender();
      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = false;
      viewerRef.current.cesiumElement.render();

      const canvas = viewerRef.current.cesiumElement.canvas;
      const originalWidth = canvas.width;
      const originalHeight = canvas.height;

      // Create a new canvas with the desired size
      const newCanvas = document.createElement('canvas');
      newCanvas.width = width;
      newCanvas.height = height;
      const context = newCanvas.getContext('2d');

      // Draw the image from the original canvas onto the new canvas with resizing
      context.drawImage(
        canvas,
        0,
        0,
        originalWidth,
        originalHeight,
        0,
        0,
        width,
        height
      );

      const base64Image = newCanvas.toDataURL('image/jpeg', quality / 100);
      const imageFile = await createImageFileFromBase64(base64Image, filename);
      const formData = new FormData();
      formData.append('files', imageFile);

      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = projectStore.showAmbientOcclusion;

      return ProjectRequest.uploadModel(formData);
    }
    return Promise.reject();
  };

  const inherit4DSetting = (node, tree) => {
    if (node && !node?.startDate && !node?.endDate) {
      const parent = TreeUtils.searchParentTreeNode(tree, 'key', node.key);
      if (parent && !parent?.startDate && !parent?.endDate) {
        return inherit4DSetting(parent, tree);
      }
      else if (parent && (parent?.startDate || parent?.endDate)) {
        const isVisible4D = projectStore.visible4DFolders?.find(x => x.key === parent.key)?.isVisible4D
        if (isVisible4D) {
          return {
            key: parent.key,
            startDate: parent.startDate,
            endDate: parent.endDate,
            title: parent.title,
            isVisible4D,
            inherit: parent.title
          }
        }
        return null
      }
      else {
        return null
      }
    }
    return null
  }

  //4D tool should turn on / off only objects that have either start or end date or both.
  const getVisibleModel4D = (models, match) => {
    let visibleModels = []
    let modelNullStartAndEnDate = []
    const currentTime = moment(schedulingStore.currentViewingTime)
    models.forEach(model => {
      let momentStart = model.startDate && moment(model.startDate)
      let momentEnd = model.endDate && moment(model.endDate)
      const treeData = Array.isArray(projectStore?.projectDetail?.treeData) ? JSON.parse(JSON.stringify(projectStore?.projectDetail?.treeData)) : [];
      if (!momentStart && !momentEnd && match) {
        if (treeData.length > 0) {
          const node = TreeUtils.searchTreeNode(treeData, match, model.id)
          const parent = inherit4DSetting(node, treeData)
          if (parent) {
            momentStart = parent.startDate && moment(parent.startDate)
            momentEnd = parent.endDate && moment(parent.endDate)
            model.isVisible4D = parent.isVisible4D
          }
        }
      }
      let state = 'null'
      if (!momentStart && !momentEnd) {
        modelNullStartAndEnDate.push(model._id)
      }
      else if (momentStart && !momentEnd &&
        momentStart.isBefore(currentTime)
      ) {
        state = 'startDate isBefore currentTime'
        visibleModels.push(model._id)
      }
      else if (!momentStart && momentEnd &&
        momentEnd.isAfter(currentTime)
      ) {
        state = 'endDate isBefore currentTime'
        visibleModels.push(model._id)
      }
      else if (
        momentStart && momentEnd &&
        momentEnd.isAfter(currentTime)
        &&
        momentStart.isBefore(currentTime)
      ) {
        state = 'startDate isBefore currentTime and endDate isAfter currentTime'
        visibleModels.push(model._id)
      }
      else {
        state = 'not visible in 4D'
      }
    })
    return { visibleModels: visibleModels, modelNullStartAndEnDate: modelNullStartAndEnDate }
  }

  function getStream() {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    var constraints = {
      video: { facingMode: { exact: "environment" } }
    };
    return navigator.mediaDevices.getUserMedia(constraints)
  }

  const handleDevices = React.useCallback(
    mediaDevices => {
      mediaDevices.forEach(function (device) {
        console.log(device.kind + ": " + device.label +
          " id = " + device.deviceId);
      });
      var testDevices = mediaDevices.filter(
        device => {
          if (isIOS) {
            return device.kind === 'videoinput'
          } else {
            if (device.getCapabilities && device.getCapabilities()) {
              if (device.kind === 'videoinput' && device.getCapabilities().facingMode) {
                return device.getCapabilities().facingMode.indexOf('environment') > -1
              }
            }
          }
        }
      )

      if (testDevices.length === 0) {
        testDevices = mediaDevices.filter(
          item => item && item.kind === 'videoinput'
        )
      }

      if (testDevices.length > 0) {
        let sampleDevice = testDevices[testDevices.length - 1]
        commonStore.setDeviceId(sampleDevice.deviceId)
      }
      commonStore.setDevices(testDevices)
    }
  )
  async function setProjectPlaneModel(RefPoint, elevationSystem) {
    if (RefPoint) {
      const ref = React.createRef()
      const url = 'https://s3.eu-west-1.amazonaws.com/prod.xd-twin.io/Project+Plane+Mesh.glb'
      var refProject = Cartesian3.fromDegrees(RefPoint[1], RefPoint[0], 0)
      var projectElevation = projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
      if (projectElevation && projectElevation !== 'None') {
        await projectStore.calculateHeighCenterModelFlowGeoid(RefPoint[0], RefPoint[1], 0, elevationSystem).then(async response => {
          if (response.Status === "OK") {
            refProject = Cartesian3.fromDegrees(RefPoint[1], RefPoint[0], response.Heigh)
          }
        })
      }

      setRefPointView(
        <Entity
          id={`${projectStore.projectDetail._id}-refpoint`}
          key={`${projectStore.projectDetail._id}-refpoint`}
          ref={ref}
          show={projectStore.showRefPoint}
          position={refProject}
          type="refProject"
          model={{
            uri: url, shadows: ShadowMode.ENABLED,
          }}>
        </Entity>
      )
    }

  }
  function getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }
  useEffect(() => {
    if (
      projectStore.gpsMode === 'first_person' && (
        projectStore.arToolTip === 'camera' ||
        projectStore.arToolTip === 'masking')
    ) {
      if (isIOS)
        getStream().then(getDevices).then(handleDevices);
      else
        getDevices().then(handleDevices)
    }
  }, [handleDevices, projectStore.gpsMode, projectStore.arToolTip])

  const getProjectInfo = async (startTime) => {
    // Check user logged but lost session then get current user
    // if (!usersStore.currentUser._id && commonStore.checkToken()) {
    //   await usersStore.getCurrentUser()
    // }
    if (usersStore.currentUser && usersStore.currentUser._id) {
      async function fetchData(projectId) {
        try {
          const expirationPromise = projectStore.getExpirationLicense(projectId).then(response => {
            let retrievedObject = JSON.parse(localStorage.getItem(`show_expiration_notification-${projectId}`));
            if (response && response.length > 0 && !retrievedObject) {
              projectStore.setShowDialogExpirationLicense(true);
            }
          });
      
          const feedbackFormPromise = feedbackStore.getFeedbackformsList(projectId, 'normal').then(res => {
            feedbackStore.setFeedbackformNormalVisualization(res.data);
          });
      
          const feedbackAnswerPromise = feedbackStore.getFeedbackAnserList(projectId, 'normal').then(res => {
            feedbackStore.setFeedbackVisualization(res.data);
          });
      
          const projectTopicsPromise = topicStore.getProjectTopics(projectId);
          const projectTeamsPromise = projectTeamsStore.getProjectTeams(projectId);
          const objectQueryPromise = objectQueryStore.getByProjectId(projectId);
          const serviceChangeLogoPromise = projectStore.checkServiceChangeLogo(projectId);
      
          await Promise.all([
            expirationPromise,
            feedbackFormPromise,
            feedbackAnswerPromise,
            projectTopicsPromise,
            projectTeamsPromise,
            objectQueryPromise,
            serviceChangeLogoPromise
          ]);
      
          // console.log('All promises resolved successfully');
        } catch (error) {
          console.error('Error occurred while fetching data:', error);
        }
      }
      
      // Usage
      fetchData(props.match.params.projectId);
      
    } else {
      if (props?.match?.params?.projectId) {
        topicStore.getLocationTopicsByProject(props.match.params.projectId)
        feedbackStore.getFeedbackformsList(props.match.params.projectId, 'public').then(res => {
          feedbackStore.setFeedbackformNormalVisualization(res.data)
        })
        // get feedback answer type generic (case if user not login then get feedback answer generic with isPublic == true and user is null)
        feedbackStore.getFeedbackAnserList(props.match.params.projectId, 'generic').then(res => {
          feedbackStore.setFeedbackVisualization(res.data)
        })
        projectTeamsStore.getProjectTeams(props.match.params.projectId)
        projectStore.checkServiceChangeLogo(props.match.params.projectId)
        // projectStore.setChangeLogoXDTwin({
        //   customOrganizationLogo : 'fail',
        //   logo : false
        // })
      }

    }
    //Get sketch of project, effect in arrSketches
    await projectStore.getProjectDetail(props.match.params.projectId).then((res) => {
      projectTeamsStore.getProjectRoles()
      if (usersStore.currentUser && usersStore.currentUser._id && usersStore.currentUser.apiKey) {
        try {
          socket.emit("read-data", {
            apiKey: usersStore.currentUser.apiKey,
            data: { projectId: props.match.params.projectId },
            type: 'open-project',
            sesstionId: authStore.sesstionId
          });
        } catch (error) {
        }
      }

      if (res?.data?.model3DS) {
        processModel3D(res?.data?.model3DS || [])
      }
      commonStore.loggingFunction('Open project', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
    }).catch(err => {
      if (err?.status === 401) {
        return history.push("/auth/login")
      }
    })

    const metadata = projectStore.projectDetail?.metadata
    let selectedBaselayer = ''
    if (metadata) {
      selectedBaselayer = metadata[`selectedBaselayer${usersStore.currentUser._id}`]
    }
    if (viewerRef.current && viewerRef.current.cesiumElement) {
      const viewer = viewerRef.current.cesiumElement
      defaultImagery.forEach((l) => {
        if (l.name === selectedBaselayer) {
          if (viewer.baseLayerPicker) {
            viewer.baseLayerPicker.viewModel.selectedImagery = l
            l.show = projectStore.showMap
          }
        }
      })

      knockout.getObservable(viewer.baseLayerPicker.viewModel, 'selectedImagery').subscribe(setSelectedImagery);
    }
    //subscribe to base layer change event
  }

  function setSelectedImagery(model) {
    saveProjectCurrentViewpoint(usersStore.currentUser._id, viewerRef);
    viewerRef.current.cesiumElement.scene.imageryLayers._layers[0].show = projectStore.showMap
    startReRender()
  }

  useEffect(() => {
    const loadInitData = async () => {
      const startTime = Date.now();
      projectStore.setLoadingProgress(true)
      projectStore.checkProjectIsDeleted(props.match.params.projectId).then(res => { }).catch(() => {
        return history.push("/")
      })
      sketchingStore.mergeSketchLibraryElement(props.match.params.projectId).then(res => {
        //
      }).catch(err => {
        sketchingStore.setSketchLibraryElements(adminStore.defaultTreeData)
      })
      userGroupStore.getAllProjectUserGroup(props.match.params.projectId).then(res => { })
      await adminStore.getSystemFeatureSetting("system")
      await adminStore.getProjectFeatureSetting("project", props.match.params.projectId).then(res => {
        if (res.data.status === 'failed') {
          adminStore.setProjectFeatureSetting(adminStore.systemFeatureSetting)
          adminStore.setGreenColorSwitch(true)
        }
      })
      await sketchingStore.getSketchesByProjectId(props.match.params.projectId).then(res => {
        sketchingStore.setSketches(res.data)
      }).catch(err => console.log(err)).finally(async () => {
        await getProjectInfo(startTime);
        await projectStore.getListProjectLink(props.match.params.projectId).catch(err => console.log(err))
        if (projectStore.projectDetail?._id && usersStore.currentUser?._id) {
          projectStore.updateProjectStorage(projectStore.projectDetail._id)
        }
        setTimeout(() => {
          projectStore.setMovingCamera(false)
          projectStore.addPastLocations();
          projectStore.setCameraLoadFinish(true)
        }, 5000)
      })
      objectQueryStore.checkObjectInforsReady(props.match.params.projectId)
      projectStore.setLoadingProgress(false)
      setLoadInit(true)
    }
    window.scrollTo(0, 0)
    commonStore.setCurrentPage('projectDetail')
    projectStore.setTileViews([])
    projectStore.setTileLinkViews([])
    projectStore.setAlignment([])
    capturesStore.setCameraData(false)
    projectStore.setMasking(false)
    let handler
    const loadFuntionCesium = () => {
      if (!(viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget)) {
        return
      }
      handler = new ScreenSpaceEventHandler(
        viewerRef.current.cesiumElement.canvas
      )
      if (viewerRef.current) {
        var options = {}
        options.enableZoomControls = false
        viewerRef.current.cesiumElement.extend(cesiumNavMixin, options)
        //viewerRef.current.cesiumElement.scene.pickTranslucentDepth = true;
      }

      handler.setInputAction((movement) => {
        if (projectStore.clippingMode) return
        if (sketchingStore.drawMode) return
        if (polylineCutToolStore.drawMode) return
        if (!(viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget)) {
          return
        }
        const viewer = viewerRef.current.cesiumElement;
        try {
          var pickedObject = commonStore.getPickedObject(movement.position)
          if (!pickedObject) {
            pickedObject = viewer.scene.pick(movement.position, 20)
            commonStore.setpickedObject(pickedObject, movement.position)
            const pickedImageryItems = new Promise(resolve => {
              // if there are any enabled imagery layers aside from the basemap layer
              if (viewer.scene.imageryLayers.length > 1) {
                // get any possible imagery layer features at the provided position coordinates
                // const imageryLayerFeatures = getImageryLayerFeatures.call(this, mousePos)
                return getImageryLayerFeatures(viewer, movement.position).then(imageryLayerFeaturesValues => {
                  // if imagery layer features are present
                  if (imageryLayerFeaturesValues.length) {
                    // store the imagery layers feature info
                    resolve(imageryLayerFeaturesValues)
                  }
                })
              }
              return resolve([])
            })
            if (canPicking()) {
              if (!(checkingFeatureRole("feature_object_info"))) return
              if (!pickedObject) {
                pickedImageryItems.then(pickedImageryItemsVals => {
                  if (pickedImageryItemsVals.length > 0) {
                    let firstOne;
                    projectStore.setClickModelLink(true);
                    for (let i = 0; i < pickedImageryItemsVals.length; i++) {
                      const item = pickedImageryItemsVals[i];
                      if (firstOne && firstOne.data) {
                        continue
                      }
                      if (item && item.data) {
                        firstOne = item
                      }
                    }
                    const title = (firstOne.imageryLayer && firstOne.imageryLayer.imageryProvider && firstOne.imageryLayer.imageryProvider.layers) || ''

                    let refId
                    if (firstOne.imageryLayer?.imageryProvider) {
                      const layer = firstOne.imageryLayer.imageryProvider
                      const { hostname } = new URL(layer._resource.url);
                      refId = `${hostname}-${layer.layers}`
                    }
                    const attrData = {
                      pTitle: title,
                      isImageInfo: true,
                      pKey: title,
                      refId: refId,
                      children: []
                    };

                    const data = firstOne.data && firstOne.data.properties ? firstOne.data.properties : {}
                    const id = firstOne.data ? firstOne.data.id : '';
                    attrData.children.push({
                      key: uuid(),
                      title: `id = ${id}`
                    })
                    for (const key in data) {
                      const prop = data[key];
                      if (prop) {
                        if ((key === 'id' && !id) || key !== 'id') {
                          attrData.children.push({
                            key: uuid(),
                            title: `${key} = ${prop}`
                          })
                        }

                      }
                    }

                    uiStore.setShowAttrPanel(true);
                    projectStore.setSelectedAttrData(attrData);
                  }
                })
                return
              }
            } else {
              if (
                pickedObject?.content?.tile?.i3sNode &&
                Cesium.defined(pickedObject.content) &&
                Cesium.defined(pickedObject.content.tile.i3sNode)
              ) {
                openObjectInfo(pickedObject, movement.position)
              }
            }
          }
          else {
            openObjectInfo(pickedObject, movement.position)
          }
        } catch (error) {
          console.log(error)
        }
      }, ScreenSpaceEventType.LEFT_CLICK)
    }
    function waitViewerReady() {
      const waitUntilReady = async () => {
        if (viewerRef?.current?.cesiumElement) {
          loadFuntionCesium()
          return true
        } else {
          setTimeout(waitUntilReady, 1000);
        }
      };
      (async () => {
        await waitUntilReady();
      })();
    }
    if (!projectStore.isSocketOpenProjectError) {
      loadInitData()
      waitViewerReady()
    } else {
      notification.open({
        message: t('open-project-error'),
        description: t('project-not-existing'),
        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        duration: 10
      })
    }
    return () => {
      projectStore.clearCurrentUserRoleInProject()
      adminStore.clearFeatureSetting()
      schedulingStore.setCurrentViewingTime(moment(new Date()))
      projectStore.setUploadNewStartImage(false)

      projectStore.toggleProjectInfoDrawer(false)
      projectStore.setCurrentModelId(false)
      projectStore.setCurrentTile(false)
      projectStore.setGpsMode('none')
      projectStore.setSkyColor('none')
      projectStore.setViewMode('Default mode')
      projectStore.setModelList([])
      projectStore.set2D(false)
      projectStore.clearProjectDetail()
      projectStore.setMaximumScreenSpaceError(16)
      projectStore.setMaximumScreenSpaceError(16)
      projectStore.setOpenCVData({
        id: 0,
        angle: 999,
        apply: false,
      })
      projectStore.setCombineBoundingSpheres([])
      projectStore.setRenderRecordDataControl(false)
      projectStore.setCurrentGPS({})
      projectStore.setShowBoudingVolume(false)
      capturesStore.setClickAdd(false)
      projectStore.clearProjectDetail()
      projectStore.setVisibleTilesets([])
      projectStore.setHiddenTileSelected([])
      projectStore.clearNavigationStyles()
      polylineCutToolStore.setHideArea(false)

      projectStore.setTileViews([])
      projectStore.setAlignment([])
      setSketchView(false)
      setMoveTile(false)
      setMeasurePoints(false)
      setMeasureDistance(false)
      setMeasureArea(false)
      setMeasurePolyline(false)
      setDrawingSketch(false)
      setCesium3DtileChildrenHidden([])
      setImageryView(false)
      setCityDB3DView(false)
      setGeoJsonView(false)
      setImageryViewLink(false)
      setCityDB3DViewLink(false)
      setGeoJsonViewLink(false)
      setDrawingPolylineCut(false)
      setLoadInit(false)
      //topic
      setTopicLocationView(false)
      topicStore.clearArrTopicLocation()
      topicStore.setShowTopicEditor(false, false);
      topicStore.setShownListTopic(false);
      
      //feedback
      feedbackStore.clearShowFeedbackAnswer()
      feedbackStore.setVisiblFeedbackForm([])
      setFeedbackVisualization(false)
      feedbackStore.clearFeedbackVisualization()
      feedbackStore.clearVisiblFeedback()

      feedbackStore.clearFeedbackformNormalVisualization()
      setFeedbackformVisualization(false)

      //workflow
      workflowStore.setShowWorkflowDrawer(false)

      capturesStore.toggleDrawerCaptureVisible(false);
      setrequestRenderMode(uiStore.requestRender);
      clearInterval(intervalID);
      clearInterval(intervalUpdateLas);
      clearInterval(intervalVisibleData);
      clearInterval(intervalLicenses);
      clearInterval(intervalUpdateLastSettings);
      clearInterval(intervalCheckPointCloudReady);
      // localStorage.removeItem('gotoviewer')
      projectStore.setSelectedFeature(undefined);
      projectSettingStore.clearSystemProjectSetting()
      sketchingStore.setMultipleMode(false)
      projectStore.setGlobeBehind(false)

      //edit sketch geometry
      sketchingStore.setSketchProps({ pointDragger: [] })
      sketchingStore.setCurrentEditEntity(false)
      sketchingStore.setGeometrySketch(false)
      sketchingStore.setSketchLibraryElements([])
      sketchingStore.setResetGeometrySketch(false)
      sketchingStore.setCurrentEditSketch(false)
      sketchingStore.setCurrentSketchId(false)

      if (usersStore.currentUser?._id && projectStore.projectDetail?._id) {
        projectStore.updateProjectStorage(projectStore.projectDetail._id)
      }

      if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
        sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      }

      if (handler) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
        handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      }
      projectStore.setShowTwinGuideDialog(false)
      topicStore.setList3DObjectsTopic([])
      topicStore.clearList3DViewTopic()
      topicStore.setViewingTopicForm({})
      topicStore.setIsCheckSaveViewDone(true)
      topicStore.setIsShowLoading(true)
      projectStore.setProjectStorage(0)
      projectStore.setCheck2D({ load: false, status: false })
      projectStore.setExpirationLicenses([])
      projectStore.setShowDialogExpirationLicense(false)
      projectStore.setIsExistLicenses(true)
      projectStore.changeAttributesTab('attributes')

      //project Link
      projectStore.setListModelProjectLink([]);
      projectStore.setListProjectLink([]);
      projectStore.setShowProjectLinkDrawer(false);
      projectStore.setListModelProject([])
      projectStore.setClickModelLink(false)
      projectStore.setListAllModel3DSLink([])
      projectStore.setTileLinkViews([])
      projectStore.setNewModelId(false)
      projectSettingStore.setVisibleTreeData({})

      //clip alignment
      alignmentStore.toggleAlignmentTimeSliderVisible(false)

      //usergroup
      userGroupStore.setProjectGroups([])
      userGroupStore.setProjectMemberGroups([])
      userGroupStore.setTreeNodeEdit()
      userGroupStore.setSelectedGroup()

      //projectroles
      projectTeamsStore.clearProjectRoles()
      objectQueryStore.setListObjectsQuery([])

      // clear change logo state
      projectStore.setReRenderModel(false)
      projectStore.setChangeLogoXDTwin(false)

      // clear improve  navigation state
      projectStore.addPastLocations()
      projectStore.setCameraLoadFinish(false)
      projectStore.setMovingCamera(true)
      projectStore.setCameraHome(false)

      //clear gantt
      projectGanttStore.setProjectGanttData([]);
      projectGanttStore.setPlayerMode('dragDate');
      projectGanttStore.setIsShowBaseline(false)
      projectGanttStore.setHighlightSavedQueryObjectStyles([])
      projectGanttStore.setIsPlay4d(false)

      onClearAlignmentCachingObject(viewerRef?.current?.cesiumElement, true)

      // clear gantt selected in sketching
      sketchingStore.setListGanttNodeSaveSketch([])
      sketchingStore.setListOriginalGanttNodeSaveSketch([])

      if (usersStore.currentUser?._id) {
        usersStore.getCurrentUser();
      }
      
      projectStore.setPrevious3DViewSetting(false)
      projectStore.clearTargetViewPoint()
      sketchingStore.setStopDragging(false)
    }
  }, [])

  const mergeAccessTreeNode = (currentNode, parentNode) => {
    let result = currentNode || [];
    if (!parentNode && currentNode) {
      result = currentNode || []
    }
    if (!currentNode && parentNode) {
      result = parentNode || []
    }
    if (parentNode && currentNode) {
      result = mergeArrays(currentNode, parentNode)
    }
    return result;
  }

  function mergeArrays(array1, array2) {
    const merged = {};
    array1.forEach(obj => {
      merged[obj.id] = obj;
    });

    array2.forEach(obj => {
      if (!merged[obj.id]) {
        merged[obj.id] = obj;
      }
    });
    return Object.values(merged);
  }

  const recursiveGroupRights = (node, groups, rights = []) => {
    let avaiableGroups = GroupUtils.checkAvaiableGroups(node, groups)
    let settings = mergeAccessTreeNode(rights, avaiableGroups)
    if (node.parentKey) {
      let tTree = projectStore.projectDetail?.treeData || []
      let parrentNode = TreeUtils.searchTreeNode(tTree, 'key', node.parentKey);
      if (parrentNode) {
        settings = recursiveGroupRights(parrentNode, groups, settings)
      }
    }
    return settings;
  }

  const checkCombineRightsChildrenNode = (node, currentGroup, currentUser) => {
    if (!node) return true;
    let isShow = true;
    if (node.type === "FOLDER" || node.type === "FILE") {
      isShow = false;
      let tTree = projectStore.projectDetail?.treeData || []
      let userRights = GroupUtils.checkingUserRightsAtTreeNode(node, currentUser, tTree) // user rights at treenode
      let avaiableGroups = recursiveGroupRights(node, userGroupStore.groups)
      let isExist = userGroupStore.findMostPowerfulGroup(currentGroup, avaiableGroups)
      if (isExist) {
        let combineRights = userRights ? userRights : isExist
        isShow = combineRights.rights === 'none' ? true : false;
      } else {
        if (userRights) {
          isShow = userRights.rights === 'none' ? true : false;
        } else {
          if (node?.type === "FILE") {
            isShow = true;
          }
        }
      }
      return isShow;
    }
  }

  const isAllNodeHasCombineRights = (node, currentGroup, currentUser) => {
    let isHidden = true;
    if (node.type === 'FOLDER' && node.children?.length > 0) {
      let allElements = GroupUtils.findAllTreeNode(node.children || [])
      let _accessAllChildren = allElements.every(c => checkCombineRightsChildrenNode(c, currentGroup, currentUser))
      isHidden = _accessAllChildren ? true : false;
    }
    return isHidden;
  }

  const checkUserRightsChildrenNode = (node, currentUser) => {
    if (!node || !currentUser) return true;
    let isHidden = true;
    let tTree = projectStore.projectDetail?.treeData || []
    if (node.type === "FOLDER" || node.type === "FILE") {
      let userRights = GroupUtils.checkingUserRightsAtTreeNode(node, currentUser, tTree)
      isHidden = userRights?.rights === 'none' ? true : false
    }
    return isHidden;
  }

  const isAllNodeHasUserRight = (node, currentUser) => {
    let isNoUserRights = true;
    if (node.type === 'FOLDER' && node.children?.length > 0) {
      let allElements = GroupUtils.findAllTreeNode(node.children || [])
      let allNoUserRights = allElements.every(c => checkUserRightsChildrenNode(c, currentUser))
      isNoUserRights = allNoUserRights ? true : false;
    }
    return isNoUserRights;
  }

  /**
* Checking user rights and user-group rights at treenode => list treenode will be hidden on the 3dview
* @param {*} data // Array : Current datatree
* @param {*} groups  // Array : List user-group in project
* @param {*} currentGroup // Object :  User-group of current user
* @param {*} currentRole // String :  Current project user role ['project_owner' || 'project_user']
* @returns List treenode will be hidden on the 3dview
*/
  const getAllNodeNoRights = (data, groups, currentGroup, currentRole) => {
    if (currentRole && currentRole === 'project_owner') {
      return []
    } else {
      const stack = [];
      const result = [];
      let tTree = projectStore.projectDetail?.treeData || []
      data.map(item => stack.push(item));
      while (stack.length > 0) {
        const node = stack.shift();
        const userRights = GroupUtils.checkingUserRightsAtTreeNode(node, usersStore.currentUser, tTree) // user rights at treenode
        if (currentGroup && currentGroup?.length > 0) {
          if (node?.type === "FILE" || node.type === "FOLDER") {
            let avaiableGroups = recursiveGroupRights(node, groups) || [];
            let isExist = userGroupStore.findMostPowerfulGroup(currentGroup, avaiableGroups)
            if (isExist) {
              let combineRights = userRights ? userRights : isExist
              if (combineRights.rights === 'none') {
                if (node?.type === "FILE") {
                  result.push(toJS(node))
                }
                if (node?.type === "FOLDER" && isAllNodeHasCombineRights(node, currentGroup, usersStore.currentUser)) {
                  result.push(toJS(node))
                }
              }
            } else { // user belong to user-group but don't have group rights ,check user rights
              if (userRights) {
                if (userRights.rights === 'none') {
                  if (node?.type === "FILE") {
                    result.push(toJS(node))
                  }
                  if (node?.type === "FOLDER" && isAllNodeHasCombineRights(node, currentGroup, usersStore.currentUser)) {
                    result.push(toJS(node))
                  }
                }
              } else {
                if (node.indent === 0 && node?.type === "FILE") {
                  // first childrent of the root folder
                  // result.push(toJS(node))
                }
              }
            }
          }
        } else {  // user don't belong to user-group ,check user rights
          if (userRights) {
            if (userRights.rights === 'none') {
              if (node?.type === "FILE") {
                result.push(toJS(node))
              }
              const isAllHidden = isAllNodeHasCombineRights(node, currentGroup, usersStore.currentUser);
              if (node?.type === "FOLDER" && isAllHidden ) {
                result.push(toJS(node))
              }
            }
          } else {
            // the outermost treenode
            // All the first level of folder will be have default modify rights
            if ((!node.indent || node.indent === 0) && node?.type === "FILE") {
              // result.push(toJS(node))
            }
          }
        }
        // If folder have children so push all its children to array to check
        if (node.children && node.type === "FOLDER") {
          node.children.map(child => stack.push(child))
        }
      }
      return result
    }
  }

  const traversalTree = (data, parentKey, indent = 0) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          children: traversalTree(item.children, item.key, indent + 1),
          parentKey,
          indent
        };
      }
      return {
        ...item,
        parentKey,
        indent
      };
    });
  }

  const addUserIfNotExists = (array, object) => {
    const userId = object._id;
    // Check if object with the same ID already exists in the array
    const existingObject = array.find(item => item.id === userId);
    if (!existingObject) {
      // Object doesn't exist, push it to the array
      array.push({
        id: object._id,
        type: 'user',
        email: object.email,
        username: object.username,
        name: generateUsername(object) // generate name from frist name and last name
      });
    }
    return array;
  }

  const processedUserGroups = useMemo(() => {
    // Perform some data processing or transformation
    const combineGroups = []
    if (userGroupStore.groups && userGroupStore.groups.length > 0) {
      userGroupStore.groups.map((value) => {
        combineGroups.push({
          id: value.id,
          name: value.name,
          type: 'user-group'
        })
      });
    }
    if (projectTeamsStore.teamList && projectTeamsStore.teamList.length > 0) {
      projectTeamsStore.teamList.map((member) => {
        if (member.user && (member.inviteStatus === "accepted" || member.inviteStatus === "waiting_accept"))
          addUserIfNotExists(combineGroups, member.user)
      });
    }
    userGroupStore.setProcessedUserGroups(combineGroups)
    return combineGroups;
  }, [userGroupStore.groups, projectTeamsStore.teamList]);

  useEffect(() => {
    if (userGroupStore.groups && projectStore.currentUserRoleInProject) {
      let treeData = projectStore.projectDetail.treeData || [];
      let currentGroup = userGroupStore.checkCurrentUserGroup(
        usersStore.currentUser,
        userGroupStore.groups
      );
      let nodeNoAccessRights = getAllNodeNoRights(
        traversalTree(treeData),
        userGroupStore.groups,
        currentGroup,
        projectStore.currentUserRoleInProject
      );
      userGroupStore.setNodeNoAccessRights(nodeNoAccessRights);
      if (nodeNoAccessRights && nodeNoAccessRights.length > 0) {
        // hanldeVisibleModel3DGroup(nodeNoAccessRights);
      }
    }
  }, [
    projectStore.displayPanel,
    projectStore?.projectDetail?.treeData,
    userGroupStore.groups,
    projectStore.currentUserRoleInProject
  ]);

  useEffect(() => {
    if (!sketchingStore.showProjectSketchDrawer && adminStore.organizationActiveTab !=='1' && adminStore.organizationActiveTab !== 'library') {
      sketchingStore.mergeSketchLibraryElement(props.match.params.projectId).then(res => {
        //
      })
        .catch(err => {
          sketchingStore.setSketchLibraryElements(adminStore.defaultTreeData)
        })
    }
  }, [sketchingStore.currentSketchElement, organizationStore.currentSketchElement])



  useEffect(() => {
    if (!capturesStore.cameraData) return
    if (!viewerRef.current) return
    if (!viewerRef.current.cesiumElement) return
    let cam = capturesStore.cameraData
    let flyOption = {
      duration: cam.duration !== 'undefined' ? cam.duration : 1,
    }
    if (cam.position) {
      let destination = new Cartesian3(
        cam.position.x,
        cam.position.y,
        cam.position.z
      )
      flyOption.destination = destination
      // setFly(position)
    }

    if (cam.direction) {
      let direction = new Cartesian3(
        cam.direction.x,
        cam.direction.y,
        cam.direction.z
      )
      let up = new Cartesian3(cam.up.x, cam.up.y, cam.up.z)
      flyOption.orientation = {
        direction,
        up,
      }
    }
    viewerRef.current.cesiumElement.camera.flyTo(flyOption)
    capturesStore.setCameraData(false)
  }, [capturesStore.cameraData])

  useEffect(() => {
    if (!viewerRef?.current) return
    if (!viewerRef?.current?.cesiumElement) return
    const viewer = viewerRef.current.cesiumElement
    if (viewer?.baseLayerPicker?.viewModel) {
      Cesium.subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, 'selectedImagery', function (newValue) {
        if (projectStore?.projectDetail?.metadata) {
          const _projectMetadata = { ...projectStore?.projectDetail?.metadata }
          _projectMetadata[`selectedBaselayer${usersStore.currentUser._id}`] = newValue.name
          let newData = {
            metadata: _projectMetadata,
            store: 'metadata'
          }
          projectStore.updateProjectData(newData).then((res) => {
            projectStore.projectDetail.metadata = res.metadata
          }).catch(err => {

          })
        }
      });
    }
  }, [viewerRef?.current?.cesiumElement])

  const setDataLight = (data) => {
    projectSettingStore.setLightSetting(data?.lightSetting || {})
    commonStore.setenableShadows(data?.shadowsSetting?.enableShadows || data?.enableShadows)
    projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", data?.shadowsSetting?.enableShadows || data?.enableShadows)
    projectStore.setSoftShadows(data?.shadowsSetting?.softShadows || data?.renderResolution?.softShadows)
    projectSettingStore.setRenderResolution('softShadows', data?.shadowsSetting?.softShadows || data?.renderResolution?.softShadows)
    projectStore.setShadowDarkness(data?.shadowsSetting?.shadowDarkness || data?.renderResolution?.shadowDarkness)
    projectSettingStore.setRenderResolution('shadowDarkness', data?.shadowsSetting?.shadowDarkness || data?.renderResolution?.shadowDarkness)
    projectStore.setShadowDistance(data?.shadowsSetting?.shadowDistance || data?.renderResolution?.shadowDistance)
    projectSettingStore.setRenderResolution('shadowDistance', data?.shadowsSetting?.shadowDistance || data?.renderResolution?.shadowDistance)
    projectStore.setShadowAccuracy(data?.shadowsSetting?.shadowAccuracy || data?.renderResolution?.shadowAccuracy)
    projectSettingStore.setRenderResolution('shadowAccuracy', data?.shadowsSetting?.shadowAccuracy || data?.renderResolution?.shadowAccuracy)
    return true
  }


  useEffect(() => {
    if (projectStore.projectDetail._id && projectStore.targetViewPoint) {
      let data
      if (projectStore?.typeViewPoint === 'viewpoint') {
        data = toJS(projectStore.currentViewpoint)
      } else if (projectStore?.typeViewPoint === 'topic3DView') {
        data = toJS(topicStore.currentTopic3DView)
      } else if (projectStore?.typeViewPoint === 'topic') {
        data = toJS(topicStore.viewingTopicForm)
      }
      const isBefore = data?.cloneData?.isBefore || checkOldViewPoint(data?.updatedAt || data?.createdAt);
      // 4d schedule
      if (data?.cameraData?.timeSlider) {
        let time = moment(data.cameraData.timeSlider)
        schedulingStore.setCurrentViewingTime(time)
      }

      if (data?.treeData) {
        //navigationDistanceLimit
        const navigationDistanceLimit = data?.treeData?.navigationDistanceLimit
        if (navigationDistanceLimit) {
          if (projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object') {
            projectStore.setNavigationStyles({ ...projectStore.navigationStyles, distanceLimit: navigationDistanceLimit || -1 })
          }
          else {
            projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: navigationDistanceLimit || -1 })
          }
        }
        //allowUnderground
        const navigationStyles = projectStore?.navigationStyles;
        const navigationAllowUnderground = data.treeData?.navigationAllowUnderground;
        if (navigationStyles && typeof navigationStyles === 'object') {
          projectStore.setNavigationStyles({ ...navigationStyles, allowUnderground: navigationAllowUnderground ?? true })
        } else {
          projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, allowUnderground: navigationAllowUnderground ?? true })
        }
        // active hide area
        const hideAreaData = data.treeData?.hideAreaData;
        const clippingData = data?.treeData?.clippingData;
        if ((!clippingData || typeof clippingData !== 'object') && hideAreaData && typeof hideAreaData === 'object') {
          polylineCutToolStore.setHideArea(hideAreaData);
          if (hideAreaData?._id !== projectStore.projectDetail.tilesetData?.hiddenArea?._id)
            projectStore.setProjectDetail({ ...projectStore.projectDetail, tilesetData: { ...projectStore.projectDetail.tilesetData, hiddenArea: hideAreaData } });
          if (polylineCutToolStore.showForm) polylineCutToolStore.setShowForm(false)
        } else {
          polylineCutToolStore.setHideArea(false);
        }
        // active clipping plane 
        if (clippingData && typeof clippingData === 'object') {
          projectStore.setViewMode('default mode')
          projectStore.setClippingViewPoint(clippingData)
          projectStore.setClippingMode(clippingData?.clippingMode)
          if (clippingData?.clippingMode === 'alignment') {
            alignmentStore.setCurrentStep(clippingData?.currentStep || 0) // current step
            // alignmentStore.setChangeSlider(true)
          }
        } else {
          projectStore.setClippingMode(false)
          projectStore.setClippingViewPoint(false)
        }

        let visibleTilesets = Array.isArray(data.treeData) ? data.treeData : data.treeData?.visibleTilesets
        let visible4DModels = Array.isArray(data.treeData) ? data.treeData : (data.treeData?.visible4DModels || [])
        let visibleClipModels = data.treeData && data.treeData.visibleClipModels ? data.treeData?.visibleClipModels : []
        const modelList = projectStore.modelList.length ? projectStore.modelList : projectStore.projectDetail.model3DS
        let tempModel3D = toJS(modelList).map(item => {
          return { modelId: item._id, isVisible: isBefore ? true : false, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, data: item.data, isVisible4D: true, endDate: item.endDate, startDate: item.startDate, isVisibleClip: true, }
        })
        if ((visibleTilesets && visibleTilesets.length > 0) || (visible4DModels && visible4DModels.length > 0) || (visibleClipModels && visibleClipModels.length > 0)) {
          const _visibletileset = tempModel3D.map(function (newData) {
            let isExist = visibleTilesets.find(c => c.modelId === newData.modelId)
            let isExist4D = visible4DModels && visible4DModels.length > 0 ? visible4DModels.find(c => c.type4D === 'model' && c.modelId === newData.modelId) : false
            let isExistClip = visibleClipModels && visibleClipModels.length > 0 ? visibleClipModels.find(c => c.modelId === newData.modelId) : false
            if (isExist) {
              newData.isVisible = isExist.isVisible
            }
            if (isExist4D) {
              newData.isVisible4D = false
            }
            if (isExistClip) {
              newData.isVisibleClip = false
            }
            return newData
          });
          projectStore.setVisibleTilesets(_visibletileset)
        } else if ((visibleTilesets && visibleTilesets.length === 0) && (visible4DModels && visible4DModels.length === 0) && (visibleClipModels && visibleClipModels.length === 0)) {
          projectStore.setVisibleTilesets(tempModel3D)
        }
        //sketch
        let tempSketch = toJS(sketchingStore.arrSketches).map(item => {
          return { sketchId: item._id
            , isVisible: isBefore ? true : false
            , isVisible4D: true, endDate: item.endDate, startDate: item.startDate }
        })
        if ((data.treeData?.visibleSketches && data.treeData?.visibleSketches?.length > 0) || (visible4DModels && visible4DModels.length > 0)) {
          let _visibleSketches = data?.treeData?.visibleSketches || []
          const _visiblesketch = tempSketch.map(function (newData) {
            let isExist = _visibleSketches?.find(c => c.sketchId === newData.sketchId)
            let isExist4D = visible4DModels.find(c => c.type4D === 'sketch' && c.sketchId === newData.sketchId)
            if (isExist) {
              newData.isVisible = isExist.isVisible
              // newData.isTempHidden = isExist.isTempHidden
            }
            if (isExist4D) {
              newData.isVisible4D = false
            }
            return newData
          });
          sketchingStore.setVisibleSketches(_visiblesketch)
        } else if ((data.treeData?.visibleSketches && data.treeData?.visibleSketches?.length === 0) || (visible4DModels && visible4DModels.length === 0)) {
          sketchingStore.setVisibleSketches(tempSketch)
        }

        // 4d for folder
        const _visible4DFolders = data?.treeData?.visible4DFolders
        if (_visible4DFolders) {
          projectStore.setVisible4DFolders(_visible4DFolders)
        } else {
          initialVisibleFolder()
        }

        // visible4DFolders
        // project link
        let listProjectLink = toJS(projectStore.listProjectLink)
        if (listProjectLink.length > 0 && data?.treeData?.projectLinks?.length > 0) {
          data.treeData.projectLinks.map(projectLink => {
            listProjectLink = listProjectLink.map(item => {
              if (item.id === projectLink?.projectLinkId) {
                item.visibleData = projectLink.visibleData
                const index = projectLink.visibleData?.findIndex(x => x.userId === usersStore.currentUser?.id)
                if (index > -1 && projectLink.visibleData[index]) {
                  item.isVisible = projectLink.visibleData[index].isVisible
                  item.isVisible4D = projectLink.visibleData[index].isVisible4D
                  item.isVisibleClip = projectLink.visibleData[index].isVisibleClip
                }
              }
              return item
            })
          })
          projectStore.setListProjectLink(listProjectLink)
        }

        //topics
        let arrType = []
        let topicData = topicStore.topicList
        if (topicData.length) {
          topicData.map(topic => {
            let find = arrType.find(c => c.topictype === topic.topictype)
            if (!find) {
              arrType.push(toJS(topic))
            }
          })
          let topicNoType = topicData.find(c => !c.topictype)
          if (topicNoType) {
            arrType.push({ topictype: 'No type' })
          }
          let tempTopic = arrType.filter(topic => topic.topictype).map(item => {
            return { type: 'topic', isShow: isBefore ? true : false, isLeaf: true, title: item.topictype, controlName: item.topictype }
          })
          if (data.treeData.visibleTopic && data.treeData.visibleTopic.length > 0) {
            let hiddenData = data.treeData.visibleTopic
            const _visiblestopic = tempTopic.map(function (newData) {
              let isExist = hiddenData.find(c => c.controlName === newData.controlName)
              if (isExist) {
                newData.isShow = isExist.isShow
              }
              return newData
            });
            topicStore.setVisibleTopic(_visiblestopic)
          } else {
            topicStore.setVisibleTopic(tempTopic)
          }
        }

        //feedback
        let tempFeedback = []
        if (feedbackStore.feedbackVisualization && feedbackStore.feedbackVisualization.length > 0) {
          let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
          let arr2 = [...new Set(layerGenericFeedback.flat(1))];

          const feedbackForms = {}
          for (let i = 0; i < arr2.length; i++) {
            const item = arr2[i];
            if (!feedbackForms[item.name]) {
              feedbackForms[item.name] = []
            }
            feedbackForms[item.name].push(item)
          }

          for (const key in feedbackForms) {
            const GenericFeedbacks = feedbackForms[key];
            const el = GenericFeedbacks[0]
            let _subchild = {
              title: el.title,
              isShow: isBefore ? true : false,
              isLeaf: true,
              key: el.name,
              type: 'feedback',
              controlName: el.name,
            }
            tempFeedback.push(_subchild)
          }
          if (data.treeData.visibleFeedback && data.treeData.visibleFeedback.length > 0) {
            let hiddenData = data.treeData.visibleFeedback
            const _visiblestopic = tempFeedback.map(function (newData) {
              let isExist = hiddenData.find(c => c.controlName === newData.controlName)
              if (isExist) {
                newData.isShow = isExist.isShow
              }
              return newData
            });
            feedbackStore.setVisiblFeedback(_visiblestopic)
          } else {
            feedbackStore.setVisiblFeedback(tempFeedback)
          }
        }

        //fb forms
        let tempFeedbackForms = []
        if (feedbackStore.feedbackformNormalVisualization.length > 0) {
          let _fbArrs = feedbackStore.feedbackformNormalVisualization
          for (const element of _fbArrs) {
            let _subchild = {
              title: element.title,
              isLeaf: true,
              isShow: isBefore ? true : false,
              key: element.id,
              type: 'feedback',
              controlName: element.id,
              typeFolder: 'form'
            }
            tempFeedbackForms.push(_subchild)
          }
          if (data?.treeData?.visibleFeedbackForms && data?.treeData?.visibleFeedbackForms.length > 0) {
            let hiddenData = data?.treeData?.visibleFeedbackForms
            const _visiblestopic = tempFeedbackForms.map(function (newData) {
              let isExist = hiddenData.find(c => c.controlName === newData.controlName)
              if (isExist) {
                newData.isShow = isExist.isShow
              }
              return newData
            });
            feedbackStore.setVisiblFeedbackForm(_visiblestopic)
          } else {
            feedbackStore.setVisiblFeedbackForm(tempFeedbackForms)
          }
        }

      }
      if (typeof data?.cameraData?.showMap === "boolean") {
        projectStore.setShowMap(data?.cameraData?.showMap)
        projectSettingStore.setSystemProjectSetting({ showMap: data?.cameraData?.showMap })
        viewerRef.current.cesiumElement.scene.imageryLayers._layers[0].show = projectStore.showMap
      }
      if (data?.cameraData?.selectedBaselayer) {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
          const viewer = viewerRef.current.cesiumElement
          defaultImagery.forEach((l) => {
            if (l.name === data?.cameraData?.selectedBaselayer) {
              if (viewer.baseLayerPicker) {
                viewer.baseLayerPicker.viewModel.selectedImagery = l
                l.show = projectStore.showMap
              }
            }
          })

          viewerRef.current.cesiumElement.scene.imageryLayers._layers[0].show = projectStore.showMap
        }

      }
      if (typeof data?.cameraData?.is2D == "boolean") {
        if (!projectStore.projectDetail.isPublic) {
          projectStore.set2D(data?.cameraData?.is2D)
        } else {
          if (projectStore.check2D.status) {
            projectStore.set2D(data?.cameraData?.is2D)
          }
          if (!projectStore.check2D.load) {
            projectStore.setCheck2D({ status: true })
            setTimeout(() => {
              projectStore.set2D(data?.cameraData?.is2D)
            }, 1000)
          }
          projectStore.setCheck2D({ load: true })
        }
      }
      const renderResolution = data?.cameraData?.renderResolution
      if (renderResolution?.maximumAttenuation) {
        projectStore.setMaximumAttenuation(renderResolution?.maximumAttenuation)
        projectSettingStore.setRenderResolution('maximumAttenuation', renderResolution?.maximumAttenuation)
      }
      if (renderResolution?.geometricErrorScale) {
        projectStore.setGeometricErrorScale(renderResolution?.geometricErrorScale)
        projectSettingStore.setRenderResolution('geometricErrorScale', renderResolution?.geometricErrorScale)
      }
      if (renderResolution?.eyeDomeLightingStrength) {
        projectStore.setEyeDomeLightingStrength(renderResolution?.eyeDomeLightingStrength)
        projectSettingStore.setRenderResolution('eyeDomeLightingStrength', renderResolution?.eyeDomeLightingStrength)
      }
      if (renderResolution?.eyeDomeLightingRadius) {
        projectStore.setEyeDomeLightingRadius(renderResolution?.eyeDomeLightingRadius)
        projectSettingStore.setRenderResolution('eyeDomeLightingRadius', renderResolution?.eyeDomeLightingRadius)
      }

      if (typeof data?.cameraData?.enableShadows === "boolean") {
        commonStore.setenableShadows(data?.cameraData?.enableShadows)
        projectSettingStore.setSystemProjectSetting({ enableShadows: data?.cameraData?.enableShadows })
      }
      if (data?.cameraData?.lightSetting) {
        setDataLight(data?.cameraData)
      }
      if (typeof data?.cameraData?.globeBehind === "boolean") {
        projectStore.setGlobeBehind(data?.cameraData?.globeBehind)
        viewerRef.current.cesiumElement.scene.globe.depthTestAgainstTerrain = !projectStore.globeBehind
      }

      if (typeof data?.cameraData?.nearValue === 'number') {
        commonStore.setNearValue(data?.cameraData?.nearValue)
        projectSettingStore.setAssignObjSystemProjectSetting('nearValue', data?.cameraData?.nearValue)
      }

      if (typeof data?.cameraData?.farDistance === 'number') {
        commonStore.setFarDistance(data?.cameraData?.farDistance)
        projectSettingStore.setAssignObjSystemProjectSetting('farDistance', data?.cameraData?.farDistance)
      }
      if (typeof data?.cameraData?.nearDistance === 'number') {
        commonStore.setNearDistance(data?.cameraData?.nearDistance)
        projectSettingStore.setAssignObjSystemProjectSetting('nearDistance', data?.cameraData?.nearDistance)
      }
      //if (data?.cameraData?.nearValue && data?.cameraData?.farDistance) {
      let datasetting = projectSettingStore.systemProjectSetting
      //let _value = {
      datasetting.nearValue = data?.cameraData?.nearValue;
      datasetting.nearDistance = data?.cameraData?.nearDistance || 0;
      datasetting.farDistance = data?.cameraData?.farDistance || 1000;
      projectSettingStore.assignSystemProjectSetting(datasetting)
      let scene = viewerRef.current.cesiumElement.scene;
      let globe = scene.globe
      const setTranslucencyEnabled = function () {
        if (data?.cameraData?.farDistance && data?.cameraData?.nearValue) {
          globe.translucency.enabled = true
          globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
            data?.cameraData?.nearDistance || 0,
            1 - data?.cameraData?.nearValue,
            data?.cameraData?.farDistance,
            1.0
          );
          if (scene.requestRenderMode) { scene.requestRender(); }
        }
        else {
          globe.translucency.enabled = false
          if (scene.requestRenderMode) { scene.requestRender(); }
        }
      }
      setTranslucencyEnabled()
      // }
      viewerRef.current.cesiumElement.scene.imageryLayers._layers[0].show = projectStore.showMap
    }
  }, [projectStore.targetViewPoint])

  useEffect(() => {
    if (!viewerRef.current || !viewerRef.current.cesiumElement) return
    var handler = new ScreenSpaceEventHandler(
      viewerRef.current.cesiumElement.canvas
    )

    if (measureStore.measureMode == 'point') {
      setMeasurePoints(
        <MeasurePoint
          viewer={viewerRef}
          handler={handler}
        />
      )
    } else if (measureStore.measureMode === 'distance') {
      setMeasureDistance(
        <MeasureDistance
          viewer={viewerRef}
          handler={handler}
        />
      )
    } else if (measureStore.measureMode === 'area') {
      setMeasureArea(
        <MeasureArea
          viewer={viewerRef}
          handler={handler}
        />
      )
    } else if (measureStore.measureMode === 'polyline') {
      setMeasurePolyline(
        <MeasurePolyline
          viewer={viewerRef}
          handler={handler}
        />
      )
    } else {
      if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      setMeasurePoints(false)
      setMeasureDistance(false)
      setMeasureArea(false)
      setMeasurePolyline(false)
      if (viewerRef.current && viewerRef.current.cesiumElement) {
        startReRender()
      }
    }
    return () => {
      if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      setMeasurePoints(false)
      setMeasureDistance(false)
      setMeasureArea(false)
      setMeasurePolyline(false)
    }
  }, [measureStore.measureMode])

  const setrequestRenderMode = (requestRender) => {
    if (isValidCanvas())
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {
        viewerRef.current.cesiumElement.scene.requestRenderMode = requestRender
      }
  }

  useEffect(() => {
    if (projectStore.currentModelId) {
      var tile = findTile(projectStore.currentModelId, projectStore.tileViews)
      if (tile) tile.debugShowBoundingVolume = projectStore.showBoudingVolume
    }
  }, [projectStore.showBoudingVolume])

  useEffect(() => {
    syncShowMap()
  }, [projectStore.showMap])

  useEffect(() => {
    syncbackgroundColorSetting()
  }, [commonStore.backgroundColorSetting])

  useEffect(() => {
    syncglobeBehind()
  }, [projectStore.globeBehind])

  useEffect(() => {
    syncshowInspectorTool()
    syncshowAntialiasing()
    syncshowHUD()
    syncglobeBehind()
    syncbackgroundColorSetting()
    setrequestRenderMode(uiStore.requestRender);
    syncShowMap()
  }, [viewerRef.current])

  useEffect(() => {
    setrequestRenderMode(uiStore.requestRender);
  }, [uiStore.requestRender])

  useEffect(() => {
    syncshowAntialiasing()
  }, [projectStore.msaaSamples, projectStore.showAntialiasing, projectStore.fxaa])

  // effect show/hide project plane
  useEffect(() => {
    if (refPointView && refPointView?.ref?.current?.cesiumElement) {
      refPointView.ref.current.cesiumElement.show = projectStore.showRefPoint
      setRefPointView(refPointView)
      startReRender()
    }
  }, [projectStore.showRefPoint])

  // effect load project plane (if refpoint change then auto update project plane)
  useEffect(() => {
    if (projectStore.projectDetail?._id && projectStore.projectDetail.tilesetData?.RefPoint) {
      setProjectPlaneModel(projectStore.projectDetail.tilesetData?.RefPoint, projectStore.projectDetail?.elevationSystem)
    }
  }, [projectStore.projectDetail.tilesetData?.RefPoint])

  useEffect(() => {
    syncshowHUD()
  }, [projectStore.showHUD])

  useEffect(() => {

    if (isValidCanvas()) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {

        if (!isSafari && projectStore.showAmbientOcclusion && !projectStore.isMasking) {
          if (!PostProcessStageLibrary.isAmbientOcclusionSupported(viewerRef.current.cesiumElement.scene)) {
            window.alert(t('this-browser-does-not-support-the-ambient-occlusion-post-process'));
          }

          var ambientOcclusion = viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion;
          if (!isNaN(ambientSetting.blurStepSize)) {
            ambientOcclusion.uniforms.blurStepSize = Number(
              ambientSetting.blurStepSize
            );
            startReRender()
          }
        }
      }
    }
  }, [projectStore.ambientOccSetting.blurStepSize])

  function syncbackgroundColorSetting() {
    if (commonStore.backgroundColorSetting && isValidCanvas() && (projectStore.projectDetail?._id)) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {
        viewerRef.current.cesiumElement.scene.globe.baseColor = Color.fromCssColorString(commonStore.backgroundColorSetting)
      }
    }
  }

  function syncglobeBehind() {
    if (isValidCanvas() && (projectStore.projectDetail?._id)) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {
        viewerRef.current.cesiumElement.scene.globe.depthTestAgainstTerrain = !projectStore.globeBehind
      }
    }
  }

  function syncShowMap() {
    if (isValidCanvas() && (projectStore.projectDetail?._id)) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene.imageryLayers._layers[0]) {

        viewerRef.current.cesiumElement.scene.imageryLayers._layers[0].show = projectStore.showMap
        viewerRef.current.cesiumElement.scene.requestRender();
      }
    }
  }

  function syncshowHUD() {
    if (isValidCanvas() && (projectStore.projectDetail?._id)) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {
        viewerRef.current.cesiumElement.scene.debugShowFramesPerSecond = projectStore.showHUD
      }
    }
  }

  function syncshowAntialiasing() {
    if (isValidCanvas() && (projectStore.projectDetail?._id)) {
      if (viewerRef.current.cesiumElement._cesiumWidget && viewerRef.current.cesiumElement.scene) {
        const metadata = projectSettingStore.systemProjectSetting || projectStore.getCurrentProjectSetting()?.renderResolution
        //projectStore.setFXAA((metadata?.renderResolution?.fxaa || projectStore.fxaa) || true);
        //projectStore.setMSAASamples((metadata?.renderResolution?.msaaSamples || projectStore.msaaSamples) || 4)
        viewerRef.current.cesiumElement.scene.postProcessStages.fxaa.enabled = projectStore.showAntialiasing ? ((metadata?.renderResolution?.fxaa || projectStore.fxaa) || true) : false;
        if (!viewerRef.current.cesiumElement.scene?.msaaSupported) {
          window.alert("This browser does not support MSAA.");
          return;
        }
        viewerRef.current.cesiumElement.scene.msaaSamples = projectStore.showAntialiasing ? ((metadata?.renderResolution?.msaaSamples || projectStore.msaaSamples) || 4) : 1;
      }
    }
  }


  function syncshowInspectorTool() {
    if (isValidCanvas()) {
      var cesiumWidget = viewerRef.current.cesiumElement.cesiumWidget;
      if (!cesiumWidget) {
        return
      }
      if (viewerRef.current.cesiumElement.scene) {
        // if(!viewerRef.current.cesiumElement.cesium3DTilesInspector || !viewerRef.current.cesiumElement.cesium3DTilesInspector.viewModel){
        //   viewerRef.current.cesiumElement.extend(viewerCesium3DTilesInspectorMixin);
        // }
        if (projectStore.showInspectorTool) {
          if (!viewerRef.current.cesiumElement.cesium3DTilesInspector)
            viewerRef.current.cesiumElement.extend(viewerCesium3DTilesInspectorMixin);

          viewerRef.current.cesiumElement.cesium3DTilesInspector.container.hidden = false;
          viewerRef.current.cesiumElement.cesium3DTilesInspector.viewModel.picking = true;
        }
        else {
          if (viewerRef.current.cesiumElement.cesium3DTilesInspector) {
            viewerRef.current.cesiumElement.cesium3DTilesInspector.container.hidden = true;
            viewerRef.current.cesiumElement.cesium3DTilesInspector.viewModel.picking = false;
          }
        }
      }
    }
  }

  useEffect(() => {
    syncshowInspectorTool()
  }, [projectStore.showInspectorTool])


  const updateProjectDetail = (data) => {
    projectStore.setProjectDetail({ ...projectStore.projectDetail, thumbnail: data.thumbnail })
  }

  // effect click save view point
  useEffect(() => {
    if (!capturesStore.clickAdd) return
    const captureViewPoint = async () => {
      if (capturesStore.clickAdd) {
        const startTime = Date.now();
        let data = getVisileDataTree(projectStore, topicStore, sketchingStore, feedbackStore, usersStore.currentUser?._id)
        let cam = await getCameraData("Viewpoint", capturesStore.captureList, data)
        const metadata = projectSettingStore.systemProjectSetting
        if (cam && cam.cameraData) {
          cam = addViewPointCameraData(projectStore, commonStore, cam, metadata, viewerRef.current.cesiumElement);
          let isUpdateThumnail = false;
          if (!capturesStore.captureList && capturesStore.captureList.length < 0) {
            isUpdateThumnail = projectStore?.projectDetail?._id;
          } else {
            cam.id = 'new'
            const temp = capturesStore.captureList ? [...capturesStore.captureList, cam].sort((a, b) => a.name.localeCompare(b.name)) : []
            let newIndex = temp.findIndex(c => c.id === cam.id);
            if (newIndex > -1 && newIndex === 0) {
              isUpdateThumnail = projectStore?.projectDetail?.id;
            }
            delete cam.id;
          }
          if (!projectStore.projectDetail?.thumbnailFromViewpoint) {
            isUpdateThumnail = false;
          }
          await capturesStore.addCaptureViews(cam, isUpdateThumnail, {
            startTime,
            user: usersStore.currentUser,
            project: projectStore?.projectDetail?.name,
            organization: projectStore?.projectDetail?.organization?.name,
          }, updateProjectDetail)
          history.push(`${props.location.pathname}?viewpoint=${cam.name}`)
          capturesStore.setClickAdd(false)
        }
      }
    }
    captureViewPoint()
  }, [capturesStore.clickAdd])

  useEffect(() => {
    if (projectStore.projectDetail?._id && Array.isArray(projectStore.visibleTilesets) && !capturesStore.isDrawerCaptureVisible && !topicStore.isShowTopicEditor) {
      let nodeHidden = projectStore.visibleTilesets.filter(c => !c.isVisible)
      projectStore.setModelHiddenBeforeOpenViewPoint(nodeHidden)
    }
  }, [projectStore.visibleTilesets, projectStore.projectDetail?._id])

  useEffect(() => {
    if (!capturesStore.isDrawerCaptureVisible && !topicStore.isShowTopicEditor && !Object.keys(topicStore.viewingTopicForm)?.length > 0 && !Object.keys(projectStore.currentViewpoint)?.length > 0) {
      projectStore.setCurrentViewpoint({})
      let hiddenData = projectStore.modelHiddenBeforeOpenViewPoint
      let temp = toJS(projectStore.modelList).map(item => {
        return { modelId: item._id, isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, data: item.data, isVisible4D: true, endDate: item.endDate, startDate: item.startDate, isVisibleClip: true }
      })
      if (hiddenData && hiddenData.length > 0) {
        const _visibletileset = temp.map(function (newData) {
          let isExist = hiddenData.find(c => c.modelId === newData.modelId)
          if (isExist) {
            newData.isVisible = false
            newData.isTempHidden = isExist.isTempHidden
          }
          return newData
        });
        projectStore.setVisibleTilesets(_visibletileset)
      } else {
        projectStore.setVisibleTilesets(temp)
      }
    }
  }, [capturesStore.isDrawerCaptureVisible, topicStore.isShowTopicEditor])


  //effect for save 3D view in topic
  useEffect(() => {
    const capture3DView = async () => {
      const startTime = Date.now();
      let data = getVisileDataTree(projectStore, topicStore, sketchingStore, feedbackStore, usersStore.currentUser?._id)
      let cam = await getCameraData("3DView", topicStore.list3DViewTopic, data);
      const metadata = projectSettingStore.systemProjectSetting;
      if (cam) {
        if (cam.cameraData) {
          cam = addViewPointCameraData(projectStore, commonStore, cam, metadata, viewerRef.current.cesiumElement);
        }
        topicStore.addCapture3DView(cam, {
          startTime,
          user: usersStore.currentUser,
          project: projectStore?.projectDetail?.name,
          organization: projectStore?.projectDetail?.organization?.name,
        })
        topicStore.setSave3DView(false)
        topicStore.setIsCheckSaveViewDone(true)
        topicStore.setIsShowLoading(true)
      }
    }
    if (topicStore.isSave3DView) {
      capture3DView()
    }
  }, [topicStore.isSave3DView])

  //effect for save 3D location in topic
  useEffect(() => {
    if (!isValidCanvas()) return
    if (topicStore.isTopic3DLocationVisible) {
      if (viewerRef.current.cesiumElement) {
        setRenderTopic3DLocation(
          <Topic3DLocationControl
            viewer={viewerRef}
          />
        )
      }
    } else {
      setRenderTopic3DLocation(false)
    }
    return () => {
      setRenderTopic3DLocation(false)
    }
  }, [topicStore.isTopic3DLocationVisible])

  //effect for save 3D objects in topic
  useEffect(() => {
    if (!isValidCanvas()) return
    if (topicStore.isTopic3DObjectsVisible) {
      if (viewerRef.current.cesiumElement) {
        setRenderTopic3DObjects(
          <Topic3DObjectsControl
            viewer={viewerRef}
          />
        )
      }
    } else {
      setRenderTopic3DObjects(false)
    }
    return () => {
      setRenderTopic3DObjects(false)
    }
  }, [topicStore.isTopic3DObjectsVisible])

  //effect for show gantt select object model for task
  useEffect(() => {
    if (!isValidCanvas()) return
    if (projectGanttStore.selectObjectModel.open) {
      if (viewerRef.current.cesiumElement) {
        setRenderSelectObjectModelControl(
          <SelectObjectModelControl
            viewer={viewerRef}
          />
        )
      }
    } else {
      setRenderSelectObjectModelControl(false)
    }
    return () => {
      setRenderSelectObjectModelControl(false)
    }
  }, [projectGanttStore.selectObjectModel.open])

  //effect for add location in feedback editor
  useEffect(() => {
    if (!isValidCanvas()) return
    if (feedbackStore.showAddLocationControl) {
      if (viewerRef.current.cesiumElement) {
        setRenderAddLocationFeedbackEditor(
          <LocationControl
            viewer={viewerRef}
          />
        )
      }
    } else {
      setRenderAddLocationFeedbackEditor(false)
    }
    return () => {
      setRenderAddLocationFeedbackEditor(false)
    }
  }, [feedbackStore.showAddLocationControl])

  const loadTilesetByModel = async (modellist) => {
    console.log('start load tileset at ', new Date())
    const haveGoogleIonTitleSet = modellist.some(model => model.sourceType === 'external' && model.data.ionAssetId === 2275207)
    for (const model of modellist) {
      await TilesetByModel(model, projectStore.setTileViews, projectStore.tileViews, undefined,  haveGoogleIonTitleSet)
    }
    console.log('finish load tileset at ', new Date())
    function waitTilesetReady() {
      const waitUntilReady = async () => {
        // cut 3dtile
        let primitives = viewerRef?.current?.cesiumElement?.scene?.primitives?._primitives?.filter(function (value, index, arr) {
          return value?.isCesium3DTileset;
        });
        if (primitives?.length) {
          let data;
          if (projectStore?.typeViewPoint === 'viewpoint') {
            data = projectStore.currentViewpoint;
          } else if (projectStore?.typeViewPoint === 'topic3DView') {
            data = topicStore.currentTopic3DView;
          } else if (projectStore?.typeViewPoint === 'topic') {
            data = topicStore.viewingTopicForm;
          }

          const clippingData = data?.treeData?.clippingData;
          const hideAreaData = data?.treeData?.hideAreaData;

          //load hidden area (load all models then load hidden area)
          if (projectStore.projectDetail.tilesetData?.hiddenArea && !projectStore.clippingMode) {
            if (projectStore.projectDetail._id && projectStore.targetViewPoint !== 0) {
              if ((!clippingData || typeof clippingData !== 'object') && hideAreaData && typeof hideAreaData === 'object') {
                polylineCutToolStore.setHideArea(hideAreaData);
              } else polylineCutToolStore.setHideArea(false);
            } else polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea);
          }

          if (projectStore.projectDetail._id && projectStore.targetViewPoint) {
            // active clipping plane 
            if (clippingData && typeof clippingData === 'object') {
              projectStore.setViewMode('default mode')
              projectStore.setClippingViewPoint(clippingData)
              projectStore.setClippingMode(clippingData?.clippingMode)
              if (clippingData?.clippingMode === 'alignment') {
                alignmentStore.setCurrentStep(clippingData?.currentStep || 0) // current step
                // alignmentStore.setChangeSlider(true)
              }
            } else {
              projectStore.setClippingMode(false)
              projectStore.setClippingViewPoint(false)
            }
          }

          return true;
        } else {
          setTimeout(waitUntilReady, 1000);
        }
      };
      (async () => {
        await waitUntilReady();
      })();
    }
    waitTilesetReady();
  }

  const processModel3D = async (modelList) => {
    if (modelList) {
      modelList.forEach(m => {
        m.ref = false;
      });
      if (modelList?.length > 0) {
        const processingModelList = modelList.filter(model => model.data?.ionStatus === "IN_PROGRESS" || model.data?.ionStatus === "AWAITING_FILES");
        if (processingModelList?.length > 0) {
          let modelList = await checkModelIsReady(processingModelList, projectStore)
          if (modelList?.length > 0) {
            projectStore.setShowModalProcessingModel3D(true)
            setModalProcessingModel3D(<ModalProcessingModel3D processModel={modelList} />)
          }
        }

      }
    }
  }

  useEffect(() => {
    function filterAndAggregate(input) {
      const filteredMap = new Map();

      for (const item of input) {
        if (item?.ref?.current?.cesiumElement !== undefined) {
          const existingItem = filteredMap.get(item?.key);
          if (!existingItem || existingItem?.ref?.current?.cesiumElement === undefined) {
            filteredMap.set(item?.key, item);
          }
        } else if (!filteredMap.has(item?.key)) {
          filteredMap.set(item?.key, item);
        }
      }

      return Array.from(filteredMap.values());
    }
    if (projectStore.reRenderModel && projectStore.modelList?.length > 0) {
      const reloadTile = async () => {
        let model = projectStore.reRenderModel
        const scene = viewerRef?.current?.cesiumElement?.scene
        if (scene) {
          let destinationTile = findTile(model._id, projectStore.tileViews);
          if (destinationTile) {
            if (!projectStore.reRenderModel?.ref) {
              scene.primitives.remove(destinationTile);
              let tileViews = projectStore.tileViews.filter(tile => tile.key !== `${model._id}-tile`)
              projectStore.setTileViews(tileViews)
              await TilesetByModel(projectStore.reRenderModel, projectStore.setTileViews, tileViews)
              //  TilesetByModel(projectStore.reRenderModel, projectStore.setTileViews, tileViews)
              //projectStore.setModelList(projectStore.modelList.map(model => model._id === projectStore.reRenderModel ? projectStore.reRenderModel : model))
              projectStore.setReRenderModel(false)
            } else {
              let tileViews = filterAndAggregate(projectStore.tileViews)
              projectStore.setTileViews(tileViews)
              projectStore.setCurrentModelId(false);
              //projectStore.setNewModelId(model._id);
              //projectStore.setReRenderModel(false)
            }
          } else {
            let tileViews = projectStore.tileViews.filter(tile => tile.key !== `${model._id}-tile`);
            projectStore.setTileViews(tileViews);
            projectStore.setReRenderModel(false);
          }
        }
      }
      let isAvailable = projectStore.modelList.some(model => model._id === projectStore.reRenderModel?._id)
      isAvailable && reloadTile()
    }
    if (projectStore.modelList?.length) {
      let tileViews = filterAndAggregate(projectStore.tileViews);
      projectStore.setTileViews(tileViews);
    }
    return () => {
      //projectStore.setReRenderModel(false)
    }
  }, [projectStore.reRenderModel, projectStore.modelList])

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail) && !viewerRef.current?.cesiumElement) return
    if (projectStore.i3sViews.length > 0) {
      projectStore.i3sViews.map((i3sView) => {
        viewerRef.current.cesiumElement.scene.primitives.remove(i3sView);
      })
      projectStore.setI3sViews([]);
    }
    if (projectStore.modelList.length > 0) {
      // projectStore.setLoadingProgress(true)
      projectStore.Alignment.forEach(a => {
        onAlignmentReload(a)
      })
      loadTilesetByModel(projectStore.modelList)

      // for load model WMS
      setImageryView(
        <ImageryView
          viewer={viewerRef}
          modelList={projectStore.modelList.filter(model => (model.sourceType === 'WMS' || model.type === 'geotiff') && model.isDeleted === false)}
        />
      )

      //for load 3DCityDB View
      setCityDB3DView(
        <CityDB3DView
          viewer={viewerRef}
          mode3DCityDBlList={projectStore.modelList.filter(model => model.sourceType === 'City3DDB' && model.isDeleted === false)}
        />
      )

      // for load model WFS
      setGeoJsonView(
        <GeoJsonView
          viewer={viewerRef}
          canPicking={canPicking}
          modelListWFS={projectStore.modelList.filter(model => model.sourceType === 'WFS' && model.isDeleted === false)}
        />
      )

      setI3SDataProvider(
        <I3SDataProvider
          viewer={viewerRef.current.cesiumElement}
          modelListI3S={projectStore.modelList.filter(model => model.data.isI3sModel && model.isDeleted === false)}
        />
      )

      projectStore.setLoadingProgress(false)
    }
  }, [projectStore.modelList.length, projectStore.reRenderModel, projectStore.modelList])
// }, [projectStore.modelList.length, projectStore.targetViewPoint, projectStore.reRenderModel, projectStore.modelList])

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail) && !viewerRef.current.cesiumElement) return
    if (projectStore.i3sViewsLink.length > 0) {
      projectStore.i3sViewsLink.map((i3sView) => {
        viewerRef.current.cesiumElement.scene.primitives.remove(i3sView);
      })
      projectStore.setI3sViewsLink([]);
    }
    projectStore.setTileLinkViews([])
    setImageryViewLink(false)
    setCityDB3DViewLink(false)
    setGeoJsonViewLink(false)
    if (projectStore.listAllModel3DSLink.length > 0) {
      projectStore.setLoadingProgress(true)
      projectStore.listAllModel3DSLink.forEach(model => TilesetByModel(model, projectStore.setTileLinkViews, projectStore.tileLinkViews, true))
      // for load model WMS
      setImageryViewLink(
        <ImageryView
          viewer={viewerRef}
          modelList={projectStore.listAllModel3DSLink.filter(model => (model.sourceType === 'WMS' || model.type === 'geotiff') && model.isDeleted === false)}
          imageryViewLink={true}
        />
      )

      //for load 3DCityDB View
      setCityDB3DViewLink(
        <CityDB3DView
          viewer={viewerRef}
          mode3DCityDBlList={projectStore.listAllModel3DSLink.filter(model => model.sourceType === 'City3DDB' && model.isDeleted === false)}
          imageryViewLink={true}
        />
      )

      // for load model WFS
      setGeoJsonViewLink(
        <GeoJsonView
          viewer={viewerRef}
          geoJsonViewLink={true}
          canPicking={canPicking}
          modelListWFS={projectStore.listAllModel3DSLink.filter(model => model.sourceType === 'WFS' && model.isDeleted === false)}
        />
      )

      // setI3SDataProviderLink(
      //   <I3SDataProvider
      //     viewer={viewerRef.current.cesiumElement}
      //     modelListI3S={projectStore.listAllModel3DSLink.filter(model => model.data.isI3sModel && model.isDeleted === false)}
      //     i3sViewLink={true}
      //   />
      // )

      if (loadInit) {
        projectStore.setLoadingProgress(false)
      }
    }
  }, [projectStore.listAllModel3DSLink.length])

  const syncModelLinkVisibleState = async () => {
    if (isEmpty(projectStore.projectDetail) && !viewerRef.current.cesiumElement) return
    let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds(); // model visible by project tree node
    if (projectStore.tileLinkViews && projectStore.tileLinkViews.length > 0) {
      projectStore.tileLinkViews.map(tileView => {
        const tileElement = tileView.ref.current?.cesiumElement
        if (tileElement) {
          let isShow = true
          let idKey = tileView.key?.split("-") ? tileView.key?.split("-")[0] : null;
          let idElement = tileElement?.id || tileView?.props?.id
          const model = projectStore.listAllModel3DSLink.find(c => c.id === idElement);
          if (model && model?.isVisible4D) {
            if (!visibleTileViewIds.includes(idElement)) {
              isShow = false
            }
            if (projectStore.hiddenModelLinkIDs.includes(idElement)) {
              isShow = false
            }
            const Alignment = projectStore.Alignment.find(t => {
              return t && t.key === ((idElement ? idElement : idKey) + '-alignment')
            })
            tileElement.show = isShow;
            if (Alignment?.ref?.current?.cesiumElement) {
              Alignment.ref.current.cesiumElement.show = isShow
              const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
              entitiesCZML.forEach(e => {
                e.show = isShow
              })
              Alignment.show = isShow
            }
          }
          return tileView
        }
      })
    }
  }

  //  control  project link model visibility 
  useEffect(() => {
    syncModelLinkVisibleState()
    startReRender()
  }, [schedulingStore.timeSliderVisible, projectStore.tileLinkViews, projectStore.hiddenModelLinkIDs])
  /**
   * Effect show sketch view
   */
  useEffect(() => {
    if (sketchingStore.arrSketches && viewerRef.current.cesiumElement) {
      if (loadInit) {
        projectStore.setLoadingProgress(true)
      }
      setSketchView(
        <SketchView
          viewer={viewerRef}
          sketches={sketchingStore.arrSketches}
        />
      )
      if (loadInit) {
        projectStore.setLoadingProgress(false)
      }
    }
  }, [sketchingStore.arrSketches])

  useEffect(() => {
    if (polylineCutToolStore.hideArea && viewerRef.current.cesiumElement) {
      if (loadInit) {
        projectStore.setLoadingProgress(true)
      }
      setHiddenAreaView(
        <HiddenAreaView
          viewer={viewerRef}
          hideArea={polylineCutToolStore.hideArea}
        />
      )
      if (loadInit) {
        projectStore.setLoadingProgress(false)
      }
    }
  }, [polylineCutToolStore.hideArea.points, polylineCutToolStore.hideArea.edgeColor, polylineCutToolStore.hideArea.edgeWidth, polylineCutToolStore.hideArea.visibilitySource, polylineCutToolStore.hideArea.isCutTerrain, projectStore.clippingMode])

  /**
   * Effect show topic icon
   */

  useEffect(() => {
    if (topicStore.arrTopicLocation && viewerRef.current.cesiumElement) {
      if (projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject !== "project_public_viewer" && projectStore.currentUserRoleInProject !== "project_viewer") {
        projectStore.setLoadingProgress(true)
        setTopicLocationView(
          <TopicLocationView
            viewer={viewerRef}
            topicLocationData={topicStore.arrTopicLocation}
            location={props.location}
          />
        )
        startReRender()
        if (loadInit) {
          projectStore.setLoadingProgress(false)
        }
      }

    }
  }, [topicStore.arrTopicLocation, projectStore.currentUserRoleInProject, topicStore.visibleTopic])

  useEffect(() => {
    if (topicStore.visibleTopic) {
      let hidden = topicStore.visibleTopic.filter(c => !c.isShow)
      topicStore.setHiddenTopicType(hidden)
    }
  }, [topicStore.visibleTopic])

  /**
   * Effect show feedback answer generic icon
   */
  useEffect(() => {
    if (feedbackStore.feedbackVisualization && viewerRef.current.cesiumElement) {
      if (projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject !== "project_public_viewer" && projectStore.currentUserRoleInProject !== "project_viewer") {
        projectStore.setLoadingProgress(true)
        setFeedbackVisualization(
          <FeedbackAnswerGenericVisualization
            viewer={viewerRef}
            feedbackData={feedbackStore.feedbackVisualization}
          />
        )
        startReRender()
        if (loadInit) {
          projectStore.setLoadingProgress(false)
        }
      }

    }
  }, [feedbackStore.feedbackVisualization, projectStore.currentUserRoleInProject])

  /**
    * Effect show feedback form normal icon
    */
  useEffect(() => {
    if (feedbackStore.feedbackformNormalVisualization && viewerRef.current.cesiumElement) {
      if (loadInit) {
        projectStore.setLoadingProgress(true)
      }
      setFeedbackformVisualization(
        <FeedbackformVisualization
          viewer={viewerRef}
          feedbackData={feedbackStore.feedbackformNormalVisualization}
          projectId={projectStore.projectDetail._id}
          userId={usersStore.currentUser._id}
        />
      )
      startReRender()
      if (loadInit) {
        projectStore.setLoadingProgress(false)
      }
    }
  }, [feedbackStore.feedbackformNormalVisualization])


  useEffect(() => {
    let cm = getCurrentModel(
      projectStore.currentModelId,
      projectStore.modelList,
      projectStore.tileViews
    )


    if (cm) {
      if (cm.tile) {
        if (cm.model.sourceType !== 'external' || cm.model.data.ionAssetId) {
          // if (cm.model.type !== 'ifc')
          doSelectTile(cm.tile)
        }
        // projectStore.setCurrentTile(cm.tile.ref.current.cesiumElement)        
        if (projectStore.showBoudingVolume) {
          // Clear all ShowBoundingVolume tile 
          projectStore.tileViews.forEach(tileView => {
            if (tileView && tileView.ref && tileView.ref.current) {
              let tileElement = tileView.ref.current.cesiumElement
              if (tileElement) tileElement.debugShowBoundingVolume = false
            }
          })
          cm.tile.debugShowBoundingVolume = projectStore.showBoudingVolume
        }
      }
      // if (viewerRef.current) {
      //   try {
      //     // const viewer = viewerRef.current.cesiumElement
      //     // if (cm.tile) {
      //     //   if (projectStore.displayPanel != '')
      //     //     var hpr = hpr4ZoomTo(viewer, cm.tile, true)
      //     //   viewer.zoomTo(cm.tile, hpr)
      //     // }
      //     // var hpr = hpr4ZoomTo(viewer, cm.tile, true)
      //     if (!projectStore.showEditLocation) projectStore.setDisplayPanel(false)
      //   } catch (error) {
      //     console.log('error', error)
      //     alert('Data is not ready')
      //     projectStore.setZoomToModel(false)
      //   }
      // }
    } else {
      if (projectStore.showBoudingVolume) {
        // Clear all ShowBoundingVolume tile 
        projectStore.tileViews.forEach(tileView => {
          if (tileView && tileView.ref && tileView.ref.current) {
            let tileElement = tileView.ref.current.cesiumElement
            if (tileElement) tileElement.debugShowBoundingVolume = false
          }
        })
      }
    }
  }, [projectStore.currentModelId])

  useEffect(() => {
    if (projectStore.zoomToModel) {
      const model = projectStore.modelList.find(model => model._id === projectStore.zoomToModel);
      let cm = getCurrentModel(
        projectStore.zoomToModel,
        projectStore.modelList,
        projectStore.tileViews
      )
      if (viewerRef.current?.cesiumElement && model) {
        try {
          const viewer = viewerRef.current.cesiumElement
          if ((['WMS', 'WFS'].includes(model.sourceType) || ['geotiff'].includes(model.type)) && model?.data?.bbox) {
            if (model.data.projectPlaneToWGS84) {
              viewer.camera.setView({
                destination: Rectangle.fromDegrees(...model.data.projectPlaneToWGS84.bbox),
              });
            } else {
              if (['geotiff'].includes(model.type)) {
                viewer.camera.setView({
                  destination: new Rectangle(...model.data.bbox),
                });
              } else {
                viewer.camera.setView({
                  destination: Rectangle.fromDegrees(...model.data.bbox),
                });
              }
            }
          } else if (model.sourceType === 'City3DDB' && projectStore.city3DDB) {
            try {
              var citydbLayer = projectStore.city3DDB.getLayerbyId(projectStore.zoomToModel);
              citydbLayer.zoomToStartPosition();
              projectStore.setZoomToModel(false)
              return
            } catch (error) {
              return
            }
          } else if (model.data?.isI3sModel) {
            let modelView = projectStore.i3sViews.find(x => x?.key === `${projectStore.zoomToModel}-tile`)
            if (modelView) {
              const center = Rectangle.center(modelView.extent);
              center.height = 5000;
              viewer.camera.setView({
                destination: Ellipsoid.WGS84.cartographicToCartesian(center),
              });
              projectStore.setZoomToModel(false)
            }
            return
          } else if (cm.tile) {
            try {
              viewer.flyTo(cm.tile, cm?.tile?.boundingSphere?.radius ? {
                duration: 0.2,
                offset: new HeadingPitchRange(0, CesiumMath.toRadians(-45), cm.tile.boundingSphere.radius * 8.0)
              } : {
                duration: 0.2,
              });
            } catch (error) {
              console.log('error', error)
              alert(t('model-is-not-ready'))
            }
          }
          projectStore.setZoomToModel(false)
        } catch (error) {
          console.log('error', error)
          alert(t('data-is-not-ready'))
        }
      }
    }
  }, [projectStore.zoomToModel, projectStore.i3sViews])

  useEffect(() => {

    if (!projectStore.showEditLocation) {
      setMoveTile(false)
      if (tempDisableRequestRender) {
        setTempDisableRequestRender(false)
        uiStore.setRequestRender(true)
      }
      return
    }
    var handler = new ScreenSpaceEventHandler(
      viewerRef.current.cesiumElement.canvas
    )

    // console.log('projectStore.modelList', toJS(projectStore.modelList))
    let cm = getCurrentModel(
      projectStore.currentModelId,
      toJS(projectStore.modelList),
      projectStore.tileViews
    )
    if (cm) {
      if (typeof cm?.tile?._root === 'undefined' && !['kmz', 'geotiff', 'geojson'].includes(cm.model.type)) {
        // notification.open({
        //   message: t('an-error-occurred-when-edit-data'),
        //   description: t('something-went-wrong-when-edit-data'),
        //   icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        // })
        // projectStore.setShowEditLocation(false, false)
        // projectStore.setDisplayPanel(true)
        // return
      }
      if (uiStore.requestRender) {
        setTempDisableRequestRender(true)
        uiStore.setRequestRender(false)
      }
      // if (cm.tile.root.boundingSphere) {
      //   // viewerRef.current.cesiumElement.zoomTo(cm.tile)
      //   if (cm.model.sourceType === 'external')
      //     viewerRef.current.cesiumElement.zoomTo(cm.tile)
      //   else {
      //     var hpr = hpr4ZoomTo(viewerRef.current.cesiumElement, cm.tile, true)
      //     viewerRef.current.cesiumElement.zoomTo(cm.tile, hpr)
      //   }
      // }
      if (cm?.tile?._root) {
        setMoveTile(
          <MoveTile
            viewer={viewerRef}
            handler={handler}
            tile={cm.tile}
            model={cm.model}
          />
        )
      }
    }
  }, [projectStore.showEditLocation, projectStore.modelList.length])


  useEffect(() => {
    if (!uiStore.showObjectInfo) {
      setObjectInfo();
      return
    }

    var handler = new ScreenSpaceEventHandler(
      viewerRef.current.cesiumElement.canvas
    )
    let cm = getCurrentModel(
      projectStore.currentModelId,
      toJS(projectStore.modelList),
      projectStore.tileViews
    )
    if (cm) {
      if (cm.tile?.root?.boundingSphere) {
        // viewerRef.current.cesiumElement.zoomTo(cm.tile)
        if (cm.model.sourceType === 'external')
          viewerRef.current.cesiumElement.zoomTo(cm.tile)
        else {
          var hpr = hpr4ZoomTo(viewerRef.current.cesiumElement, cm.tile, true)
          viewerRef.current.cesiumElement.zoomTo(cm.tile, hpr)
        }
      }
      setObjectInfo({
        handler,
        tile: cm.tile,
        model: cm.model
      })
    }
    // return () => {

    // }
  }, [uiStore.showObjectInfo, projectStore.modelList.length])

  useEffect(() => {
    if (projectStore.deleteIds && projectStore.deleteIds.length > 0) {
      projectStore.deleteIds.map((item) => {
        let fkey = item + '-tile'
        let akey = item + '-alignment'
        projectStore.setAlignment(projectStore.Alignment.filter(t => {
          return t && t.key !== akey
        }))
        projectStore.setTileViews(projectStore.tileViews.filter(t => t.key !== fkey))
        projectStore.setModelList(projectStore.modelList.filter(t => {
          return t && t._id !== item
        }))
      })
      projectStore.setDeleteIds([])
    }
  }, [projectStore.deleteIds])

  useEffect(() => {
    if (projectStore.closeEditModel) {
      let cm = getCurrentModel(
        projectStore.currentModelId,
        projectStore.modelList,
        projectStore.tileViews
      )
      if (!cm) return
      updatePlaneMatix(cm.model, projectStore.projectDetail, cm.tile, false, true)

      projectStore.setCloseEditModel(false)
    }
  }, [projectStore.closeEditModel])

  useEffect(() => {
    if (projectStore.gpsMode !== 'none') {

      setGpsView(
        <Gps
          viewer={viewerRef}
          webcamRef={webcamRef}
          projectid={props.match.params.projectId}
        />
      )
      if (projectStore.gpsMode === 'free') {
        try {
          commonStore.setCameraPossition(new Cartesian3(
            viewerRef.current.cesiumElement.camera._positionWC.x,
            viewerRef.current.cesiumElement.camera._positionWC.y,
            viewerRef.current.cesiumElement.camera._positionWC.z));
        } catch (error) {

        }

      }
    }
  }, [projectStore.gpsMode])

  // Retore user’s view in normal 3D mode before click AR or GPRS
  useEffect(() => {
    if (projectStore.restoreUserView3Dmode) {
      var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-public`));
      if (usersStore.currentUser && usersStore.currentUser._id) {
        retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
        if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && retrievedObject.userId === usersStore.currentUser._id) {
          setxDCamera(viewerRef.current.cesiumElement, retrievedObject.flyOption)
        }
      } else {
        if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && retrievedObject.userId === 'public') {
          setxDCamera(viewerRef.current.cesiumElement, retrievedObject.flyOption)
        }
      }
      projectStore.setRestoreUserView3Dmode(false)
    }
  }, [projectStore.restoreUserView3Dmode])


  useEffect(() => {
    if (projectStore.clippingMode) {
      if (!projectStore.showHiddenDrawer || !projectStore.showHiddenAreaTab) {
        if (!polylineCutToolStore.drawMode)
          polylineCutToolStore.setHideArea(false)
      }
      if (projectStore.clippingMode === 'vertical') {
        setClippingView(
          <ClippingPlaneVertical viewer={viewerRef} />
        )
      } else if (projectStore.clippingMode === 'horizontal') {
        setClippingView(
          <ClippingPlaneHorizontal viewer={viewerRef} />
        )
      } else if (projectStore.clippingMode === 'alignment') {
        setClippingView(
          <ClippingPlaneAlignment viewer={viewerRef} />
        )
      }
    } else {
      projectStore.setClippingViewPoint(false)
      setClippingView(false)
    }
  }, [projectStore.clippingMode, projectStore.showHiddenDrawer])

  /** Effect for beforeVisibleTileset */
  useEffect(() => {
    if (schedulingStore.timeSliderVisible) {
      let visibleTilesetsArr = []
      if (Array.isArray(projectStore.visibleTilesets)) {
        projectStore.visibleTilesets.map(item => {
          visibleTilesetsArr.push(toJS(item))
        })
      }
      setBeforeVisibleTileset(visibleTilesetsArr)

      let visibleSketchArr = []
      if (sketchingStore.visibleSketches) {
        sketchingStore.visibleSketches.map(item => {
          visibleSketchArr.push(toJS(item))
        })
      }
      setBeforeVisibleSketch(visibleSketchArr)
    }

    if (!schedulingStore.timeSliderVisible) {
      const visibleData = beforeVisibleTileset?.map(item => {
        return item
      })
      projectStore.setVisibleTilesets(visibleData)

      const visibleSketchData = beforeVisibleSketch?.map(item => {
        return item
      })
      sketchingStore.setVisibleSketches(visibleSketchData)
    }
  }, [schedulingStore.timeSliderVisible])

  /** Effect for 4D slider */
  useEffect(() => {
    if (schedulingStore.timeSliderVisible && schedulingStore.currentViewingTime && schedulingStore.currentViewingTime.toDate && viewerRef.current.cesiumElement) {
      // display tiles (4D tool should turn on / off only objects that have either start or end date or both)      
      let _4dModels = getVisibleModel4D(projectStore.modelList, 'modelId')
      // let arr_4dmodel = _4dModels.visibleModels.map(modelId => modelId + '-tile')
      // let arr_4dmodel_nullDate = _4dModels.modelNullStartAndEnDate.map(modelId => modelId + '-tile')
      let arr_visibleTilesets = [..._4dModels.visibleModels]
      let arr_visibleTilesets_modelNullStartAndEnDate = [..._4dModels.modelNullStartAndEnDate]

      const showData = projectStore.visibleTilesets.map(item => {
        let isExist = arr_visibleTilesets.find(visible => item.modelId === visible)
        let alwaysExist = arr_visibleTilesets_modelNullStartAndEnDate.find(modelNull => item.modelId === modelNull)
        if (alwaysExist) {
        }
        else {
          if (item.isVisible4D) {
            if (isExist) {
              item.isVisible = true
            } else {
              item.isVisible = false
            }
          }
        }
        return item
      })
      if (!capturesStore.isDrawerCaptureVisible) {
        projectStore.setVisibleTilesets(showData)
      }

      // 4D for model link
      let _4dLinkModels = getVisibleModel4D(projectStore.listAllModel3DSLink);
      const getHiddenIDs = (allModels = [], modelNullStartAndEnDate = [], visibleModels = []) => {
        const filteredTileLinkViews = allModels.filter(view =>
          !modelNullStartAndEnDate.includes(view.id) && !visibleModels.includes(view.id)
        );
        const filteredIds = filteredTileLinkViews.map(view => view.id);
        return filteredIds;
      }
      let _hiddenModelLinkIDs = getHiddenIDs(projectStore.listAllModel3DSLink, _4dLinkModels?.modelNullStartAndEnDate, _4dLinkModels?.visibleModels)
      projectStore.setHiddenModelLinkIDs(_hiddenModelLinkIDs)


      // 4D for topic
      if (topicLocationView && topicLocationView.props.viewer.entities && topicLocationView.props.viewer.entities.values.length > 0) {
        let _topic4D = getTopic4D(schedulingStore, topicStore.arrTopicLocation)
        topicLocationView.props.viewer.entities.values.forEach(topicview => {
          if (topicview.type && topicview.type === 'topic' && _topic4D.length > 0) {
            topicview.show = _topic4D.indexOf(topicview.id) > -1
          }
        })
      }
      // 4D Sketch
      let _modelsSketch = getVisibleModel4D(sketchingStore.arrSketches, 'sketchId')
      let arr_4dmodelSketch = [..._modelsSketch.visibleModels]
      let arr_4dmodelSketch_nullDate = [..._modelsSketch.modelNullStartAndEnDate]
      const checkvisibleSketch = function (item) {
        let isExist = arr_4dmodelSketch.find(visible => item.sketchId === visible)
        let alwaysExist = arr_4dmodelSketch_nullDate.find(modelNull => item.sketchId === modelNull)
        if (alwaysExist) {
        }
        else {
          if (item.isVisible4D) {
            if (isExist) {
              item.isVisible = true
            } else {
              item.isVisible = false
            }
          }
        }
        return item
        //if(alwaysExist)
      }
      if (sketchingStore.visibleSketches.length > 0) {
        let _visibleSketch = sketchingStore.visibleSketches.map(item => checkvisibleSketch(item))
        sketchingStore.setVisibleSketches(_visibleSketch)
      } else {
        let _visibleSketch = sketchingStore.arrSketches.map(item => checkvisibleSketch(item))
        sketchingStore.setVisibleSketches(_visibleSketch)
      }

      if (sketchView && sketchView.props.viewer.current.cesiumElement.entities && sketchView.props.viewer.current.cesiumElement.entities.values.length > 0) {
        sketchView.props.viewer.current.cesiumElement.entities.values.forEach(_sketchview => {
          if (_sketchview.type && _sketchview.type === 'sketch') {
            if (arr_4dmodelSketch_nullDate.includes(_sketchview.id)) { // case model not start date or end date then not change
              _sketchview.show = _sketchview.show
            } else {
              _sketchview.show = arr_4dmodelSketch.indexOf(_sketchview.id) > -1
            }
          }
        })
      }
      viewerRef.current.cesiumElement.clockViewModel.clock.currentTime = JulianDate.fromDate(schedulingStore.currentViewingTime.toDate());
      viewerRef.current.cesiumElement.clockViewModel.clock.startTime = JulianDate.fromDate(schedulingStore.beforeTime.toDate());
      viewerRef.current.cesiumElement.clockViewModel.clock.stopTime = JulianDate.fromDate(schedulingStore.afterTime.toDate());
      viewerRef.current.cesiumElement.clockViewModel.synchronize();
      startReRender()
    } else {
      projectStore.setHiddenModelLinkIDs([])
    }
  }, [schedulingStore.currentViewingTime, schedulingStore.beforeTime, schedulingStore.afterTime, schedulingStore.timeSliderVisible, schedulingStore.target4DIcon, projectStore.listProjectLink])


  useEffect(() => {
    if (schedulingStore.timeSliderVisible && viewerRef.current.cesiumElement) {
      //set orginal model view visible before active 4D
      projectStore.tileViews.forEach(tileView => {
        const tileElement = tileView?.ref?.current?.cesiumElement
        let x = {
          type: 'model',
          tileKey: tileView.key,
          show: tileElement?.show
        }
        setOrginalTileViews(orginalTileViews => [...orginalTileViews, x])
      })

      //set orginal topic view visible before active 4D
      if (topicLocationView && topicLocationView.props.viewer.entities && topicLocationView.props.viewer.entities.values.length > 0) {
        topicLocationView.props.viewer.entities.values.forEach(topicview => {
          if (topicview?.type === 'topic') {
            let x = {
              type: 'topic',
              tileKey: topicview.id,
              show: topicview.show
            }
            setOrginalTileViews(orginalTileViews => [...orginalTileViews, x])
          }
        })
      }

      viewerRef.current.cesiumElement.clockViewModel.clock.currentTime = JulianDate.fromDate(schedulingStore.currentViewingTime.toDate());
      viewerRef.current.cesiumElement.clockViewModel.synchronize();
    }
  }, [schedulingStore.timeSliderVisible])

  //Effect viewpoint box shadow model
  useEffect(() => {
    if (projectStore.currentViewpoint?.cameraData?.timeSlider && viewerRef.current.cesiumElement) {
      let time = moment(projectStore.currentViewpoint.cameraData.timeSlider)
      viewerRef.current.cesiumElement.clockViewModel.clock.currentTime = JulianDate.fromDate(time.toDate());
      viewerRef.current.cesiumElement.clockViewModel.synchronize();
    }
  }, [projectStore.currentViewpoint])

  useEffect(() => {
    if (schedulingStore.currentViewingTime && schedulingStore.currentViewingTime.toDate && viewerRef.current.cesiumElement) {
      viewerRef.current.cesiumElement.clockViewModel.clock.currentTime = JulianDate.fromDate(schedulingStore.currentViewingTime.toDate());
      viewerRef.current.cesiumElement.clockViewModel.synchronize();
    }
  }, [schedulingStore.currentViewingTime])

  //Effect reset tile visible before active 4D
  useEffect(() => {
    if (schedulingStore.isResetTileBeforeActive4D) {
      //reset model view     
      projectStore.tileViews.forEach(tileView => {
        const tileElement = tileView?.ref?.current?.cesiumElement
        let _orgtile = orginalTileViews.find(x => x.tileKey === tileView.key && x.type === 'model')
        if (_orgtile && tileElement) {
          tileElement.show = _orgtile.show
        }
      })

      //reset topic view
      if (topicLocationView && topicLocationView.props.viewer.entities && topicLocationView.props.viewer.entities.values.length > 0) {
        topicLocationView.props.viewer.entities.values.forEach(_topicview => {
          if (_topicview.type === 'topic') {
            let _orgtile = orginalTileViews.find(x => x.tileKey === _topicview.id && x.type === 'topic')
            if (_orgtile) {
              _topicview.show = _orgtile.show
            }
          }
        })
      }

      //reset sketch view           
      if (sketchView && sketchView.props.viewer.current.cesiumElement.entities && sketchView.props.viewer.current.cesiumElement.entities.values.length > 0) {
        sketchView.props.viewer.current.cesiumElement.entities.values.forEach(_sketchview => {
          if (_sketchview.type === 'sketch') {
            let _orgtile = sketchingStore.visibleSketches.find(x => x.sketchId === _sketchview.id)
            if (_orgtile) {
              _sketchview.show = _orgtile.isVisible
            }
          }
        })
      }

      setOrginalTileViews([])
      schedulingStore.setResetTileBeforeActive4D(false)
      startReRender()
    }
  }, [schedulingStore.isResetTileBeforeActive4D])

  const syncModelVisibleState = async () => {
    const checkWMSDrapeToModel = (modelList) => {
      let isExist = modelList.some(model => (model.sourceType === 'WMS' || model.type === 'geotiff') && model?.data?.classificationType === "CESIUM_3D_TILE");
      if (isExist) {
        projectStore.setRerenderWMS(!projectStore.rerenderWMS)
      }
    }
    if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
      //show hide model terrain
      var terrainProvider = new EllipsoidTerrainProvider({});
      var selectedTerrainId = ''
      for (let item of projectStore.visibleTilesets) {
        if (item.type === 'terrain' && item.isVisible && item.data && item.data.ionAssetId) {
          try {
            terrainProvider = await CesiumTerrainProvider.fromIonAssetId(item.data.ionAssetId, { accessToken: item.data.cesiumToken })
            selectedTerrainId = item.modelId
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (selectedTerrainId && selectedTerrain !== selectedTerrainId && viewerRef.current?.cesiumElement) {
        setSelectedTerrain(selectedTerrainId)
        viewerRef.current.cesiumElement.scene.terrainProvider = terrainProvider
      }
      if (!selectedTerrainId) {
        if (selectedTerrain) {
          setSelectedTerrain('')
          if (!(viewerRef.current.cesiumElement?.scene.terrainProvider instanceof Cesium.EllipsoidTerrainProvider))
            viewerRef.current.cesiumElement.scene.terrainProvider = new EllipsoidTerrainProvider()
        }
      }
      const visibleTileViews = projectStore.visibleTilesets.map(item => {
        if (item.isVisible && !(userGroupStore.nodeNoAccessRights.some(c => c.modelId === item.modelId))) {
          return { modelId: item.modelId + '-tile', isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceTypem, isTempHidden: item.isTempHidden, isVisibleClip: true, }
        }
      })
      projectStore.tileViews.forEach(tileView => {
        const tileElement = tileView.ref.current?.cesiumElement
        // tileElement.show = visibleTileViewIds.indexOf(tileView.key) > -1
        if (tileElement) {

          const model = visibleTileViews.find(t => {
            return t && t.modelId === tileView.key
          })
          const modelData = projectStore.modelList.find(t => {
            return t && t._id + '-tile' === tileView.key
          })
          const hideFeatures = projectStore.findHideFeaturesByUrl(tileElement?.resource?.url);
          if (model && modelData) {
            const isIdInList = (id, list) => {
              return list.some(obj => obj.modelId === (id + '-tile'));
            }
            const isShowUserGroup = isIdInList(model._id, userGroupStore.nodeNoAccessRights);
            if (model?.data?.Alignment || modelData.crs?.Alignment || modelData?.data?.Alignment) {
              const Alignment = projectStore.Alignment.find(t => {
                const key = (modelData.id || modelData._id) + '-alignment';
                return t && t.key === key;
              })

              if (Alignment && Alignment?.ref?.current?.cesiumElement) {
                if (isShowUserGroup) {
                  Alignment.ref.current.cesiumElement.show = false
                  const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
                  entitiesCZML.forEach(e => {
                    e.show = false
                  })
                  Alignment.show = false
                } else {
                  Alignment.ref.current.cesiumElement.show = model.isVisible
                  const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
                  entitiesCZML.forEach(e => {
                    e.show = hideFeatures.indexOf(e.id) === -1 && hideFeatures.indexOf(e.parentId) === -1
                  })
                  Alignment.show = model.isVisible
                }
              }
            }

            setTileConditions(projectGanttStore.isShowGanttPanel, tileElement, model.type, projectStore.gpsMode, (isShowUserGroup ? false : model.isVisible), modelData.style, hideFeatures, projectStore.listObjectHide, projectStore.listObjectColor, modelData._id, modelData?.data, projectGanttStore.highlightSavedQueryObjectStyles, projectGanttStore.showedModels, projectGanttStore.selectObjectModel.open)
          } else {
            if (model?.data?.Alignment || modelData?.crs?.Alignment || modelData?.data?.Alignment) {
              const Alignment = projectStore.Alignment?.find(t => {
                const key = (modelData.id || modelData._id) + '-alignment';
                return t && t.key === key;
              })

              if (Alignment && Alignment?.ref?.current?.cesiumElement) {
                Alignment.show = false
                Alignment.ref.current.cesiumElement.show = false
              }
            }
            setTileConditions(projectGanttStore.isShowGanttPanel, tileElement, undefined, projectStore.gpsMode, false, undefined, hideFeatures, projectStore.listObjectHide, projectStore.listObjectColor, undefined, modelData?.data, projectGanttStore.highlightSavedQueryObjectStyles, projectGanttStore.showedModels,projectGanttStore.selectObjectModel.open)
          }
        }
      })
      checkWMSDrapeToModel(projectStore.modelList)
    } else {
      projectStore.tileViews.forEach(tileView => {
        const tileElement = tileView.ref.current?.cesiumElement
        // tileElement.show = visibleTileViewIds.indexOf(tileView.key) > -1
        if (tileElement) {

          const modelData = projectStore.modelList.find(t => {
            return t && t._id + '-tile' === tileView.key
          })
          const hideFeatures = projectStore.findHideFeaturesByUrl(tileElement?.resource?.url);
          if (modelData) {
            if (modelData.crs?.Alignment || modelData?.data?.Alignment) {
              const Alignment = projectStore.Alignment.find(t => {
                const key = (modelData.id || modelData._id) + '-alignment';
                return t && t.key === key;
              })

              if (Alignment && Alignment?.ref?.current?.cesiumElement) {
                Alignment.ref.current.cesiumElement.show = true
                const entitiesCZML = Alignment.ref.current.cesiumElement.entities.values
                entitiesCZML.forEach(e => {
                  e.show = hideFeatures.indexOf(e.id) === -1 && hideFeatures.indexOf(e.parentId) === -1
                })
                Alignment.show = true
              }
            }
            setTileConditions(projectGanttStore.isShowGanttPanel, tileElement, modelData.type, projectStore.gpsMode, true, modelData.style, hideFeatures, projectStore.listObjectHide, projectStore.listObjectColor, modelData._id, modelData?.data, projectGanttStore.highlightSavedQueryObjectStyles, projectGanttStore.showedModels, projectGanttStore.selectObjectModel.open)
          } else {
            if (modelData?.crs?.Alignment || modelData?.data?.Alignment) {
              const Alignment = projectStore.Alignment?.find(t => {
                const key = (modelData.id || modelData._id) + '-alignment';
                return t && t.key === key;
              })

              if (Alignment && Alignment?.ref?.current?.cesiumElement) {
                Alignment.show = false
                Alignment.ref.current.cesiumElement.show = false
              }
            }
            setTileConditions(projectGanttStore.isShowGanttPanel, tileElement, undefined, projectStore.gpsMode, false, undefined, hideFeatures, projectStore.listObjectHide, projectStore.listObjectColor, undefined, modelData?.data, projectGanttStore.highlightSavedQueryObjectStyles, projectGanttStore.showedModels, projectGanttStore.selectObjectModel.open)
          }
        }
      })
      checkWMSDrapeToModel(projectStore.modelList)
    }
  }
  useEffect(() => {
    syncModelVisibleState()
    startReRender()
  }, [projectStore.visibleTilesets, projectStore.hideFeatures, projectStore.changeModelStyle, projectStore.listObjectHide, projectStore.listObjectColor, projectGanttStore.isShowGanttPanel, userGroupStore.nodeNoAccessRights, projectGanttStore.highlightSavedQueryObjectStyles, projectGanttStore.showedModels])

  const defaultContextOption = useMemo(() => {
    const context = {
      webgl: {
        alpha: false
      },
    }
    return context
  }, [])

  const defaultImagery = useMemo(() => {
    var imageryProviders = createDefaultImageryProviderViewModels();
    var imageryViewModels = [];
    imageryProviders.forEach(element => {
      if (element.category === "Cesium ion") {
        if (element.name === 'Bing Maps Aerial') {
          imageryViewModels.push(new ProviderViewModel({
            name: 'Bing Maps Aerial',
            iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/bingAerial.png'),
            tooltip: t('bing-maps-aerial-imagery-provided-by-cesium-ion'),
            category: "Cesium ion",
            show: projectStore.showMap,
            creationFunction: async function () {
              return await BingMapsImageryProvider.fromUrl('//dev.virtualearth.net', {
                key: bingMapKey,
                mapStyle: BingMapsStyle.AERIAL
              });
            }
          }));
        } else if (element.name === 'Bing Maps Aerial with Labels') {
          imageryViewModels.push(new ProviderViewModel({
            name: 'Bing Maps Aerial with Labels',
            iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/bingAerialLabels.png'),
            tooltip: t('bing-maps-aerial-imagery-with-labels-provided-by-cesium-ion'),
            category: 'Cesium ion',
            show: projectStore.showMap,
            creationFunction: async function () {
              return await BingMapsImageryProvider.fromUrl('//dev.virtualearth.net', {
                key: bingMapKey,
                mapStyle: BingMapsStyle.AERIAL_WITH_LABELS
              });
            }
          }));
        } else if (element.name === 'Bing Maps Roads') {
          imageryViewModels.push(new ProviderViewModel({
            name: 'Bing Maps Roads',
            iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/bingRoads.png'),
            tooltip: t('bing-maps-standard-road-maps-provided-by-cesium-ion'),
            category: 'Cesium ion',
            show: projectStore.showMap,
            creationFunction: async function () {
              return await BingMapsImageryProvider.fromUrl('//dev.virtualearth.net', {
                key: bingMapKey,
                mapStyle: BingMapsStyle.ROAD
              });
            }
          }));
        } else {
          imageryViewModels.push(element)
        }
      } else {
        imageryViewModels.push(element)
      }
    });

    return imageryViewModels
  }, [])


  useEffect(() => {
    if (sketchingStore.drawMode) {
      setMoveTile(false)
      const viewer = viewerRef.current.cesiumElement
      var tile = findTile(projectStore.currentModelId, projectStore.tileViews)
      if (viewer) {
        const handler = new ScreenSpaceEventHandler(viewer.canvas)
        setDrawingSketch(
          <DrawSketch viewer={viewerRef} handler={handler} tile={tile} />
        )
      }
    } else {
      setDrawingSketch(false)
    }
  }, [sketchingStore.drawMode])

  useEffect(() => {
    if (polylineCutToolStore.drawMode) {
      setMoveTile(false)
      const viewer = viewerRef.current.cesiumElement
      var tile = findTile(projectStore.currentModelId, projectStore.tileViews)
      if (viewer) {
        const handler = new ScreenSpaceEventHandler(viewer.canvas)
        setDrawingPolylineCut(
          <DrawHiddenArea viewer={viewer} handler={handler} tile={tile} />
        )
      }
    } else {
      setDrawingPolylineCut(false)
    }
  }, [polylineCutToolStore.drawMode])

  useEffect(() => {
    if (projectStore.showFov) {
      if (viewerRef.current.cesiumElement) {
        setSettingView(
          <SettingControl viewer={viewerRef} />
        )
        projectStore.setCleanMode(true)
      }
    } else {
      setSettingView(false)
    }
  }, [projectStore.showFov])


  useEffect(() => {
    if (uiStore.showIFCSetting) {
      setIFCSettingModal(<IFCSetting />)
    } else {
      setIFCSettingModal(false)
    }
  }, [uiStore.showIFCSetting])

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail)) return
    if (uiStore.showUndergroundSetting) {
      setUndergroundSettingModal(<UndergroundSetting viewer={viewerRef} />)
    } else {
      setUndergroundSettingModal(false)
    }
  }, [uiStore.showUndergroundSetting])

  useEffect(() => {
    if (uiStore.showTransparentSetting) {
      setTransparentSettingModal(<TransparentSetting viewer={viewerRef} />)
    } else {
      setTransparentSettingModal(false)
    }
  }, [uiStore.showTransparentSetting, commonStore.farDistance])

  useEffect(() => {
    // effect show fog setting
    if (uiStore.showFogSetting) {
      setFogSettingModal(<FogSetting viewer={viewerRef} />)
    } else {
      setFogSettingModal(false)
    }
  }, [uiStore.showFogSetting])

  useEffect(() => {
    // effect show Light setting
    if (uiStore.showLightSetting) {
      setLightSettingModal(<LightSetting viewer={viewerRef} />)
    } else {
      setLightSettingModal(false)
    }
  }, [uiStore.showLightSetting])

  useEffect(() => {
    // effect show importer setting
    if (uiStore.showImporterSetting) {
      setImporterSettingModal(<ImporterSetting viewer={viewerRef} />)
    } else {
      setImporterSettingModal(false)
    }
  }, [uiStore.showImporterSetting])



  useEffect(() => {
    if (projectSettingStore.systemProjectSetting.fogSetting && projectSettingStore.systemProjectSetting.fogSetting.enabled) {
      setFogView(
        <PostProcessStage
          fragmentShader={Utils.fragmentShaderSource()}
          uniforms={{ fogByDistance: new Cartesian4.fromArray(projectSettingStore.systemProjectSetting.fogSetting.distance || [100.0, 0.0, 20000.0, 1.0]), fogColor: new Color.fromCssColorString(projectSettingStore.systemProjectSetting.fogSetting.color).withAlpha(projectSettingStore.systemProjectSetting.fogSetting.alpha) }}
        />
      )
    } else {
      setFogView(false)
    }

    if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement.scene) {
      let _cameraLocation = getCurrentCamera(viewerRef)
      if (_cameraLocation.position) {
        let flyOption = {}
        flyOption.orientation = {
          direction: _cameraLocation.direction,
          up: _cameraLocation.up,
        }

        let destination = new Cartesian3(
          _cameraLocation.position.x + 0.00001, // 0.00001 for effect refresh viewer
          _cameraLocation.position.y,
          _cameraLocation.position.z
        )
        flyOption.destination = destination
        viewerRef.current.cesiumElement.camera.setView(flyOption)
        uiStore.setRefreshViewer(false)
      }
    }

  }, [projectSettingStore.systemProjectSetting.fogSetting, uiStore.refreshViewer])

  useEffect(() => {
    if (projectStore.showBackgroundSetting) {
      setBackgroundColorSettingModal(<BackgroundColorSetting viewer={viewerRef} />)
    } else {
      setBackgroundColorSettingModal(false)
    }
  }, [projectStore.showBackgroundSetting])

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail)) return
    if (uiStore.showAmbientOccSetting) {
      setAmbientOccSettingModal(<AmbientOccSetting viewer={viewerRef} />)
    } else {
      setAmbientOccSettingModal(false)
    }

    setAmbientSetting(projectStore.ambientOccSetting)
    startReRender()
  }, [projectStore.ambientOccSetting, uiStore.showAmbientOccSetting])

  useEffect(() => {
    if (projectStore.renderResolutionControl) {
      if (viewerRef.current.cesiumElement) {
        setSettingRenderResolutionView(
          <SettingRenderResolutionControl viewer={viewerRef} />
        )
        projectStore.setCleanMode(true)
      }
    } else {
      setSettingRenderResolutionView(false)
    }
  }, [projectStore.renderResolutionControl])

  useEffect(() => {
    if (projectStore.renderRecordDataControl) {
      if (viewerRef.current.cesiumElement) {
        setRenderRecordDataView(
          <RecordDataControl />
        )
        // projectStore.setCleanMode(true)
      }
    } else {
      setRenderRecordDataView(false)
      // projectStore.setCleanMode(false)
    }
  }, [projectStore.renderRecordDataControl])

  useEffect(() => {
    if (!isValidCanvas()) return
    var viewer = viewerRef.current.cesiumElement
    var centerScreen = getCenterScreenCoordinates(viewer)
    if (!centerScreen || !viewer.camera) return
    var transform = Transforms.eastNorthUpToFixedFrame(centerScreen.oldPos)
    if (projectStore.is2D) {
      var heading = viewer.camera.heading
      projectStore.setObtGPSOrientation({
        orientation: {
          heading: viewer.camera.heading,
          pitch: viewer.camera.pitch,
          roll: viewer.camera.roll,
        },
      })
      viewer.scene.camera.switchToOrthographicFrustum()
      var pitch = CesiumMath.toRadians(-89.999)
      // var pitch = CesiumMath.toRadians(-90)
      var range = commonStore.manualGpsState === 1 ? 55 : centerScreen.dis
      viewer.camera.lookAtTransform(
        transform,
        new HeadingPitchRange(heading, pitch, range)
      )
      viewer.camera.lookAtTransform(Matrix4.IDENTITY)
      viewer.scene.screenSpaceCameraController.enableTilt = false
    } else {
      viewer.scene.camera.switchToPerspectiveFrustum()
      var saveCamData = {
        heading: viewer.camera.heading,
        pitch: viewer.camera.pitch,
        roll: viewer.camera.roll,
      }
      if (!projectStore.obtGPSOrientation.orientation) return
      // viewer.camera.setView(projectStore.obtGPSOrientation);
      // projectStore.setObtGPSOrientation({});
      viewer.camera.lookAtTransform(
        transform,
        new HeadingPitchRange(
          // projectStore.obtGPSOrientation.orientation.heading,
          saveCamData.heading,
          projectStore.obtGPSOrientation.orientation.pitch,
          centerScreen.dis
        )
      )
      viewer.camera.lookAtTransform(Matrix4.IDENTITY)
      viewer.scene.screenSpaceCameraController.enableTilt = true
      projectStore.setObtGPSOrientation({
        orientation: saveCamData,
      })
    }
  }, [projectStore.is2D])

  useEffect(() => {
    if (!isEmpty(projectStore.projectDetailError)) {
      let _statuscode = projectStore.projectDetailError.data.statusCode
      const isLoggedIn = localStorage.getItem('jwt') || sessionStorage.getItem('jwt')
      if (_statuscode === 403 && isEmpty(isLoggedIn)) {
        history.push(`/auth/login`, {
          from: {
            pathname: location.pathname + location.search
          }
        });
      }
      if (_statuscode === 403 && !isEmpty(isLoggedIn)) {
        history.push(`/`);
      }
      if (_statuscode === 404 || _statuscode === 500) {
        history.push(`/`);
      }
      projectStore.setProjectDetailError({})
    }
  }, [projectStore.projectDetailError])

  // //Effect click on map close dropdown menu settings
  useEffect(() => {
    if (projectStore.dropDownMenuCameraSettings && viewerRef?.current?.cesiumElement?.canvas) {
      viewerRef.current.cesiumElement.canvas.addEventListener('click', function fcdrp(event) {
        projectStore.setDropDownMenuCameraSettings(false)
        viewerRef.current.cesiumElement.canvas.removeEventListener('click', fcdrp);
      })
    }
  }, [projectStore.dropDownMenuCameraSettings])

  //fly to camera view from external socket
  useEffect(() => {
    if (projectStore.currentCamera) {
      try {

        let destination = Cesium.Cartesian3.fromDegrees(
          projectStore.currentCamera.position.lng,
          projectStore.currentCamera.position.lat,
          projectStore.currentCamera.position.elevation);
        let orientation = {}
        if (projectStore.currentCamera.orientation && projectStore.currentCamera.orientation.heading && projectStore.currentCamera.orientation.pitch && projectStore.currentCamera.orientation.roll) {
          orientation = {
            heading: projectStore.currentCamera.orientation.heading,
            pitch: projectStore.currentCamera.orientation.pitch,
            roll: projectStore.currentCamera.orientation.roll
          }
        }
        if (projectStore.currentCamera.direction && projectStore.currentCamera.up) {
          orientation = {
            direction: new Cesium.Cartesian3(projectStore.currentCamera.direction.x, projectStore.currentCamera.direction.y, projectStore.currentCamera.direction.z),
            up: new Cesium.Cartesian3(projectStore.currentCamera.up.x, projectStore.currentCamera.up.y, projectStore.currentCamera.up.z),
          }
        }
        if (projectStore.currentCamera.fov && projectStore.currentCamera.fov?.fov) {
          viewerRef.current.cesiumElement.camera.frustum.fov = (projectStore.currentCamera.fov?.fov < 0 || projectStore.currentCamera.fov?.fov > CesiumMath.PI) ? CesiumMath.toRadians(projectStore.currentCamera.fov?.fov) : projectStore.currentCamera.fov?.fov
        }
        viewerRef.current.cesiumElement.camera.flyTo({
          duration: 1.5,
          destination,
          orientation: orientation,
        })
      } catch (error) {

      }
    }
  }, [projectStore.currentCamera])



  useEffect(() => {
    let scene = viewerRef?.current?.cesiumElement?.scene;
    let globe = scene?.globe
    if (projectSettingStore.systemProjectSetting.openCVData.fovValue && projectSettingStore.systemProjectSetting.openCVData.fovValue.length > 1) {
      console.log(CesiumMath.toRadians(projectSettingStore.systemProjectSetting.openCVData.fovValue[1]))
      //viewerRef.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(projectSettingStore.systemProjectSetting.openCVData.fovValue[1])
    }
    if (projectSettingStore.systemProjectSetting.openCVData?.extra?.fov) {
      // console.log(CesiumMath.toRadians(projectSettingStore.systemProjectSetting.openCVData.extra.fov))
      //viewerRef.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(projectSettingStore.systemProjectSetting.openCVData.extra.fov)
    }
    //scene.screenSpaceCameraController.enableCollisionDetection = false;
    commonStore.setNearValue(projectSettingStore.systemProjectSetting?.nearValue)
    commonStore.setNearDistance(projectSettingStore.systemProjectSetting?.nearDistance || 0)
    commonStore.setFarDistance(projectSettingStore.systemProjectSetting?.farDistance)

    if (commonStore.nearValue && commonStore.farDistance) {
      const setTranslucencyEnabled = function () {
        if (commonStore.farDistance && commonStore.nearValue) {
          globe.translucency.enabled = true
          globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
            commonStore.nearDistance || 0,
            1 - commonStore.nearValue,
            commonStore.farDistance,
            1.0
          );
          if (scene.requestRenderMode) { scene.requestRender(); }
        }
        else {
          globe.translucency.enabled = false
          if (scene.requestRenderMode) { scene.requestRender(); }
        }
      }
      setTranslucencyEnabled()
    }
  }, [projectSettingStore.systemProjectSetting])

  const hanlde4DTimeSlider = (timeData) => {
    let time = moment(timeData)
    schedulingStore.setCurrentViewingTime(time)
    schedulingStore.setOriginViewingTime(time)
    schedulingStore.setBeforeTime(time)
    schedulingStore.setAfterTime(time)
  }

  const hanldeVisibleDataTree = (data) => {
    if (data) {
      //model
      const modelList = projectStore.modelList.length ? toJS(projectStore.modelList) : toJS(projectStore.projectDetail.model3DS)
      let tempModel = toJS(modelList).map(item => {
        const isBefore = item?.cloneData?.isBefore || checkOldViewPoint(item?.updatedAt || item?.createdAt);
        return { modelId: item._id
          //, isVisible: isBefore ? true : false
          ,isVisible: true
          , isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, data: item.data, isVisible4D: true, endDate: item.endDate, startDate: item.startDate, isVisibleClip: true }
      })
      if (data.modelHiden?.length > 0 || data.visible4DModels?.length > 0 || data.visibleClipModels?.length > 0) {
        const hiddenData = data.modelHiden || []
        const visible4DModels = data.visible4DModels || []
        const visibleClipModels = data.visibleClipModels || []
        const _visibletileset = tempModel.map(function (newData) {
          let isExist = hiddenData.find(c => c.modelId === newData.modelId);
          let isExist4D = visible4DModels.find(c => c.type4D === 'model' && c.modelId === newData.modelId);
          let isExistClip = visibleClipModels.find(c => c.modelId === newData.modelId);
          if (isExist) {
            // newData.isVisible = isExist.isVisible
            newData.isVisible = true
            newData.isTempHidden = isExist.isTempHidden
          }else{
            newData.isVisible = false
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          if (isExistClip) {
            newData.isVisibleClip = false
          }
          return newData
        });
        projectStore.setVisibleTilesets(_visibletileset)
      } else {
        projectStore.setVisibleTilesets(tempModel)
      }
      //sketch
      let tempSketch = toJS(sketchingStore.arrSketches).map(item => {
        const isBefore = item?.cloneData?.isBefore || checkOldViewPoint(item?.updatedAt || item?.createdAt);
        return { sketchId: item._id
          , isVisible: isBefore ? true : false
          , isVisible4D: true, endDate: item.endDate, startDate: item.startDate }
      })
      if ((data.visibleSketches && data.visibleSketches.length > 0) || (data.visible4DModels && data.visible4DModels.length > 0)) {
        let _visibleSketches = data.visibleSketches   //  Được hiển thị trong datatree
        let hiddenData4D = data.visible4DModels || []
        const _visiblesketch = tempSketch.map(function (newData) {
          let isExist = _visibleSketches.find(c => c.sketchId === newData.sketchId)
          let isExist4D = hiddenData4D.find(c => c.type4D === 'sketch' && c.sketchId === newData.sketchId)
          if (isExist) {
            newData.isVisible = isExist.isVisible
            newData.isTempHidden = isExist.isTempHidden
          } else { 
            newData.isVisible = false;
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          return newData
        });
        sketchingStore.setVisibleSketches(_visiblesketch)
      } else if ((data.visibleSketches && data.visibleSketches.length === 0) && (data.visible4DModels && data.visible4DModels.length === 0)) {
        sketchingStore.setVisibleSketches(tempSketch)
      }

      //topics
      let arrType = []
      let topicData = topicStore.topicList
      if (topicData.length) {
        topicData.map(topic => {
          let find = arrType.find(c => c.topictype === topic.topictype)
          if (!find) {
            arrType.push(toJS(topic))
          }
        })
        let topicNoType = topicData.find(c => !c.topictype)
        if (topicNoType) {
          arrType.push({ topictype: 'No type' })
        }
        let tempTopic = arrType.filter(topic => topic.topictype).map(item => {
          const isBefore = checkOldViewPoint(item?.updatedAt || item?.createdAt);
          return { type: 'topic'
            //, isShow: isBefore ? true : false
            , isShow: true
            , isLeaf: true, title: item.topictype, controlName: item.topictype }
        })
        if (data.visibleTopics && data.visibleTopics.length > 0) {
          let hiddenData = data.visibleTopics
          const _visiblestopic = tempTopic.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            }else{
              newData.isShow = false
            }
            return newData
          });
          topicStore.setVisibleTopic(_visiblestopic)
        } else {
          topicStore.setVisibleTopic(tempTopic)
        }
      }

      //feedback
      let tempFeedback = []
      if (feedbackStore.feedbackVisualization && feedbackStore.feedbackVisualization.length > 0) {
        let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
        let arr2 = [...new Set(layerGenericFeedback.flat(1))];

        const feedbackForms = {}
        for (let i = 0; i < arr2.length; i++) {
          const item = arr2[i];
          if (!feedbackForms[item.name]) {
            feedbackForms[item.name] = []
          }
          feedbackForms[item.name].push(item)
        }

        for (const key in feedbackForms) {
          const GenericFeedbacks = feedbackForms[key];
          const el = GenericFeedbacks[0]
          const isBefore = checkOldViewPoint(el?.updatedAt || el?.createdAt);
          let _subchild = {
            title: el.title,
            isShow: isBefore ? true : false,
            isLeaf: true,
            key: el.name,
            type: 'feedback',
            controlName: el.name,
          }
          tempFeedback.push(_subchild)
        }
        if (data.visibleFeedbacks && data.visibleFeedbacks.length > 0) {
          let hiddenData = data.visibleFeedbacks
          const _visiblestopic = tempFeedback.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            }else{
              newData.isShow = false
            }
            return newData
          });
          feedbackStore.setVisiblFeedback(_visiblestopic)
        } else {
          feedbackStore.setVisiblFeedback(tempFeedback)
        }

      }
      //fb forms
      let tempFeedbackForms = []
      if (feedbackStore.feedbackformNormalVisualization.length > 0) {
        let _fbArrs = feedbackStore.feedbackformNormalVisualization
        for (const element of _fbArrs) {
          let _subchild = {
            title: element.title,
            isLeaf: true,
            isShow: false,
            key: element.id,
            type: 'feedback',
            controlName: element.id,
            typeFolder: 'form'
          }
          tempFeedbackForms.push(_subchild)
        }
        if (data.visibleFeedbackForms && data.visibleFeedbackForms.length > 0) {
          let hiddenData = data.visibleFeedbackForms
          const _visiblestopic = tempFeedbackForms.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            } else {
              newData.isShow = false
            }
            return newData
          });
          feedbackStore.setVisiblFeedbackForm(_visiblestopic)
        } else {
          feedbackStore.setVisiblFeedbackForm(tempFeedbackForms)
        }
      }


      // project link
      let listProjectLink = toJS(projectStore.listProjectLink)
      if (listProjectLink.length > 0 && data?.projectLinks?.length > 0) {
        data.projectLinks.map(projectLink => {
          listProjectLink = listProjectLink.map(item => {
            if (item.id === projectLink?.projectLinkId) {
              item.visibleData = projectLink.visibleData
              const index = projectLink.visibleData?.findIndex(x => x.userId === usersStore.currentUser?.id)
              if (index > -1 && projectLink.visibleData[index]) {
                item.isVisible = projectLink.visibleData[index].isVisible
                item.isVisible4D = projectLink.visibleData[index].isVisible4D
                item.isVisibleClip = projectLink.visibleData[index].isVisibleClip
              }
            }
            return item
          })
        })
        projectStore.setListProjectLink(listProjectLink)
      }

    }
  }

  const viewpointUrlInfo = useMemo(() => {
    let vpURLInfo;
    const viewpoints = projectStore.projectDetail?.viewpoints || [];
    const { search } = props.location;
    const queryObject = queryString.parse(search)
    if (queryObject?.viewpoint && viewpoints?.length > 0) {
      vpURLInfo = viewpoints.find(c => c.name === queryObject.viewpoint);
    }
    return toJS(vpURLInfo);

  }, [props.location.search, projectStore.projectDetail?.viewpoints])

  const initialVisibleFolder = () => {
    const allFolders = projectStore.getFoldersWithChildren();
    if (allFolders?.length > 0) {
      let _visiblesFolder = allFolders.map(function (item) {
        return {
          title: item.title,
          parentKey: item.parentKey,
          key: item.key,
          isVisible4D: true,
          startDate: item.startDate,
          endDate: item.endDate
        }
      });
      projectStore.setVisible4DFolders(_visiblesFolder)
    }
  }


  const getCurrent3DViewSetting = (savePrevious) => {
    let camera = viewerRef?.current?.cesiumElement?.camera;
    if (!camera) return;
    let flyOption = {}
    flyOption.destination = new Cartesian3(
      camera.position.x,
      camera.position.y,
      camera.position.z
    )
    flyOption.orientation = {
      heading: camera.heading,
      pitch: camera.pitch,
      roll: camera.roll
    }
    let _visibleData = toJS(getVisileDataTree(projectStore, topicStore, sketchingStore, feedbackStore, usersStore.currentUser?._id))
    let navigationStyles;
    let navigationInstructions = projectStore?.projectDetail?.metadata?.navigationInstructions || []
    if (navigationInstructions?.length > 0) {
      navigationStyles = projectStore?.navigationStyles?.type ? projectStore.navigationStyles : navigationInstructions[0];
    } else {
      navigationStyles = { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true };
    }

    let gotoViewer = {
      projectId: projectStore.projectDetail._id,
      userId: usersStore.currentUser?._id ? usersStore.currentUser._id : 'public',
      flyOption: flyOption,
      timeSlider: schedulingStore.currentViewingTime,
      modelHiden: _visibleData.visibleTilesets,
      visibleSketches: _visibleData.visibleSketches,
      visibleTopics: _visibleData.visibleTopic,
      visibleFeedbacks: _visibleData.visibleFeedback,
      visibleFeedbackForms: _visibleData.visibleFeedbackForms,
      visible4DModels: _visibleData.visible4DModels,
      visibleClipModels: _visibleData.visibleClipModels,
      visible4DFolders: _visibleData.visible4DFolders,
      projectLinks: _visibleData.projectLinks,
      navigationStyles: {
        type: navigationStyles?.type,
        control: navigationStyles?.control,
        distanceLimit: navigationStyles?.distanceLimit,
        allowUnderground: navigationStyles?.allowUnderground
      },
      lightSetting: projectSettingStore.systemProjectSetting?.lightSetting,
      shadowsSetting: {
        enableShadows: projectSettingStore.systemProjectSetting?.enableShadows,
        softShadows: projectSettingStore.systemProjectSetting?.renderResolution?.softShadows,
        shadowDarkness: projectSettingStore.systemProjectSetting?.renderResolution?.shadowDarkness,
        shadowDistance: projectSettingStore.systemProjectSetting?.renderResolution?.shadowDistance,
        shadowAccuracy: projectSettingStore.systemProjectSetting?.renderResolution?.shadowAccuracy
      },
      is2D: projectStore?.is2D
    }
    if(savePrevious){
      projectStore.setPrevious3DViewSetting(gotoViewer)
      projectStore.clearTargetViewPoint()
    }
    return gotoViewer;
  }

  const syncLastSessionSettings = (retrievedObject) => {
    // last seting when user left project
    if (!retrievedObject || !viewerRef?.current?.cesiumElement) return;
    projectStore.set2D(false)
    projectStore.setVisible4DFolders(retrievedObject.visible4DFolders)
    hanlde4DTimeSlider(retrievedObject.timeSlider)
    setxDCamera(viewerRef.current.cesiumElement, retrievedObject.flyOption)
    projectStore.setCameraHome(retrievedObject.flyOption)
    hanldeVisibleDataTree(retrievedObject)
    const navigationInstructions = projectStore?.projectDetail?.metadata?.navigationInstructions || [];
    const resultNavigationInstructions = navigationInstructions[0];
    if (retrievedObject?.navigationStyles) {
      projectStore.setNavigationStyles(
        retrievedObject?.navigationStyles?.type ? retrievedObject?.navigationStyles
          : (resultNavigationInstructions ? resultNavigationInstructions : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
      );
    } else {
      projectStore.setNavigationStyles(resultNavigationInstructions ? resultNavigationInstructions : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
    }
    setDataLight(retrievedObject)
    setTimeout(() => projectStore.set2D(retrievedObject?.is2D || false), 500)
    projectStore.setPrevious3DViewSetting(false)
  }

  useEffect(() => {
    if (isEmpty(projectStore.projectDetail)) return
    function checkProjectDetail() {
      const isLoggedIn =
        localStorage.getItem('jwt') || sessionStorage.getItem('jwt')
      if (isLoggedIn) {
        if (projectStore.projectDetail.isUserProjectMember) {
          projectStore.setVisitedMode(false)
        } else {
          projectStore.setVisitedMode(true)
        }
        //goto localStore view
        const navigationInstructions = projectStore?.projectDetail?.metadata?.navigationInstructions || [];
        const resultNavigationInstructions = navigationInstructions[0];
        var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
        if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && usersStore.currentUser && retrievedObject.userId === usersStore.currentUser._id && projectStore.projectDetail.currentUser && retrievedObject.modelHiden && retrievedObject.timeSlider) {
          /// last setting
          syncLastSessionSettings(retrievedObject)
          projectStore.setLoadingProgress(false)
        } else {
          /// the first time user access to project
          // if (projectStore.projectDetail.viewpoints && projectStore.projectDetail.viewpoints.length > 0) {
          //   let _viewpointData = viewpointUrlInfo?.id ? viewpointUrlInfo : projectStore.projectDetail.viewpoints.sort((a, b) => a.name.localeCompare(b.name))[0]
          //   if (_viewpointData?.cameraData?.timeSlider) {
          //     hanlde4DTimeSlider(_viewpointData.cameraData.timeSlider)
          //   }
          //   goViewpoint(viewerRef.current.cesiumElement, _viewpointData)
          //   projectStore.setCameraHome(_viewpointData)
          //   projectStore.setCurrentViewpoint(_viewpointData)
          //   projectStore.setTypeViewPoint('viewpoint')
          //   projectStore.setTargetViewPoint()
          //   projectStore.setNavigationStyles(resultNavigationInstructions ? resultNavigationInstructions : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
          // } 
          // else {
            // no viewpoint            
            if (projectStore.projectDetail._id) {
              if (projectStore.projectDetail?.metadata?.projectSetting?.length > 0) {
                var visibleTreeData = projectStore.projectDetail.metadata.projectSetting.find(x => x.userid === usersStore.currentUser._id)?.visibleTreeData
                if (visibleTreeData && visibleTreeData.projectId === projectStore.projectDetail._id && usersStore.currentUser && visibleTreeData.userId === usersStore.currentUser._id && projectStore.projectDetail.currentUser && visibleTreeData.modelHiden && visibleTreeData.timeSlider) {
                  hanlde4DTimeSlider(visibleTreeData.timeSlider)
                  setxDCamera(viewerRef.current.cesiumElement, visibleTreeData.flyOption)
                  projectStore.setCameraHome(visibleTreeData.flyOption)
                  hanldeVisibleDataTree(visibleTreeData)
                  setDataLight(visibleTreeData)
                  projectStore.setVisible4DFolders(visibleTreeData?.visible4DFolders || [])
                }else{
                  if (projectStore.projectDetail.viewpoints && projectStore.projectDetail.viewpoints.length > 0) {
                    let _viewpointData = viewpointUrlInfo?.id ? viewpointUrlInfo : projectStore.projectDetail.viewpoints.sort((a, b) => a.name.localeCompare(b.name))[0]
                    if (_viewpointData?.cameraData?.timeSlider) {
                      hanlde4DTimeSlider(_viewpointData.cameraData.timeSlider)
                    }
                    goViewpoint(viewerRef.current.cesiumElement, _viewpointData)
                    projectStore.setCameraHome(_viewpointData)
                    projectStore.setCurrentViewpoint(_viewpointData)
                    projectStore.setTypeViewPoint('viewpoint')
                    projectStore.setTargetViewPoint()
                    setTimeout(() => projectStore.setTargetViewPoint(), 3000)
                    projectStore.setNavigationStyles(resultNavigationInstructions ? resultNavigationInstructions : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
                  } 
                }
                const _projectSetting = projectStore.projectDetail.metadata.projectSetting.find(x => x.userid === usersStore.currentUser._id)?.projectSetting
                if (_projectSetting) {
                  projectStore.set2D(_projectSetting?.is2D)
                }
              } else {
                if (projectStore.projectDetail.viewpoints && projectStore.projectDetail.viewpoints.length > 0) {
                  let _viewpointData = viewpointUrlInfo?.id ? viewpointUrlInfo : projectStore.projectDetail.viewpoints.sort((a, b) => a.name.localeCompare(b.name))[0]
                  if (_viewpointData?.cameraData?.timeSlider) {
                    hanlde4DTimeSlider(_viewpointData.cameraData.timeSlider)
                  }
                  goViewpoint(viewerRef.current.cesiumElement, _viewpointData)
                  projectStore.setCameraHome(_viewpointData)
                  projectStore.setCurrentViewpoint(_viewpointData)
                  projectStore.setTypeViewPoint('viewpoint')
                  projectStore.setTargetViewPoint()
                  setTimeout(() => projectStore.setTargetViewPoint(), 3000)
                  projectStore.setNavigationStyles(resultNavigationInstructions ? resultNavigationInstructions : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
                }else{
                  let _camPosition = projectStore.projectDetail.metadata.cameraPosition
                  if (_camPosition && _camPosition.length > 0 && usersStore.currentUser._id) {
                    let _camuser = _camPosition.find(obj => obj.userId === usersStore.currentUser._id)
                    if (_camuser) {
                      goViewpoint(viewerRef.current.cesiumElement, { cameraData: _camuser })
                      projectStore.setCameraHome({ cameraData: _camuser })
                    }
                  }
                  setDataLight(projectSettingStore.defaultSystemProjectSetting)
                  initialVisibleFolder()
                } 
              }
              //Check navigation style (event mouse for cesium) 
              let navStyle = projectStore.projectDetail.metadata.navigationInstructions
              if (navStyle && navStyle.length > 0) {
                let prjNavigationInstructions = navStyle[0]
                projectStore.setNavigationStyles(
                  prjNavigationInstructions?.type ? prjNavigationInstructions
                    : { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true }
                );
              } else { // if not exist set default
                projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
              }
            }
          // }
        }

      } else if (projectStore.projectDetail.isPublic) {
        projectStore.setVisitedMode(true)
        let _viewpointData = viewpointUrlInfo ? viewpointUrlInfo : projectStore.projectDetail.viewpoints.sort((a, b) => a.name.localeCompare(b.name))[0]
        if (_viewpointData) {
          if (_viewpointData?.cameraData?.timeSlider) {
            hanlde4DTimeSlider(_viewpointData.cameraData.timeSlider)
          }
          goViewpoint(viewerRef.current.cesiumElement, _viewpointData)
          projectStore.setCurrentViewpoint(_viewpointData)
          projectStore.setTypeViewPoint('viewpoint')
          projectStore.setTargetViewPoint()
          setTimeout(() => projectStore.setTargetViewPoint(), 3000)
          setDataLight(_viewpointData)
        } else {
          initialVisibleFolder()
          //public project check if has localStorage gotoview then use localstore else go to viewpoint
        }
      } else {
        setRedirecToLogin(true)
      }
    }

    if (!viewerRef.current.cesiumElement) return
    if(!projectStore.targetViewPoint){
      checkProjectDetail()
    }

    //Drag and drop files into 3D view    
    if (!viewerRef.current.cesiumElement.dropTarget) {
      viewerRef.current.cesiumElement.extend(viewerDragDropMixinxd, {
        clearOnDrop: false
      });
      // remove auto add datasource default when drag drop .czml, geojson, json, topojson, kml, kmz, gpx
      viewerRef.current.cesiumElement.dataSources.dataSourceAdded.addEventListener(function (collection, dataSource) {
        var entities = dataSource.entities.values;
        //Do not remove data source from citydbkml bcs it cause model disappear forever
        if (entities.length > 0 && dataSource?.dbSource !== "CityDBKmlDataSource") {
          viewerRef.current.cesiumElement.dataSources.remove(dataSource);
        }
      });
    }
    // viewerRef.current.cesiumElement.clearOnDrop = true;
    viewerRef.current.cesiumElement.flyToOnDrop = false;
    var dropArea = viewerRef.current.cesiumElement.dropTarget;
    if (dropArea) {
      setDragDrop3DView(
        <DropMultipleFiles3DView dropArea={dropArea} viewer={viewerRef} />
      )
    }

    //Check  startup mode    
    // if (projectStore.projectDetail.defaultStartupMode === 'gps') {
    //   projectStore.setGpsMode('free')
    // } else if (projectStore.projectDetail.defaultStartupMode === 'gps_compass') {
    //   // set gps mode free before set fix in GPS
    //   projectStore.setGpsMode('free')
    // } else if (projectStore.projectDetail.defaultStartupMode === 'ar_with_3d') {
    //   projectStore.setGpsMode('first_person')
    //   projectStore.setSkyColor('cesium')
    // } else if (projectStore.projectDetail.defaultStartupMode === 'ar_with_camera') {
    //   projectStore.setGpsMode('first_person')
    //   projectStore.setSkyColor('camera')
    // }

    //Check render resolution
    if (projectStore.projectDetail.metadata.renderResolution) {
      viewerRef.current.cesiumElement.resolutionScale = projectStore.projectDetail.metadata.renderResolution.resolutionScale
      viewerRef.current.cesiumElement.useBrowserRecommendedResolution = projectStore.projectDetail.metadata.renderResolution.useBrowserRecommendedResolution
      // viewerRef.current.cesiumElement.shadowMap.softShadows = projectStore.projectDetail.metadata.renderResolution.softShadows
    }

    // //Check navigation style (event mouse for cesium) 
    // let navStyle = projectStore.projectDetail.metadata.navigationInstructions
    // // if (navStyle && navStyle.length > 0 && navStyle.findIndex(obj => obj.userId === usersStore.currentUser._id) > -1) {
    //   if (navStyle && navStyle.length > 0) {
    //   let prjNavigationInstructions = navStyle[0]
    //   // let prjNavigationInstructions = navStyle.find(x => x.userId === usersStore.currentUser._id)
    //   projectStore.setNavigationStyles(prjNavigationInstructions)
    // } else { // if not exist set default
    //   projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
    // }

    // set project setting
    if (usersStore.currentUser._id) {
      let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
      if (_systemProjectSetting.length > 0) {
        let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser._id)

        // ifc setting
        if (_currentUserSystemSetting?.projectSetting && _currentUserSystemSetting?.projectSetting.ifcSetting) {
          projectSettingStore.setIfcSetting(_currentUserSystemSetting.projectSetting.ifcSetting)
        } else {
          projectSettingStore.setIfcSetting(projectSettingStore.defaultSystemProjectSetting.ifcSetting)
        }

        // fog setting
        if (_currentUserSystemSetting?.projectSetting && _currentUserSystemSetting?.projectSetting.fogSetting) {
          projectSettingStore.setFogSetting(_currentUserSystemSetting.projectSetting.fogSetting)
        } else {
          projectSettingStore.setFogSetting(projectSettingStore.defaultSystemProjectSetting.fogSetting)
        }

      } else {
        projectSettingStore.setIfcSetting(projectSettingStore.defaultSystemProjectSetting.ifcSetting)
        projectSettingStore.setFogSetting(projectSettingStore.defaultSystemProjectSetting.fogSetting)
      }
    } else {
      projectSettingStore.setIfcSetting(projectSettingStore.defaultSystemProjectSetting.ifcSetting)
      projectSettingStore.setFogSetting(projectSettingStore.defaultSystemProjectSetting.fogSetting)
    }

    // Save current user viewpoint to project
    // add event camera moveend
    if (projectStore.projectDetail._id) {
      viewerRef.current.cesiumElement.camera.moveEnd.addEventListener(function () {
        if (usersStore.currentUser && usersStore.currentUser._id && projectStore.gpsMode === 'none' && projectStore.showEditLocation == false) { //if in ar mode and edit model then not save camera position
          // let modelHiden = projectStore.visibleTilesets.filter(c => !c.isVisible)
          // delayedSaveProjectCurrentViewpoint(projectStore, usersStore.currentUser._id, viewerRef, schedulingStore.currentViewingTime, modelHiden);
        }
      });
      if (usersStore.currentUser?._id) {
        usersStore.getSystemUserRole()
      }
      if (usersStore.currentUser?._id && projectStore.projectDetail?.organization) {
        organizationStore.getOrgLicenses(projectStore.projectDetail?.organization._id).then(response => {
          if (response.status === 'failed' || response.length === 0) {
            projectStore.setIsExistLicenses(false)
            message.error(t('no-license-avaiable-organization'), 5);
          } else {
            projectStore.setIsExistLicenses(true)
          }
        })
        adminStore.getRoleCurrentUserInOrganization(projectStore.projectDetail?.organization._id)
      }
      let cancelToken
      intervalLicenses = setInterval(function () {
        if (viewerRef && viewerRef.current && viewerRef.current.cesiumElement && usersStore.currentUser?._id) {
          const checkLicenseProject = async () => {
            if (typeof cancelToken !== typeof undefined) {
              cancelToken.cancel('Operation canceled due to new request.')
            }
            cancelToken = axios.CancelToken.source()

            try {
              const response = await axios.get(
                `${apiUrl}/licenses/getOrgLicenses/${projectStore.projectDetail?.organization._id}`,
                {
                  headers: {
                    Authorization: `Bearer ${commonStore.token
                      }`,
                  },
                  cancelToken: cancelToken.token
                }
              )
              if (response) {
                if (response.data) {
                  if (response?.data?.status === 'failed' || response?.data?.length === 0) {
                    projectStore.setIsExistLicenses(false)
                  } else {
                    projectStore.setIsExistLicenses(true)
                  }
                } else {
                  projectStore.setIsExistLicenses(false)
                }
              }
            } catch (error) {
              // projectStore.setIsExistLicenses(false)
            }
          }
          if (usersStore.currentUser?._id && projectStore.projectDetail?.organization) {
            checkLicenseProject()
          }
        }
      }, 300000)

      intervalID = setInterval(function () {
        if (viewerRef && viewerRef.current && viewerRef.current.cesiumElement) {
          let gotoViewer = getCurrent3DViewSetting();

          if (projectStore.gpsMode === 'none' && projectStore.showEditLocation == false) {
            if (usersStore.currentUser && usersStore.currentUser._id) {
              projectSettingStore.setVisibleTreeData(gotoViewer)
              if (!projectStore.isEditSessionVisibleData)
                localStorage.setItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`, JSON.stringify(gotoViewer));
            } else {
              localStorage.setItem(`gotoviewer-${projectStore.projectDetail._id}-public`, JSON.stringify(gotoViewer));
            }
          }
        }
      }, 1000)

      intervalUpdateLastSettings = setInterval(function () {
        if (usersStore?.currentUser?._id) {
          let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore);
          projectStore.updateProjectMetadata({ metadata,  store : 'metadata'})
        }
      }, 60000)

      intervalCheckPointCloudReady = setInterval(function () {
        if (usersStore?.currentUser?._id && projectStore?.projectDetail?._id) {
          checkXDEnginePointCloudReady()
        }
      }, 30000)

      // intervalUpdateLas = setInterval(async function () {
      //   if (!isMergeModel) {
      //     setIsMergeModel(true)
      //     const values = await projectStore.getAllLasTrigger(props.match.params.projectId);
      //     if (values.length > 0) {
      //       await Promise.all(values.map(async item => {
      //         await projectStore.deleteLasTriggerId(item.id)
      // const model = item.model;
      // const project = item.project;
      // let rsp_model = await updateProjectModel(project, projectStore.modelList, model)
      // let _project = rsp_model.project
      // if (_project.tilesetData && _project.tilesetData.RefPoint && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
      //   let _model3ds = _project.model3DS.map(c => {
      //     let u = { ...c }
      //     if (c.id === model.id) {
      //       u.newUpdate = true
      //     }
      //     return u;
      //   })
      //   let modelmerge = await mergeAllModels(
      //     _model3ds,
      //     Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
      //     _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
      //     _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
      //     _project.headingRotation,
      //     _project.tilesetData.coordinateSystem.code,
      //     _project.elevationSystem ? _project.elevationSystem : 'None'
      //   );
      //   _project.model3DS = modelmerge
      // }
      // await projectStore.updateProjectRefPoint(_project)
      //         notification.success({
      //           message: `Update location model successfully: ${model.name}`,
      //           duration: 5,
      //         })
      //       }))
      //     }
      //     setTimeout(() => setIsMergeModel(false), 10000)
      //   }
      // }, 10000)

      // intervalVisibleData = setInterval(function () {
      //   var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
      //   if (retrievedObject) {
      //     projectSettingStore.setVisibleTreeData(retrievedObject)
      //     let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
      //     projectStore.updateProjectData({ metadata,  store : 'metadata'})
      //   }
      // }, 60000)

    }
  }, [projectStore.projectDetail])

  /**
   * Effect check and set navigation style (event mouse for cesium)
   */
  useEffect(() => {
    if (projectStore.navigationStyles && viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement.scene) {
      let _screenSpaceCameraController = viewerRef.current.cesiumElement.scene.screenSpaceCameraController
      let _navigationControl = projectStore.navigationStyles.control

      // Add event listeners to limit camera movement within the spherical range
      const refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint;
      let scene = viewerRef?.current?.cesiumElement?.scene;
      let camera = viewerRef.current.cesiumElement.camera

      if (refPoint && scene) {
        const center = Cesium.Cartesian3.fromDegrees(refPoint[1], refPoint[0], refPoint[2])
        const handleLimitCameraRange = () => {
          const cameraPosition = camera.position;
          const distance = Cesium.Cartesian3.distance(center, cameraPosition);
          let maxRadius = projectStore.navigationStyles?.distanceLimit || -1;
          if (distance > maxRadius && maxRadius !== -1) {
            // Calculate the direction from the center to the camera position
            const direction = Cesium.Cartesian3.normalize(Cesium.Cartesian3.subtract(cameraPosition, center, new Cesium.Cartesian3()), new Cesium.Cartesian3());

            // Calculate the new camera position at the maximum allowed radius
            const newPosition = Cesium.Cartesian3.add(Cesium.Cartesian3.multiplyByScalar(direction, maxRadius, new Cesium.Cartesian3()), center, new Cesium.Cartesian3());

            // Set the new camera position
            camera.position = newPosition;
          }
        }
        scene.preUpdate.addEventListener(handleLimitCameraRange);
      }

      if (scene) {
        scene.screenSpaceCameraController.enableCollisionDetection = !(projectStore.navigationStyles?.allowUnderground ?? true);
      }

      if (projectStore.navigationStyles.type === 'custom') {
        _screenSpaceCameraController.zoomEventTypes = _navigationControl.zoom
        _screenSpaceCameraController.rotateEventTypes = _navigationControl.pan
        _screenSpaceCameraController.tiltEventTypes = [...merge_array(_navigationControl.rotate, _navigationControl.tilt),
        { eventType: CameraEventType.LEFT_DRAG, modifier: KeyboardEventModifier.CTRL }, { eventType: CameraEventType.RIGHT_DRAG, modifier: KeyboardEventModifier.CTRL }
        ]
      }
      if (projectStore.navigationStyles.type === 'connect') {
        _screenSpaceCameraController.lookEventTypes = [CameraEventType.RIGHT_DRAG] // Right button = Turn head
        _screenSpaceCameraController.zoomEventTypes = [CameraEventType.WHEEL, CameraEventType.PINCH] // Scroll roll = Zoom
        _screenSpaceCameraController.tiltEventTypes = [CameraEventType.LEFT_DRAG, CameraEventType.PINCH] //Left button = orbit
        _screenSpaceCameraController.rotateEventTypes = [CameraEventType.MIDDLE_DRAG] //Middle button = Pan in screen plane
      }
      if (projectStore.navigationStyles.type === 'cesium') {
        _screenSpaceCameraController.zoomEventTypes = [CameraEventType.RIGHT_DRAG, CameraEventType.WHEEL, CameraEventType.PINCH]
        _screenSpaceCameraController.rotateEventTypes = [CameraEventType.LEFT_DRAG]
        _screenSpaceCameraController.tiltEventTypes = [CameraEventType.MIDDLE_DRAG, CameraEventType.PINCH, {
          eventType: CameraEventType.LEFT_DRAG, modifier: KeyboardEventModifier.CTRL
        }, { eventType: CameraEventType.RIGHT_DRAG, modifier: KeyboardEventModifier.CTRL }]
      }
      if (projectStore.navigationStyles.type === 'xdTwin') {
        _screenSpaceCameraController.zoomEventTypes = [CameraEventType.WHEEL, CameraEventType.PINCH, CameraEventType.MIDDLE_DRAG]
        _screenSpaceCameraController.rotateEventTypes = [CameraEventType.LEFT_DRAG]
        _screenSpaceCameraController.tiltEventTypes = [CameraEventType.RIGHT_DRAG, CameraEventType.PINCH, {
          eventType: CameraEventType.LEFT_DRAG, modifier: KeyboardEventModifier.CTRL
        }, { eventType: CameraEventType.RIGHT_DRAG, modifier: KeyboardEventModifier.CTRL }]
      }

      scene.requestRender()
    }
  }, [projectStore.navigationStyles, projectStore.projectDetail?.tilesetData?.RefPoint])


  let viewer = viewerRef?.current?.cesiumElement;
  const pastCameraLocations = useRef([]);
  const maxPastLocations = 10;
  const isCameraMoving = useRef(false);

  // Function to record the current camera location
  const recordCameraLocation = () => {
    try {
      const currentLocation = {
        orientation: {
          heading: viewer.camera.heading,
          pitch: viewer.camera.pitch,
          roll: viewer.camera.roll,
        },
        destination: new Cartesian3(
          viewer.camera.position.x,
          viewer.camera.position.y,
          viewer.camera.position.z
        ),
      };
      if (!projectStore.isMovingCamera) {
        pastCameraLocations.current.push(currentLocation);
        projectStore.addPastLocations(currentLocation);

        // Keep only the last maxPastLocations locations
        if (pastCameraLocations.current.length > maxPastLocations) {
          pastCameraLocations.current.shift();
        }
      }
    } catch (error) {

    }

  };

  // Event listener for camera move start
  //Camera started moving
  const handleCameraMoveStart = () => {
    recordCameraLocation();
    isCameraMoving.current = true;
  };

  // Event listener for camera move end
  //Camera stopped moving
  const handleCameraMoveEnd = () => {
    isCameraMoving.current = false;
    // recordCameraLocation();
    projectStore.setMovingCamera(false);
  };

  //Camera is moving
  const checkCameraState = () => { };
  useEffect(() => {
    if (!viewer?.camera) return;

    // Add event listeners for camera move start and end
    viewer.camera.moveStart.addEventListener(handleCameraMoveStart);
    viewer.camera.moveEnd.addEventListener(handleCameraMoveEnd);


    // Start checking the camera state
    const checkCameraStateLoop = () => {
      // If the camera is still moving, continue checking
      if (isCameraMoving.current) {
        // Check camera state
        checkCameraState();
      }
      // Continue the loop
      requestAnimationFrame(checkCameraStateLoop);
    };

    checkCameraStateLoop();

    // Optionally, clean up event listeners and the Cesium Viewer when the component unmounts
    return () => {
      try {
        if (viewer?.camera) {
          if (viewer.camera.moveStart) { viewer.camera.moveStart.removeEventListener(handleCameraMoveStart); }
          if (viewer.camera.moveEnd) { viewer.camera.moveEnd.removeEventListener(handleCameraMoveEnd); }
          if (viewer.camera.changed) { viewer.camera.changed.removeEventListener(recordCameraLocation); }
          // viewer.destroy();
        }
      } catch (error) {
      }
    };
  }, [viewer?.camera]);

  const checkFeatureOpen = () => {
    return !projectStore.displayPanel && !projectGanttStore.isShowGanttPanel && !projectStore.showAddResourceModel && !projectStore.showHiddenDrawer && !commonStore.showOrganizationPageDrawer && !commonStore.showUserSettingPageDrawer && !projectSettingStore.showProjectSettingDrawer && !projectStore.dropDownMenuCameraSettings && !capturesStore.isDrawerCaptureVisible && !schedulingStore.timeSliderVisible && !feedbackStore.shownFeedbackAnswer?.show && !feedbackStore.showDrawerFeedbackReport && !topicStore.isShowTopicEditor && !topicStore.isShownListTopic && !topicStore.isShowDrawerTopicReport && !projectStore.showProjectDashBoard && !projectStore.isShowGenericReport && !objectQueryStore.isShowListQuery && !projectStore.showDrawerCalculation && !projectStore.showEditLocation && !sketchingStore.sketchFormVisible
  }

  //improve navigation
  // key event back to home, back to past location
  // useEffect(() => {
  //   document.addEventListener('keyup', handleKeyDown);
  //   function handleKeyDown(event) {
  //     const keyPressed = event.key;
  //     if (!viewerRef?.current?.cesiumElement) return;
  //     if (keyPressed === 'Home' && checkFeatureOpen()) {
  //       if (projectStore.isAnyInputFocused) return
  //       projectStore.backToHome(viewerRef?.current?.cesiumElement)
  //     } else if (keyPressed === 'Backspace' ) {
  //       if (projectStore.isAnyInputFocused) return
  //       projectStore.backToPastLocation(viewerRef?.current?.cesiumElement)
  //     }
  //   }
  // }, [])

  /**
   * Effect check and set camera never get elevation below reference point elevation
   */
  useEffect(() => {
    if (projectStore.navigationStyles && viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement.scene) {
      const refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint;
      let scene = viewerRef?.current?.cesiumElement?.scene;
      const allowUnderground = projectStore?.navigationStyles?.allowUnderground
      if (scene) {
        if (refPoint && refPoint?.length > 0 && !allowUnderground) {
          const center = Cesium.Cartesian3.fromDegrees(refPoint[1], refPoint[0], refPoint[2]);
          function getHeightAboveGround(scene, position) {

            const heightAboveGround = Cartographic.fromCartesian(position)
            return Cesium.defined(heightAboveGround?.height) ? heightAboveGround?.height : 0;
          }
          // Get the height at the center
          const heightAtCenter = getHeightAboveGround(scene, center);
          scene.screenSpaceCameraController.minimumZoomDistance = heightAtCenter || 1;
        } else {
          scene.screenSpaceCameraController.minimumZoomDistance = 1;
        }
        scene.requestRender()
      }
    }
  }, [projectStore.navigationStyles, projectStore.projectDetail?.tilesetData?.RefPoint])

  useEffect(() => {
    if (usersStore.currentUser && usersStore.currentUser._id && props?.match?.params?.projectId) {
      projectStore.findProjectUserRole(props.match.params.projectId).then(res => {
      })
        .catch(error => {
          if (projectStore.projectDetail.isPublic) {
            projectStore.setCurrentUserRoleInProject('public_project')
          }
        })
    } else {
      projectStore.setCurrentUserRoleInProject('public_project')
    }
  }, [usersStore.currentUser])
  // }, [usersStore.currentUser, projectStore.projectDetail])



  if (redirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: props.location },
        }}
      />
    )
  }


  function onTick() {
    viewerRef.current.cesiumElement.scene.canvas.style.background = 'transparent';
    // if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement.camera) {
    //   //syncShowMap()
    //   commonStore.setCameraHeadingPitchRoll({
    //     heading: viewerRef.current.cesiumElement.camera.heading,
    //     pitch: viewerRef.current.cesiumElement.camera.pitch,
    //     roll: viewerRef.current.cesiumElement.camera.roll,
    //     timestamp: new Date().getTime()
    //   })

    //   commonStore.setCameraPossition(new Cartesian3(
    //     viewerRef.current.cesiumElement.camera._positionWC.x,
    //     viewerRef.current.cesiumElement.camera._positionWC.y,
    //     viewerRef.current.cesiumElement.camera._positionWC.z));

    //   commonStore.setCameraFov(viewerRef.current.cesiumElement.camera.frustum.fov)
    // }

  }

  return (
    <HelmetProvider>
      <DefaultTemplate viewer={viewerRef}>
        <Helmet
          title={
            projectStore.projectDetail
              ? (window.globalConfig.siteName ? window.globalConfig.siteName : t('project')) + (projectStore.projectDetail.name ? ' | ' + projectStore.projectDetail.name : '')
              : `${t('project')} | 6DPlanner`
          }
        >
        </Helmet>
        {viewerRef.current && projectStore.projectDetail._id && topicStore.isShowTopicEditor ? (
          <TopicEditorDrawer viewer={viewerRef.current.cesiumElement} location={props.location} />
        ) : ('')}
        {viewerRef.current && projectStore.projectDetail._id && objectQueryStore.isShowListQuery ? (
          <QueryDrawer viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current && projectStore.projectDetail._id && projectGanttStore.isShowGanttPanel ? (
          <DrawerGanttPanel viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && topicStore.isShownListTopic ? (
          <TopicListDrawer location={props.location} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && topicStore.isDrawer3DViewVisible  ? (
          <Drawer3DView viewerRef={viewerRef} viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {
          viewerRef.current?.cesiumElement && topicStore.isShowDrawerTopicReport ? (
            <DrawerTopicReport viewer={viewerRef.current.cesiumElement} />
          ) : ('')
        }
        {
          viewerRef.current?.cesiumElement && feedbackStore.showDrawerFeedbackReport ? (
            <DrawerFeedbackReport viewer={viewerRef.current.cesiumElement} />
          ) : ('')
        }
        {viewerRef.current?.cesiumElement ? (
          <DrawerTilesetExplorer syncLastSessionSettings={syncLastSessionSettings} getCurrent3DViewSetting={getCurrent3DViewSetting} viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement ? (
          <StickyLogo />
        ) : ('')}
        {viewerRef.current?.cesiumElement ? (
          <DrawerProjectDashBoard viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showDrawerCalculation ? (
          <DrawerCalculation viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.projectDetail._id ? (
          <DrawerCaptureViews viewerRef={viewerRef} viewer={viewerRef.current.cesiumElement} location={props.location} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showAddResourceModel ? (
          <PDetailAddResources viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && fileStore.photoViewer360 ? (
          <PhotoViewer360 viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showUpdateResourceModel ? (
          <ModalUpdateResources viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.dataAttributeModal ? (
          <ModalDataAttribute viewer={viewerRef} />
        ) : ('')}
        {projectStore.showProjectInfoDrawer ? (
          <DrawerProjectInfo />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showHiddenDrawer ? (
          <DrawerHiddenTiles viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showFeatureSettingDrawer ? (
          <DrawerFeatureSetting viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showDrawerConnectNavigation? (
          <DrawerUserNavigationSetting viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && objectInfo ? (
          <ObjectDetail
            viewer={viewerRef.current.cesiumElement}
            handler={objectInfo.handler}
            tile={objectInfo.tile}
            model={objectInfo.model}
          />
        ) : ('')}
        {schedulingStore.timeSliderVisible ? <TimeSlider /> : null}
        {alignmentStore.alignmentTimeSliderVisible ? <FollowAlignmentTool viewer={viewerRef} /> : null}
        {viewerRef.current?.cesiumElement ? (
          <AttributesPanel getCurrent3DViewSetting={getCurrent3DViewSetting} viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && feedbackStore?.shownFeedbackAnswer?.show ? (
          <DrawerFeedbackAnswer viewer={viewerRef} />
        ) : ('')}
        {projectSettingStore.showProjectSettingDrawer ? (<DrawerProjectSetting />) : ('')}        
        {viewerRef.current?.cesiumElement && feedbackStore.showFeedbackEditorDrawer ? (
          <DrawerFeedbackEditor viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && workflowStore.showWorkflowDrawer ? (
          <DrawerWorkflow viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && sketchingStore.showProjectSketchDrawer ? (
          <DrawerProjectSketch viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showCustomAttributeDrawer ? (
          <DrawerCustomAttribute viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.isShowGenericReport ? (
          <DrawerGenericReport viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {viewerRef.current?.cesiumElement && projectStore.showUserGroupDrawer ? (
          <DrawerUserGroup viewer={viewerRef.current.cesiumElement} />
        ) : ('')}
        {projectTeamsStore.showProjectTeamsDrawer ? (
          <DrawerProjectTeams />
        ) : ('')}
        {projectStore.showProjectLinkDrawer ? (
          <DrawerProjectLink />
        ) : ('')}
        {projectStore.showAddProjectTemplateModal ? (
          <ModalAddProjectTemplate />
        ) : ('')}        
        <LicenseExpirationNotificationModal />
        {/* {sketchingStore.geometrySketch && (<PolygonPortal />)} */}
        <React.Fragment>
          {(projectStore.gpsMode.indexOf('first_person') > -1 || (projectStore.isMasking && commonStore.manualGpsState !== 1)) &&
            commonStore.deviceId ? (
            <>
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width,minimum-scale=1.0,maximum-scale=10.0,initial-scale=1.0"
                />
              </Helmet >
              <Webcam
                videoConstraints={{ deviceId: commonStore.deviceId }}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                forceScreenshotSourceSize={false}
                minScreenshotWidth={1280}
                style={{
                  width: `${isPortrait == false ? '100%' : ''}`,
                  height: `${isPortrait == true ? '100%' : ''} `,
                  position: 'fixed',
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  display: 'block',
                  position: 'absolute',
                  bottom: '50px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  padding: '0px 0px 10px 0px',
                  zIndex: 999,
                  background: 'none',
                }}>
              </div>
            </>
          ) : (
            ''
          )}
          <Viewer

            full
            // sceneModePicker={false}
            timeline={false}
            homeButton={false}
            vrButton={true}
            fullscreenButton={false}
            navigationInstructionsInitiallyVisible={false}
            navigationHelpButton={false}
            selectionIndicator={false}
            infoBox={false}
            sceneModePicker={false} // hide 3D/2D/Columbus view button
            contextOptions={defaultContextOption}
            // selectedImageryProviderViewModel={selectedImageryProviderViewModel}
            // imageryProvider={defaultImagery}
            // terrainProvider={terrainProvider}
            // requestRenderMode={true}
            // maximumRenderTimeChange={Infinity}
            requestRenderMode={true}
            // maximumRenderTimeChange={Infinity}
            // imageryProviderViewModels={defaultImagery}
            shadows={commonStore.enableShadows}
            terrainShadows={ShadowMode.ENABLED}
            onClick={(m, tile) => {
              capturesStore.toggleDrawerCaptureVisible(false)
              if (!tile) {
                if (projectStore.clippingMode) return
                if (projectStore.currentTile)
                  setColorTile(projectStore.currentTile, false, projectStore.findHideFeaturesByUrl(projectStore.currentTile?.resource?.url || projectStore.currentTile?._url))
              }
            }}
            //style={{ height: 'calc(100vh)' }}
            ref={viewerRef}>
            <SkyBox
              show={
                projectStore.gpsMode.indexOf('first_person') === -1 || projectStore.skyColor === 'cesium'
              }
            />
            <SkyAtmosphere
              show={
                projectStore.gpsMode.indexOf('first_person') === -1 || projectStore.skyColor === 'cesium'
              }
            />
            {projectStore.Alignment && projectStore.Alignment.map(a => {
              return a
            })}
            {projectStore.tileViews.map(tile => tile)}
            {projectStore.tileLinkViews.map(tile => tile)}
            {drawingSketch ? drawingSketch : ''}
            {sketchView}
            {drawingPolylineCut ? drawingPolylineCut : ''}
            {hiddenAreaView}
            {refPointView}
            {imageryViewLink}
            {cityDB3DViewLink}
            {geoJsonViewLink}
            {/* {i3SDataProviderLink} */}
            {imageryView}
            {cityDB3DView}
            {geoJsonView}
            {i3SDataProvider}
            {topicLocationView}
            {feedbackVisualization}
            {feedbackformVisualization}
            {measurePoints}
            {measureDistance}
            {measureArea}
            {measurePolyline}
            {moveTile}
            {gpsView}
            {clippingView}
            {pickLocView}
            {settingView}
            {settingRenderResolutionView}
            {renderRecordDataView}
            {IFCSettingModal}
            {UndergroundSettingModal}
            {TransparentSettingModal}
            {FogSettingModal}
            {LightSettingModal}
            {ImporterSettingModal}
            {backgroundColorSettingModal}
            {AmbientOccSettingModal}
            {dragDrop3DView}
            {modalProcessingModel3D}
            {renderTopic3DLocation}
            {renderTopic3DObjects}
            {renderSelectObjectModelControl}
            {renderAddLocationFeedbackEditor}
            {fogView}
            {/* {projectStore.isMasking && commonStore.manualGpsState !== 1 ? <PostProcessStage fragmentShader={fragmentShader} /> : ''} */}
            <Clock onTick={onTick}></Clock>
            {!isSafari && projectStore.showAmbientOcclusion && !projectStore.isMasking && projectStore.gpsMode !== 'first_person' ? <AmbientOcclusion
              bias={ambientSetting.bias}
              intensity={ambientSetting.intensity}
              lengthCap={ambientSetting.lengthCap}
              stepSize={ambientSetting.stepSize}
              // frustumLength={ambientSetting.frustumLength}
              blurStepSize={ambientSetting.blurStepSize}
            /> : ''}
          </Viewer>
          {!projectStore.hideTwinGuideDialog && <XDTwinGuideModal />}
        </React.Fragment>
      </DefaultTemplate>
    </HelmetProvider >
  )
}

export default inject(
  'commonStore',
  'uiStore',
  'projectStore',
  'capturesStore',
  'schedulingStore',
  'sketchingStore',
  'usersStore',
  'topicStore',
  'feedbackStore',
  'workflowStore',
  'adminStore',
  'projectSettingStore',
  'organizationStore',
  'alignmentStore',
  'polylineCutToolStore',
  'authStore',
  'modelStore',
  'measureStore',
  'userGroupStore',
  'projectTeamsStore',
  'objectQueryStore',
  'projectGanttStore',
  'calculationStore'
)(observer(ProjectDetailPage))
