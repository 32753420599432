import {
  CameraOutlined,
  CompassOutlined,
  ControlOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  TeamOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Avatar, Button, Popover, Tooltip, notification } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { ReactComponent as DataAddFile } from '../../../../assets/svgs/data-add-file-b0.svg';
import { ReactComponent as DataTree } from '../../../../assets/svgs/datatree-b0.svg';
import { ReactComponent as HiddenTile } from '../../../../assets/svgs/hidden-tile-s0.svg';
import { ReactComponent as ProjectSetting } from '../../../../assets/svgs/project-settings-s0.svg';
import { ReactComponent as Sketch } from '../../../../assets/svgs/sketch-b0.svg';
import { ReactComponent as GanttIcon } from '../../../../assets/svgs/gantt-icon-no-border.svg';
import SVGIcon from '../../../elements/SVGIcon';
import SketchTool from '../../../pages/ProjectDetailPage/SketchTool';
import { ToolbarLeftContainer, ToolbarLeftItem, ToolbarLeftCustom } from './CustomStyled';
import { useTranslation } from 'react-i18next';
import fileStore from '../../../../stores/fileStore';
import { toJS } from 'mobx';
import { useMediaQuery } from 'react-responsive';

const ToolbarLeft = props => {
  const {
    commonStore,
    projectStore,
    workflowStore,
    sketchingStore,
    uiStore,
    currentPage,
    history,
    match,
    adminStore,
    projectGanttStore
  } = props
  const { t } = useTranslation();

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
}

  const clickAddResource = () => {
    sketchingStore.setSketchToolVisible(false)
    uiStore.setClippingPlaneToolVisible(false);
    if (!fileStore.modalDrop3DView) {
      projectStore.setShowAddResourceModel(true)
      projectStore.setCurrentAddTab('uploadTab')
    } else {
      notification.open({
        message: t('please-wait-or-close-the-file-drag-and-drop'),
        icon: <InfoCircleOutlined style={{ color: '#faad14' }} />,
        duration: 5,
      })
    }
  }

  const handleEndDraw = () => {
    sketchingStore.setEndDrawing(true)
    projectStore.setDisplayPanel(false)
    if(sketchingStore.sketchMode ==='point'){
      sketchingStore.setSketchProps({ rotation: 0 })
    }
  }

  const clickShowExplorer = () => {
    uiStore.setClippingPlaneToolVisible(false);
    sketchingStore.setSketchToolVisible(false)
    projectStore.setDisplayPanel(true)
    projectStore.setShowProjectLinkDrawer(false)
  }

  function onChangeShowHiddenTilesList(checked) {
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setRenderRecordDataControl(false)
    projectStore.setShowHiddenDrawer(checked)
    uiStore.setRequestRender(!checked)
  }

  const clickShowGanttPanel = () => {
    uiStore.setClippingPlaneToolVisible(false);
    sketchingStore.setSketchToolVisible(false)    
    projectStore.setShowProjectLinkDrawer(false)
    projectGanttStore.setIsShowGanttPanel(true)
  }

  const viewByPage = () => {
    const defaultButton = <Fragment />
    switch (currentPage) {
      case 'cityDemo':
        return (
          <Fragment>
            {defaultButton}
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('environment')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button icon={<EnvironmentOutlined />} size={commonStore.buttonSize} />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('control')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button icon={<ControlOutlined />} size={commonStore.buttonSize} />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button icon={<CameraOutlined />} size={commonStore.buttonSize} />
              </Tooltip>
            </ToolbarLeftItem>
          </Fragment>
        )
      case 'projectDetail':
        return projectStore.projectDetail?.id ?
          (
            <Fragment>
              <Avatar.Group className="leftIcon-group"
                maxPopoverPlacement={'right'}
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', marginLeft: 0 }}
              >
                <ToolbarLeftCustom>
                  {
                    (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (
                      <Tooltip title={t('data-tree')} placement="right" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <Button
                          style={{ verticalAlign: 0 }}
                          icon={<SVGIcon content={<DataTree />} width={32} height={32} />}
                          onClick={clickShowExplorer}
                        />
                      </Tooltip>
                    )
                  }
                </ToolbarLeftCustom>
                {!projectGanttStore.selectObjectModel.open &&
                  <>
                    <ToolbarLeftCustom>
                      {
                        (checkingFeatureRole("feature_4d_gantt_view") || checkingFeatureRole("feature_4d_gantt_edit")) && (
                          <Tooltip title={t('4d-gantt')} placement="right" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <Button
                              style={{ verticalAlign: 0 }}
                              icon={<SVGIcon content={<GanttIcon />} width={32} height={32} />}
                              onClick={clickShowGanttPanel}
                            />
                          </Tooltip>
                        )
                      }
                    </ToolbarLeftCustom>
                    {!projectStore.visitedMode &&
                      <>
                        <ToolbarLeftCustom>
                          {
                            (checkingFeatureRole("feature_data_tree_edit") && projectStore.isExistLicenses) && (
                              <Tooltip title={t('add-data')} placement="right" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                <Button
                                  style={{ verticalAlign: 0 }}
                                  icon={<SVGIcon content={<DataAddFile />} width={32} height={32} />}
                                  onClick={clickAddResource}
                                />
                              </Tooltip>
                            )
                          }
                        </ToolbarLeftCustom>
                        <ToolbarLeftCustom activated={sketchingStore.drawMode}>
                          {
                            (checkingFeatureRole("feature_sketch") && projectStore.isExistLicenses) && (
                              <Tooltip title={sketchingStore.drawMode ? t('end-sketching') : t('add-sketch')}
                                placement="right"
                                zIndex={9998}
                                trigger={['click', 'hover']}
                                overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                <Popover overlayClassName='popover-ar-mobile' zIndex={9999} visible={sketchingStore.sketchToolVisible} placement='left' content={<SketchTool />}>
                                  <Button
                                    style={{ display: 'block', verticalAlign: 0 }}
                                    icon={<SVGIcon content={<Sketch />} width={32} height={32} />}
                                    onClick={() => {
                                      projectStore.setViewMode('default mode');
                                      if (sketchingStore.drawMode) {
                                        handleEndDraw()
                                      } else {
                                        sketchingStore.setSketchToolVisible(!sketchingStore.sketchToolVisible)
                                        if (sketchingStore.sketchToolVisible) projectStore.setCurrentHelpfeature('add_sketch')
                                        else projectStore.setCurrentHelpfeature('add_sketch', 1)
                                      }
                                    }}
                                  />
                                </Popover>
                              </Tooltip>
                            )
                          }
                        </ToolbarLeftCustom>
                        <ToolbarLeftCustom>
                          {
                            checkingFeatureRole("feature_hide") && (
                              <Tooltip title={t('hide-objects')} placement="right" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                <Button
                                  style={{ verticalAlign: 0 }}
                                  icon={<SVGIcon content={<HiddenTile />} width={32} height={32} />}
                                  onClick={() => { onChangeShowHiddenTilesList(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                                />
                              </Tooltip>
                            )
                          }
                        </ToolbarLeftCustom>
                      </>
                    }
                  </>
                }
              </Avatar.Group>
            </Fragment >
          ) : ''
      case 'projectShare':
        return (
          <Fragment>
            <ToolbarLeftItem activated={projectStore.gpsEnable}>
              <Tooltip placement={'right'} title={t('gps-mode')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  icon={<CompassOutlined />}
                  size={commonStore.buttonSize}
                  onClick={() => {
                    if (projectStore.gpsMode !== 'none')
                      projectStore.setGpsMode('fix')
                    else projectStore.setGpsMode('none')
                  }}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </Fragment>
        )
      case 'project-teams':
        return (
          <div style={{ marginTop: 10 }}>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('3d-views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project/${match.params.projectId}`)
                  }
                  icon={<GlobalOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={isMobile ? '' : t('project-settings')}>
                <Button
                  onClick={() =>
                    history.push(`/project-settings/${match.params.projectId}`)
                  }
                  icon={<SVGIcon content={<ProjectSetting />} width={32} height={32} />}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </div>
        )
      case 'project-settings':
        return (
          <div style={{ marginTop: 10 }}>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('3d-views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project/${match.params.projectId}`)
                  }
                  icon={<GlobalOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('teams')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project-teams/${match.params.projectId}`)
                  }
                  icon={<TeamOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </div>
        )
      case 'workflows':
      case 'topics':
        return (
          <div style={{ marginTop: 10 }}>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('3d-views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() => {
                    history.push(`/project/${workflowStore.workflowData && workflowStore.workflowData.projectId ? workflowStore.workflowData.projectId : match.params.projectId}`)
                  }
                  }
                  icon={<GlobalOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('teams')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project-teams/${workflowStore.workflowData && workflowStore.workflowData.projectId ? workflowStore.workflowData.projectId : match.params.projectId}`)
                  }
                  icon={<TeamOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('project-settings')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project-settings/${workflowStore.workflowData && workflowStore.workflowData.projectId ? workflowStore.workflowData.projectId : match.params.projectId}`)
                  }
                  icon={<SVGIcon content={<ProjectSetting />} width={32} height={32} />}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </div>
        )
      case 'project-dashboard':
        return (
          <div style={{ marginTop: 10 }}>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('3d-views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project/${match.params.projectId}`)
                  }
                  icon={<GlobalOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </div>
        )
      case 'feedback-editor':
        return (
          <div style={{ marginTop: 10 }}>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('3d-views')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project/${match.params.projectId}`)
                  }
                  icon={<GlobalOutlined />}
                  size={commonStore.buttonSize}
                />
              </Tooltip>
            </ToolbarLeftItem>
            <ToolbarLeftItem activated={false}>
              <Tooltip placement={'right'} title={t('project-settings')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  onClick={() =>
                    history.push(`/project-settings/${match.params.projectId}`)
                  }
                  icon={<SVGIcon content={<ProjectSetting />} width={32} height={32} />}
                />
              </Tooltip>
            </ToolbarLeftItem>
          </div>
        )
      default:
        break
    }
  }

  const  [distanceTop, setDistanceTop] = useState("50%");
  const isSmallMonitor = useMediaQuery({ query: '(max-width: 536px)' })

  let projectSettingGroup = document.querySelector('.projectSettingGroup');
  useEffect(() => {
    if (projectSettingGroup) {
      const {offsetTop ,offsetHeight } = projectSettingGroup;
      let u  = offsetTop + offsetHeight  + 5;
      if (window.innerHeight  < 400 ){
        setDistanceTop(u + "px");
      }
    }
    return () => {
    };
  }, [projectStore.isChangeLogoXDTwin,isSmallMonitor,projectSettingGroup,window.innerWidth,window.innerHeight]);

  return (
    <Fragment>
      <ToolbarLeftContainer
        distanceTop ={distanceTop}
        style={{
          display: projectStore.cleanMode ? (projectGanttStore.selectObjectModel.open ? 'block' : 'none') : 'block',
        }}>
        {currentPage ? viewByPage() : ''}
      </ToolbarLeftContainer>
    </Fragment>
  )
}

export default withRouter(
  inject('commonStore', 'projectStore', 'capturesStore', 'workflowStore', 'projectSettingStore', 'sketchingStore', 'uiStore', 'adminStore', 'projectGanttStore')(observer(ToolbarLeft))
)
