import React, { useEffect, useRef } from 'react'
import { Button, DatePicker, Popover, Select, Input, Space, Tooltip, Popconfirm } from 'antd'
import {
  ButtonCustom,
  MoreOptionWrapper,
  OptionWrapper,
  ToolbarWrapper,
  ButtonCustom1
} from './styled'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import en from 'antd/lib/date-picker/locale/en_US'
import fi from 'antd/lib/date-picker/locale/fi_FI'
import sv from 'antd/lib/date-picker/locale/sv_SE'
import es from 'antd/lib/date-picker/locale/es_ES'
import vi from 'antd/lib/date-picker/locale/vi_VN'

import { ReactComponent as FileImportIcon } from '../../../../../assets/svgs/file-import.svg'
import { ReactComponent as FileExportIcon } from '../../../../../assets/svgs/file-export.svg'
import { ReactComponent as BaselineUpdateIcon } from '../../../../../assets/svgs/baseline-update.svg'
import { ReactComponent as RedoIcon } from '../../../../../assets/svgs/redo_icon.svg'
import { ReactComponent as UndoIcon } from '../../../../../assets/svgs/undo_icon.svg'
import { ReactComponent as GridIcon } from '../../../../../assets/svgs/grid.svg'
import { ReactComponent as GanttIcon } from '../../../../../assets/svgs/gantt_panel.svg'
import { ReactComponent as CriticalPathIcon } from '../../../../../assets/svgs/critical-path.svg'
import { ReactComponent as BaselineIcon } from '../../../../../assets/svgs/baseline.svg'
import { ReactComponent as ResourcesIcon } from '../../../../../assets/svgs/resources.svg'
import { ReactComponent as AutoScheduleIcon } from '../../../../../assets/svgs/autoschedule.svg'
import { ReactComponent as ColorIcon } from '../../../../../assets/svgs/color_icon.svg'
import { ReactComponent as FitIcon } from '../../../../../assets/svgs/auto_fit.svg'
import { ReactComponent as CondensedIcon } from '../../../../../assets/svgs/condensed.svg'
import { ReactComponent as DragIcon } from '../../../../../assets/svgs/drag.svg'
import { ReactComponent as SelectModelIcon } from '../../../../../assets/svgs/select-model.svg'
import { ReactComponent as SelectObjectIcon } from '../../../../../assets/svgs/select-object.svg'
import { ReactComponent as GridSelectionIcon } from '../../../../../assets/svgs/grid-select-column.svg'
import { ReactComponent as Icon4DPlay } from '../../../../../assets/svgs/playback-play.svg'
import { ReactComponent as Icon4DPause } from '../../../../../assets/svgs/playback-pause.svg'
import { ReactComponent as Icon4DBack } from '../../../../../assets/svgs/playback-backward.svg'
import { ReactComponent as Icon4DForward } from '../../../../../assets/svgs/playback-forward.svg'
import { ReactComponent as SketchSingle } from '../../../../../assets/svgs/Sketch_Single.svg'
import { ReactComponent as SketchLine } from '../../../../../assets/svgs/Sketch_Line.svg'
import { ReactComponent as SketchArea } from '../../../../../assets/svgs/Sketch_Area.svg'
import { ReactComponent as SketchVolume } from '../../../../../assets/svgs/Sketch_Volume.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/svgs/Close.svg'

import SVGIcon from '../../../../elements/SVGIcon'
import { handleAddDateTime } from '../Gantt/ganttUtils'
import GridColumnMenu from './GridColumnMenu'
import ModalIEGantt from './ModalIEGantt'
import HelpButton from '../../../../elements/HelpButton';

const Toolbar = ({
  projectGanttStore,
  commonStore,
  projectStore,
  adminStore,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const { t } = useTranslation()

  const checkingFeatureRole = type => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const handleZoomChange = value => {
    projectGanttStore.setZoomScale({ status: value, isUpdate: true })
  }

  const handleZoomToFit = () => {
    projectGanttStore.setZoomToFit({ status: !projectGanttStore.zoomToFit.status, isUpdate: true })
  }

  const handleChangeCriticalPath = () => {
    projectGanttStore.setIsShowCriticalPath(!projectGanttStore.isShowCriticalPath)
  }
  const handleChangeAutoScheduling = () => {
    projectGanttStore.setIsAutoScheduling(!projectGanttStore.isAutoScheduling)
  }

  const handleUndo = () => {
    projectGanttStore.setUndoCount()
  }
  const handleRedo = () => {
    projectGanttStore.setRedoCount()
  }

  const handleSetTime = value => {
    projectGanttStore.setCurrentViewingTime(value);
  }

  const content = (
    <OptionWrapper>
      <GridColumnMenu />
    </OptionWrapper>
  )

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(
    () => {
      jumpForward()
    },
    projectGanttStore.isPlay4d ? projectGanttStore.playerSpeed * 1000 : null
  )

  useEffect(() => {
    if (projectGanttStore.playerMode === 'dragDate') {
      projectGanttStore.setBackOrForwardCount(0)
    }
  }, [projectGanttStore.playerMode])

  const playVideo = () => {
    projectGanttStore.setIsPlay4d(true)
  }

  const pauseVideo = () => {
    projectGanttStore.setIsPlay4d(false)
  }

  const jumpForward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      1,
      projectGanttStore.zoomScale.status
    )
    handleSetTime(updateDate)
    if (!projectGanttStore.isPlay4d && projectGanttStore.playerMode === 'dragGantt') {
      projectGanttStore.setBackOrForwardCount();
    }
  }

  const jumpBackward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      -1,
      projectGanttStore.zoomScale.status
    )
    handleSetTime(updateDate)
    if (!projectGanttStore.isPlay4d && projectGanttStore.playerMode === 'dragGantt') {
      projectGanttStore.setBackOrForwardCount();
    }
  }

  const handleToggleGrid = () => {
    projectGanttStore.setIsShowGrid(!projectGanttStore.isShowGrid)
  }

  const handleToggleChart = () => {
    projectGanttStore.setToggleChart(!projectGanttStore.showChart)
  }

  const handleShowColorTask = () => {
    projectGanttStore.setShowColorTask(!projectGanttStore.showColorTask)
  }

  const handleGenericBaseline = () => {
    if (!projectGanttStore.projectGanttData?.length) {
      return
    }
    const { data, _id } = projectGanttStore.projectGanttData[0]
    if (!data?.length) return
    projectGanttStore.baselineGenerator({ data }).then(() => {
      projectGanttStore.getProject4dGantt(projectStore.projectDetail.id)
    })
  }

  const handleToggleShowBaseline = () => {
    projectGanttStore.setIsShowBaseline(!projectGanttStore.isShowBaseline)
  }

  const handleOpenExportnImportProject = type => {
    projectGanttStore.setIsOpenModalExportnImportProject({ type, open: true })
  }

  // handle click button draw sketch
  const handleClickDrawSketch = type => {
    projectGanttStore.setSketchDrawer({ task: '', isNewTask: false, sketchIds: [], open: false, drawSketchType: type })
  }

  const handleFilterTask = value => {
    projectGanttStore.setFilterTask(value)
  }

  const handleChangeDragMode = () => {
    if (projectGanttStore.playerMode === 'dragDate') {
      projectGanttStore.setPlayerMode('dragGantt')
    } else {
      projectGanttStore.setPlayerMode('dragDate')
    }
  }

  const onCancel = () => {
    // set gantt default drawer gantt
    let _defaultGantt = {
      ...projectGanttStore.ganttDefaults,
      settings: {
        isShowGrid: projectGanttStore.isShowGrid,
        showChart: projectGanttStore.showChart,
        isShowCriticalPath: projectGanttStore.isShowCriticalPath,
        isShowBaseline: projectGanttStore.isShowBaseline,
        isAutoScheduling: projectGanttStore.isAutoScheduling,
        showColorTask: projectGanttStore.showColorTask,
        zoomToFit: projectGanttStore.zoomToFit,
        isCondensed: projectGanttStore.isCondensed,
        playerMode: projectGanttStore.playerMode,
        zoomScale: projectGanttStore.zoomScale,
        isPlay4d: projectGanttStore.isPlay4d,
        playerSpeed: projectGanttStore.playerSpeed,
      }
    }
    let _projectMetadata = projectStore.projectDetail.metadata
    if (_projectMetadata && _projectMetadata.ganttDefault && _projectMetadata.ganttDefault.length > 0) {
      let index = projectStore.projectDetail.metadata.ganttDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
      if (index > -1) {
        const temp = [..._projectMetadata.ganttDefault]
        temp[index] = _defaultGantt
        _projectMetadata.ganttDefault = temp
      } else {
        let _data = {
          ..._defaultGantt,
          userId: projectStore.projectDetail.currentUser._id,
        }
        _projectMetadata.ganttDefault.push(_data)
      }
    } else {
      _defaultGantt = {
        ..._defaultGantt,
        userId: projectStore.projectDetail.currentUser._id,
      }
      _projectMetadata.ganttDefault = [_defaultGantt]
    }
    projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
      projectStore.projectDetail.metadata = res.metadata
    }).catch(err => {
      console.log(err)
    });

    projectGanttStore.setIsShowGanttPanel(false)
    projectGanttStore.setGanttScrollPosition()
    projectGanttStore.setUndoCount(0)
    projectGanttStore.setRedoCount(0)
    projectGanttStore.setBackOrForwardCount(0)
    projectGanttStore.setIsPlay4d(false)
    projectGanttStore.setProjectGanttData([]);
    projectGanttStore.setPlayerSpeed(1);
    projectGanttStore.setGroupType('task')
    projectGanttStore.setFilterTask('')
    projectGanttStore.setToggleChart(true)
    projectGanttStore.setShowColorTask(true)
    projectGanttStore.setIsCondensed(false)
    projectGanttStore.setZoomScale({ status: 'Days', isUpdate: true })
    projectGanttStore.setZoomToFit({ status: false, isUpdate: true })
    projectGanttStore.setSelectObjectModel({ task: '', type: '', listExist: [], open: false })
    projectGanttStore.setSketchDrawer({ task: '', isNewTask: true, sketchIds: [], open: false, drawSketchType: '' })
    projectGanttStore.setColorTaskEnable()
    projectGanttStore.setGanttDefaults()
  }

  const handleToggleCondensed = () => {
    projectGanttStore.setIsCondensed(!projectGanttStore.isCondensed)
  }

  // handle click button select object model
  const handleClickSelectObjectModel = type => {
    projectGanttStore.setSelectObjectModel({ task: '', type: type, listExist: [], open: false })
  }

  return (
    <ToolbarWrapper>
      <MoreOptionWrapper>
        <Tooltip
          title={t('commons.help')}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined}
        >
          <Button
            style={{ top: '-5px', boxShadow: 'none' }}
            icon={
              <SVGIcon
                width={32} height={32}
                content={
                  <HelpButton
                    fontsize={20}
                    helppage={"4dgantt"}
                    styles={`
                          padding: 5px;
                          border-radius: 3px;`}
                  />
                }
              />
            }
          />
        </Tooltip>
        <ButtonCustom1
          style={{ width: '20px', height: '20px' }}
          icon={<SVGIcon content={<CloseIcon />} />}
          onClick={() => onCancel()}
        />
      </MoreOptionWrapper>
      <div className="toolbar-component pb-7">
        <Space>
          {checkingFeatureRole('feature_4d_gantt_edit') && (
            <>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('commons.import')}>
                <ButtonCustom
                  icon={<SVGIcon content={<FileImportIcon />} />}
                  onClick={() => handleOpenExportnImportProject('import')}
                />
              </Tooltip>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('commons.export')}>
                <ButtonCustom
                  icon={<SVGIcon content={<FileExportIcon />} />}
                  onClick={() => handleOpenExportnImportProject('export')}
                />
              </Tooltip>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.save-as-baseline')}>
                <Popconfirm
                  title={t('gantt.are-you-sure-you-want-to-overwrite-existing-baseline')}
                  onConfirm={handleGenericBaseline}
                  okText={t('commons.ok')}
                  cancelText={t('commons.cancel')}
                  placement="leftBottom"
                >
                  <ButtonCustom
                    icon={<SVGIcon content={<BaselineUpdateIcon />} />}
                  />
                </Popconfirm>
              </Tooltip>
            </>
          )}
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.undo')}>
            <ButtonCustom
              icon={<SVGIcon content={<UndoIcon />} className={projectGanttStore.isActiveUndo ? "" : "onoffstyle"} />}
              onClick={() => handleUndo()}
              disabled={!projectGanttStore.isActiveUndo}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.redo')}>
            <ButtonCustom
              icon={<SVGIcon content={<RedoIcon />} className={projectGanttStore.isActiveRedo ? "" : "onoffstyle"} />}
              onClick={() => handleRedo()}
              disabled={!projectGanttStore.isActiveRedo}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.grid')} ${projectGanttStore.isShowGrid ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<GridIcon />} className={projectGanttStore.isShowGrid ? "" : "onoffstyle"} />}
              onClick={handleToggleGrid}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.gantt-panel')} ${projectGanttStore.showChart ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<GanttIcon />}  className={projectGanttStore.showChart ? "" : "onoffstyle"} />}
              onClick={handleToggleChart}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.critical-path')} ${projectGanttStore.isShowCriticalPath ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<CriticalPathIcon />} className={projectGanttStore.isShowCriticalPath ? "" : "onoffstyle"} />}
              onClick={handleChangeCriticalPath}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.baseline')} ${projectGanttStore.isShowBaseline ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<BaselineIcon />} className={projectGanttStore.isShowBaseline ? "" : "onoffstyle"} />}
              onClick={handleToggleShowBaseline}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.resource-panel')}>
            <ButtonCustom
              icon={<SVGIcon content={<ResourcesIcon />} />}
            // onClick={handleToggleShowBaseline}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.auto-schedule')} ${projectGanttStore.isAutoScheduling ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<AutoScheduleIcon />} className={projectGanttStore.isAutoScheduling ? "" : "onoffstyle"} />}
              onClick={handleChangeAutoScheduling}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.coloring-task')} ${projectGanttStore.showColorTask ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<ColorIcon />} className={projectGanttStore.showColorTask ? "" : "onoffstyle"} />}
              onClick={handleShowColorTask}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.fit')} ${projectGanttStore.zoomToFit.status ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<FitIcon />} className={projectGanttStore.zoomToFit.status ? "" : "onoffstyle"} />}
              onClick={handleZoomToFit}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.condensed')} ${projectGanttStore.isCondensed ? t('commons.off') : t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<CondensedIcon />} className={projectGanttStore.isCondensed ? "" : "onoffstyle"} />}
              onClick={handleToggleCondensed}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${projectGanttStore.playerMode === "dragDate" ? t('Fixed 4D line') : t('Movable 4D line')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<DragIcon />} className={projectGanttStore.playerMode === "dragDate" ? "onoffstyle" : ""} />}
              onClick={handleChangeDragMode}
            />
          </Tooltip>
          <Select
            id={projectGanttStore.zoomScale.isUpdate ? `lable-zoom-gantt${projectGanttStore.zoomScale.status}` : projectGanttStore.zoomScale.status}
            className={`gantt-control radio-label ${projectGanttStore.zoomScale.isUpdate ? 'radio-label-active' : ''
              } lable-zoom-gantt`}
            style={{ width: 120 }}
            onChange={handleZoomChange}
            value={projectGanttStore.zoomScale.status}
            options={[
              { value: 'Hours', label: t('hours') },
              { value: 'Days', label: t('days') },
              { value: 'Weeks', label: t('weeks') },
              { value: 'Months', label: t('months') },
              { value: 'Quarters', label: t('quarters') },
              { value: 'Years', label: t('years') },
            ]}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.select-model')}>
            <ButtonCustom
              icon={<SVGIcon content={<SelectModelIcon />} />}
              onClick={e => { handleClickSelectObjectModel('model') }}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.select-object')}>
            <ButtonCustom
              icon={<SVGIcon content={<SelectObjectIcon />} />}
              onClick={e => { handleClickSelectObjectModel('object') }}
            />
          </Tooltip>
        </Space>
      </div>
      <div className="toolbar-component">
        <Space>
          <Input onChange={e => handleFilterTask(e.target.value)} value={projectGanttStore.filterTask} placeholder="Filter..." className="gantt_input_styled" style={{ width: 70 }} />
          <Select
            className={'gantt-control'}
            style={{ width: 100 }}
            onChange={value => projectGanttStore.setGroupType(value)}
            value={projectGanttStore.groupType}
            options={[
              { value: 'task', label: 'Task' },
              { value: 'priority', label: 'Priority' },
              { value: 'taskType', label: 'Type' },
            ]}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('gantt.colums-selection')}>
            <Popover content={content} title={t('gantt.show-columns')} trigger="click">
              <ButtonCustom
                icon={<SVGIcon content={<GridSelectionIcon />} />}
              />
            </Popover>
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('gantt.backward')}>
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DBack />} />}
              onClick={jumpBackward}
            />
          </Tooltip>
          <DatePicker
            locale={{
              ...(commonStore.language === 'fi'
                ? fi
                : commonStore.language === 'sv'
                  ? sv
                  : commonStore.language === 'es'
                    ? es
                    : commonStore.language === 'vi'
                      ? vi
                      : en),
              lang: {
                ...(commonStore.language === 'fi'
                  ? fi
                  : commonStore.language === 'sv'
                    ? sv
                    : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                        ? vi
                        : en
                ).lang,
                now: t('now'),
                ok: t('commons.ok'),
              },
            }}
            value={projectGanttStore.currentViewingTime}
            showTime
            placeholder={t('select-time')}
            onChange={handleSetTime}
            onOk={handleSetTime}
            style={{ width: 215 }}
            allowClear={false}
            disabled={projectGanttStore.playerMode === 'dragGantt'}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('gantt.forward')}>
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DForward />} />}
              onClick={jumpForward}
            />
          </Tooltip>
          <Select
            className={'gantt-control'}
            style={{ width: 70 }}
            onChange={value => projectGanttStore.setPlayerSpeed(value)}
            value={projectGanttStore.playerSpeed}
            options={[
              { value: 4, label: '4 ' },
              { value: 2, label: '2' },
              { value: 1, label: '1' },
              { value: 1 / 2, label: '1/2' },
              { value: 1 / 4, label: '1/4' },
            ]}
          />
          {projectGanttStore.isPlay4d ? (
            <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('gantt.pause')}>
              <ButtonCustom
                icon={<SVGIcon content={<Icon4DPause />} />}
                onClick={pauseVideo}
              />
            </Tooltip>
          ) : (
            <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('gantt.play')}>
              <ButtonCustom
                icon={<SVGIcon content={<Icon4DPlay />} />}
                onClick={playVideo}
              />
            </Tooltip>
          )}
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('single')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchSingle />} />}
              onClick={() => handleClickDrawSketch('single')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('line')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchLine />} />}
              onClick={() => handleClickDrawSketch('line')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('area')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchArea />} />}
              onClick={() => handleClickDrawSketch('area')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="bottom" title={t('volume')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchVolume />} />}
              onClick={() => handleClickDrawSketch('volume')}
            />
          </Tooltip>
        </Space>
      </div>
      <ModalIEGantt />
    </ToolbarWrapper>
  )
}

export default inject(
  'adminStore',
  'commonStore',
  'projectGanttStore',
  'projectStore'
)(observer(Toolbar))
