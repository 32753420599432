import {
  ArcType,
  CallbackProperty,
  Cartesian3,
  Cartographic,
  Math as CesiumMath,
  Color,
  Ellipsoid,
  HeadingPitchRange,
  Matrix4,
  PolylineArrowMaterialProperty,
  ScreenSpaceEventType
} from 'cesium'
import { inject, observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Axis_Rotate, CalRotationModel, cnvECEFtoENU, convertProjectPlaneToWGS84, dragMeshModelOnPlane, dragXMLModelOnPlane, getPointClickAndModel, getVerticalDistance, getXYZDialog, isCloudPointCesiumION, isCloudPointXDEngineEllipsoid, isCloudPointXDEnginePlane, isE57XDEngineEllipsoid, isE57XDEnginePlane, isIFCEllipsoid, isXMLEllipsoid, loadtModelOnPlane, pointGeoPosition, toDegrees, transformECFModel } from '../helper/CesiumUtils'

const MoveTile = ({ projectStore, modelStore, viewer, handler, tile, model }) => {

  function getHorizontalDistance(point1, point2, iSString) {
    var point1GeoPosition = Cartographic.fromCartesian(point1.position)
    var point2GeoPosition = Cartographic.fromCartesian(point2.position)
    var p1 = new Cartesian3.fromRadians(
      point1GeoPosition.longitude,
      point1GeoPosition.latitude,
      point1GeoPosition.height
    );
    var p2 = new Cartesian3.fromRadians(
      point2GeoPosition.longitude,
      point2GeoPosition.latitude,
      point1GeoPosition.height
    )
    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    var dz = p1.z - p2.z;
    var meters = Math.sqrt(dx * dx + dy * dy + dz * dz);

    if (iSString) {
      return meters.toFixed(1)
    }
    return meters
  }

  let viewerRef = viewer?.current?.cesiumElement;
  const tileZ = tile?.root?.boundingSphere?.radius ? (tile.root.boundingSphere.radius / 100) * 150 : 100;

  const [newPosition, setNewPosition] = useState(model.crs?.initPos?.cartographic ? new Cartesian3.fromRadians(model.crs?.initPos?.cartographic.longitude, model.crs?.initPos?.cartographic.latitude, model.crs?.initPos?.cartographic.height): undefined)

  const getDistanceCameraToModel = (cameraPos) => {
    if(!model) return
    const heightAboveGround = Cartographic.fromCartesian(cameraPos.position)
    let point1 = cameraPos;
    let point2 = newPosition ? { position: newPosition } : cameraPos;
    let point1Click = getPointClickAndModel(point1.position)
    let point2Click = getPointClickAndModel(point2.position)
    if (point1.position.z > point2.position.z) {
      let temp = point1
      point1 = point2
      point2 = temp
    }
    let _pointElevation ;
    if (point1Click && point2Click) {
      let _p1 = point1Click.cartesian ? [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height] : [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height]
      let _p2 = point2Click.cartesian ? [point2Click.cartesian.y, point2Click.cartesian.x, point2Click.cartographic.height] : [point2Click.cartesian.y, point2Click.cartesian.x, point1Click.cartographic.height]

      _pointElevation = {
        Status: 'OK',
        Points: [..._p1, ..._p2]
      }
    }
    if (_pointElevation && _pointElevation.Status === 'OK') {
      point1.pointElevation = [_pointElevation.Points[0], _pointElevation.Points[1], _pointElevation.Points[2]]
      point2.pointElevation = [_pointElevation.Points[3], _pointElevation.Points[4], _pointElevation.Points[5]]
    }
    let horizontal = getHorizontalDistance(pointGeoPosition(point1), pointGeoPosition(point2), true)
    let vertical = getVerticalDistance(pointGeoPosition(point1), pointGeoPosition(point2), true)

    let resizeAxisXYZ = 100;

    if(horizontal <= tileZ && horizontal <= tileZ ){
      resizeAxisXYZ = tileZ/4
    }else{
      resizeAxisXYZ = heightAboveGround.height/4
      if(horizontal > tileZ ){
        resizeAxisXYZ = (horizontal - tileZ)/2
      }
      if(vertical > tileZ ){
        resizeAxisXYZ = (vertical - tileZ)/2
      }
    }

    if(resizeAxisXYZ < tileZ/10){
      resizeAxisXYZ = tileZ/10
    }
    projectStore.setCameraHeight(resizeAxisXYZ)
  }

  const handleCameraMoveEnd = () => {
    getDistanceCameraToModel(viewerRef.camera)
  };

  useEffect(() => {
    if (!viewerRef?.camera) return;
    // Add event listeners for camera move start and end
    viewerRef.camera.moveEnd.addEventListener(handleCameraMoveEnd);

    // Optionally, clean up event listeners and the Cesium Viewer when the component unmounts
    return () => {
      if(viewerRef?.camera?.moveEnd){
        viewerRef.camera.moveEnd.removeEventListener(handleCameraMoveEnd);
      }
    };
  }, [viewerRef?.camera]);

  var picked = false
  /**
   * Clear all event add entitry LineX, LineY, Line X
   */
  function clearAll() {
    var entitys = viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement.entities._entities._array ? viewer.current.cesiumElement.entities._entities._array : []
    for (var i = 0; i < entitys.length; i++) {
      if (entitys[i]._name === "LineX" || entitys[i]._name === "LineY" || entitys[i]._name === "LineZ") {
        viewer.current.cesiumElement.entities.remove(entitys[i]);
        i--;
      }
    }
  }

  function disableCameraMotion(state) {
    if (viewer?.current?.cesiumElement?.scene?.screenSpaceCameraController) {
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableRotate = state
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableZoom = state
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableLook = state
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableTilt = state
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableTranslate = state
    }
  }

  const xcb = function () {
    if (modelStore.fileOrigo && tile.root) {
      let mat = Axis_Rotate(modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.fileOrigo);
      var tmpOxyz = new Cartesian3();
      tmpOxyz.x = (tile.root.boundingSphere.radius ? (tile.root.boundingSphere.radius / 100) * 150 : 100) + projectStore.cameraHeight; // length x
      tmpOxyz.y = 0.0;
      tmpOxyz.z = 0.0;

      var xyzO = new Cartesian3();
      Matrix4.multiplyByPoint(mat, tmpOxyz, xyzO);
      xyzO.x = xyzO.x + modelStore.fileOrigo.x;
      xyzO.y = xyzO.y + modelStore.fileOrigo.y;
      xyzO.z = xyzO.z + modelStore.fileOrigo.z;

      return [modelStore.fileOrigo, xyzO]
    }
  }

  const ycb = function () {
    if (modelStore.fileOrigo && tile.root) {
      let mat = Axis_Rotate(modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.fileOrigo);
      var tmpOxyz = new Cartesian3();
      tmpOxyz.x = 0.0;
      tmpOxyz.y = (tile.root.boundingSphere.radius ? (tile.root.boundingSphere.radius / 100) * 150 : 100) + projectStore.cameraHeight; // length y
      tmpOxyz.z = 0.0;

      var xyzO = new Cartesian3();
      Matrix4.multiplyByPoint(mat, tmpOxyz, xyzO);
      xyzO.x = xyzO.x + modelStore.fileOrigo.x;
      xyzO.y = xyzO.y + modelStore.fileOrigo.y;
      xyzO.z = xyzO.z + modelStore.fileOrigo.z;

      return [modelStore.fileOrigo, xyzO]
    }
  }

  const zcb = function () {
    if (modelStore.fileOrigo && tile.root) {
      let mat = Axis_Rotate(modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.fileOrigo);
      var tmpOxyz = new Cartesian3();
      tmpOxyz.x = 0.0;
      tmpOxyz.y = 0.0;
      tmpOxyz.z = (tile.root.boundingSphere.radius ? (tile.root.boundingSphere.radius / 100) * 150 : 100) + projectStore.cameraHeight ; // length z
      var xyzO = new Cartesian3();
      Matrix4.multiplyByPoint(mat, tmpOxyz, xyzO);
      xyzO.x = xyzO.x + modelStore.fileOrigo.x;
      xyzO.y = xyzO.y + modelStore.fileOrigo.y;
      xyzO.z = xyzO.z + modelStore.fileOrigo.z;

      return [modelStore.fileOrigo, xyzO]
    }
  }

  /**
   * General Axis Origo
   */
  const generalAxisOrigo = () => {
    if (model.type === "kmz") return;
    // check if distance from {0,0,0) to fileOrigo to ModelCenter if unless it is more than 5000 then show else don’t display local origo XYZ axis
    if (model.data && model.data?.saveMatrix?.xyzLocal && model.crs.ModelCenter) {
      let xx = model.crs.ModelCenter[0]
      let yy = model.crs.ModelCenter[1]
      let zz = model.crs.ModelCenter[2]
      if (model.type === 'landxml') {
        xx = modelStore.xyzLocal[0] + model.crs.ModelCenter[0]
        yy = modelStore.xyzLocal[1] + model.crs.ModelCenter[1]
        zz = modelStore.xyzLocal[2] + model.crs.ModelCenter[2]
      }
      let _distance = Math.sqrt(xx * xx + yy * yy + zz * zz)

      if (_distance < 5000) {
        viewer.current.cesiumElement.entities.add({
          name: 'LineX',
          polyline: {
            positions: new CallbackProperty(xcb, false),
            width: 15,
            arcType: ArcType.NONE,
            material: new PolylineArrowMaterialProperty(
              Color.GREEN
            )
          }
        })

        viewer.current.cesiumElement.entities.add({
          name: 'LineY',
          polyline: {
            positions: new CallbackProperty(ycb, false),
            width: 15,
            arcType: ArcType.NONE,
            material: new PolylineArrowMaterialProperty(
              Color.RED
            )
          }
        })

        viewer.current.cesiumElement.entities.add({
          name: 'LineZ',
          polyline: {
            positions: new CallbackProperty(zcb, false),
            width: 15,
            arcType: ArcType.NONE,
            material: new PolylineArrowMaterialProperty(
              Color.BLUE
            )
          }
        })
        viewer.current.cesiumElement.scene.requestRender()
      }
    }
  }

  //Effect when component MoveTile call
  useEffect(() => {
    if( isXMLEllipsoid(model) || isIFCEllipsoid(model) || isCloudPointXDEngineEllipsoid(model) || isE57XDEngineEllipsoid(model)) return ;
    if (model.type === 'landxml' || model.type === 'ifc' || model.type === 'cad') {
      modelStore.initFromMatrix(projectStore.projectDetail.tilesetData.RefPoint ? Cartesian3.fromDegrees(projectStore.projectDetail.tilesetData.RefPoint[1], projectStore.projectDetail.tilesetData.RefPoint[0], projectStore.projectDetail.tilesetData.RefPoint[2]) : new Cartesian3(), model.crs)
    } else {
      if (model.data?.ionAssetId) {
        // if ION
        modelStore.initFromMatrix({ x: 0, y: 0, z: 0 }, model.crs)
      } else {
        // not ION
        modelStore.initFromMatrix({ x: 0, y: 0, z: 0 }, model.crs)
      }
    }
    //zoom to model
    viewer.current.cesiumElement.flyTo(tile, {
      duration: 0.2,
      offset: new HeadingPitchRange(0, CesiumMath.toRadians(-45), 0)
    });

    if (model.type === 'kmz') return
    var orginalPos = null // vị trí mouse click
    var newLatLong = null //vị trí mouse moving đến
    var startMovePos = null

    if (!viewer.current.cesiumElement.scene) return

    var heightMove = 1
    if (model.sourceType === 'external' && (model.type === 'unknown' || model.type === 'kmz')) {

    }
    else {
      handler.setInputAction(function (click) {
        var pickedObject = viewer.current.cesiumElement.scene.pick(click.position)
        if (!pickedObject) return
        picked = false
        if (!tile?.boundingSphere) return
        heightMove = viewer.current.cesiumElement.camera.distanceToBoundingSphere(tile?.boundingSphere)
        if (heightMove > 1000) { heightMove = 1 } else {
          heightMove = heightMove / 1000
          if (heightMove > 0.3) heightMove = 0.3
          if (heightMove < 0.05) heightMove = 0.05
        }

        startMovePos = click.position.clone()
        orginalPos = viewer.current.cesiumElement.camera.pickEllipsoid(click.position, viewer.current.cesiumElement.scene.globe.ellipsoid)
        if (pickedObject.primitive === tile) {
          picked = 'F' //free
          disableCameraMotion(false)
        } else {
          if (!pickedObject.id) return
          if (pickedObject.id.name === 'LineX') {
            picked = 'X'
            disableCameraMotion(false)
          } else if (pickedObject.id.name === 'LineY') {
            picked = 'Y'
            disableCameraMotion(false)
          } else if (pickedObject.id.name === 'LineZ') {
            picked = 'Z'
            disableCameraMotion(false)
          }
        }
      }, ScreenSpaceEventType.LEFT_DOWN)

      handler.setInputAction(function (movement) {
        if (!picked) return
        let newPos = viewer.current.cesiumElement.camera.pickEllipsoid(movement.endPosition, viewer.current.cesiumElement.scene.globe.ellipsoid)
        if (!newPos) return
        newLatLong = Cartographic.fromCartesian(newPos)
        const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem
        let newLong = newLatLong.longitude
        let newLat = newLatLong.latitude
        let newHeight = newLatLong.height
        if (!orginalPos) return

        let prevLatLong = Cartographic.fromCartesian(orginalPos)
        if (picked === 'Y') newLong = prevLatLong.longitude
        if (picked === 'X') newLat = prevLatLong.latitude
        if (picked === 'Z') {
          newLong = prevLatLong.longitude
          newLat = prevLatLong.latitude
          newHeight = prevLatLong.height + (startMovePos.y - movement.startPosition.y) * heightMove
        }
        newLatLong = Cartesian3.fromRadians(newLong, newLat, newHeight)
        setNewPosition(newLatLong)

        // for external model with identical matrix 
        if (!model.data.ext && (model.type === 'unknown' || model.type === 'kmz') && model.data.isIdenticalMatrix) {
          var firstLocalPos = orginalPos;
          var lastLocalPos = newLatLong;

          //// calculate vector translation for model   
          var vecTran = new Cartesian3();
          vecTran.x = lastLocalPos.x - firstLocalPos.x;
          vecTran.y = lastLocalPos.y - firstLocalPos.y;
          vecTran.z = lastLocalPos.z - firstLocalPos.z;

          let vecDrag = new Cartesian3()
          vecDrag.x = modelStore.fileOrigo.x + vecTran.x;
          vecDrag.y = modelStore.fileOrigo.y + vecTran.y;
          vecDrag.z = modelStore.fileOrigo.z + vecTran.z;

          let car3xyzLocal = Cartesian3.fromArray(modelStore.xyzLocal)
          if ((['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
            car3xyzLocal = new Cartesian3.fromDegrees(modelStore.xyzLocal[1], modelStore.xyzLocal[0], modelStore.xyzLocal[2])
          }
          const _objResult = transformECFModel(modelStore.fileOrigo, car3xyzLocal, vecDrag);

          let xLocal = _objResult.xyzLocal.x
          let yLocal = _objResult.xyzLocal.y
          let zLocal = _objResult.xyzLocal.z

          if ((['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
            var catographic = Cartographic.fromCartesian(_objResult.xyzLocal, Ellipsoid.WGS84, catographic);
            xLocal = catographic.latitude * CesiumMath.DEGREES_PER_RADIAN;
            yLocal = catographic.longitude * CesiumMath.DEGREES_PER_RADIAN;
            zLocal = catographic.height;
          }

          // if (picked === 'Z') { //change Z then X,Y not change
          //   xLocal = modelStore.xyzLocal[0]
          //   yLocal = modelStore.xyzLocal[1]
          //   _objResult.fileOrigo.x = modelStore.fileOrigo.x
          //   _objResult.fileOrigo.y = modelStore.fileOrigo.y
          // } else if (picked === 'Y') { //change Y then X,Z not change
          //   xLocal = modelStore.xyzLocal[0]
          //   zLocal = modelStore.xyzLocal[2]
          //   _objResult.fileOrigo.x = modelStore.fileOrigo.x
          //   _objResult.fileOrigo.z = modelStore.fileOrigo.z
          // } else if (picked === 'X') { //change X then Y,Z not change
          //   yLocal = modelStore.xyzLocal[1]
          //   zLocal = modelStore.xyzLocal[2]
          //   _objResult.fileOrigo.y = modelStore.fileOrigo.y
          //   _objResult.fileOrigo.z = modelStore.fileOrigo.z
          // } else if (picked === 'F') {
          //   zLocal = modelStore.xyzLocal[2] //change X, Y then Z not change
          //   _objResult.fileOrigo.z = modelStore.fileOrigo.z
          // }

          modelStore.setFileOrigo(_objResult.fileOrigo)
          modelStore.setxyzLocal([xLocal, yLocal, zLocal])
          modelStore.setUpdateFromViewer(true)

          tile.root.transform = _objResult.modelMatrix
        } else {
          var refProject = projectStore.projectDetail.tilesetData.RefPoint ? Cartesian3.fromDegrees(projectStore.projectDetail.tilesetData.RefPoint[1], projectStore.projectDetail.tilesetData.RefPoint[0], projectStore.projectDetail.tilesetData.RefPoint[2]) : undefined
          var refLocalProject = Cartesian3.fromArray(projectStore.projectDetail.tilesetData.refLocalProject ? projectStore.projectDetail.tilesetData.refLocalProject : model.crs.LocalOrg)
          var localOrg = Cartesian3.fromArray(model.crs.LocalOrg)
          var modelCenter = Cartesian3.fromArray(model.crs.ModelCenter)
          if ((!['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'metre') && !isCloudPointXDEngineEllipsoid(model)) {

            const firstLocalPos = cnvECEFtoENU(refProject, orginalPos);
            const lastLocalPos = cnvECEFtoENU(refProject, newLatLong);

            //// calculate vector translation for model   
            let vercTran = new Cartesian3()
            vercTran.x = lastLocalPos.x - firstLocalPos.x;
            vercTran.y = lastLocalPos.y - firstLocalPos.y;
            vercTran.z = -(lastLocalPos.z - firstLocalPos.z);

            let currentTran = Cartesian3.fromArray(modelStore.xyzLocal)

            let _objResult
            if ((model.type === 'landxml') || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1) || isCloudPointXDEnginePlane(model) || (model.type === 'e57' && model?.crs?.georeferenced === true)) {
              currentTran = getXYZDialog(refLocalProject, localOrg, Cartesian3.fromArray(modelStore.xyzLocal));
              _objResult = dragXMLModelOnPlane(
                currentTran,
                vercTran,
                refProject,
                refLocalProject,
                localOrg,
                modelCenter,
                modelStore.realHpr.h,
                modelStore.realHpr.p,
                modelStore.realHpr.r,
                modelStore.scale,
                true,
                projectStore.projectDetail.headingRotation
              )
            } else { //mesh model and other model

              _objResult = dragMeshModelOnPlane(
                currentTran,
                vercTran,
                refProject,
                refLocalProject,
                modelCenter,
                modelStore.realHpr.h,
                modelStore.realHpr.r,
                modelStore.realHpr.p,
                modelStore.scale,
                false,
                projectStore.projectDetail.headingRotation
              )
            }

            let xLocal = _objResult.xyzLocal.x + refLocalProject.x
            let yLocal = _objResult.xyzLocal.y + refLocalProject.y
            let zLocal = _objResult.xyzLocal.z + refLocalProject.z

            if ((model.type === 'landxml') || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1) || isCloudPointXDEnginePlane(model) || (model.type === 'e57' && model?.crs?.georeferenced === true)) {
              xLocal = _objResult.xyzLocal.x
              yLocal = _objResult.xyzLocal.y
              zLocal = _objResult.xyzLocal.z
            }

            if (picked === 'Z') { //change Z then X,Y not change
              xLocal = modelStore.xyzLocal[0]
              yLocal = modelStore.xyzLocal[1]
            } else if (picked === 'Y') { //change Y then X,Z not change
              xLocal = modelStore.xyzLocal[0]
              zLocal = modelStore.xyzLocal[2]
            } else if (picked === 'X') { //change X then Y,Z not change
              yLocal = modelStore.xyzLocal[1]
              zLocal = modelStore.xyzLocal[2]
            } else if (picked === 'F') {
              zLocal = modelStore.xyzLocal[2] //change X, Y then Z not change
            }

            // if project not georeferenced and model type not cloudpoint cesium ion, e57
            if (!projectStore.projectDetail.tilesetData.georeferenced && isCloudPointCesiumION(model) && !isE57XDEnginePlane(model)) {
              var convertPoint2 = convertProjectPlaneToWGS84(xLocal, yLocal, zLocal, coordinateSystemCode, '4326')
              let _fileorigo2 = Cartesian3.fromDegrees(convertPoint2[0], convertPoint2[1], convertPoint2[2])
              modelStore.setFileOrigo(_fileorigo2)
              const t = CalRotationModel(modelStore.fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.scale);
              if (t) tile.root.transform = t
            } else {
              modelStore.setFileOrigo(_objResult.fileOrigo)
              tile.root.transform = _objResult.modelMatrix
            }

            modelStore.setxyzLocal([xLocal, yLocal, zLocal])
            modelStore.setUpdateFromViewer(true)           
          } else { // for project = 4326 or model cloundpoint used CesiumION or mesh model not refLocalProject
            const firstLocalPos = orginalPos;
            const lastLocalPos = newLatLong;

            //// calculate vector translation for model   
            let vecTran = new Cartesian3();
            vecTran.x = lastLocalPos.x - firstLocalPos.x;
            vecTran.y = lastLocalPos.y - firstLocalPos.y;
            vecTran.z = lastLocalPos.z - firstLocalPos.z;

            let vecDrag = new Cartesian3()
            vecDrag.x = modelStore.fileOrigo.x + vecTran.x;
            vecDrag.y = modelStore.fileOrigo.y + vecTran.y;
            vecDrag.z = modelStore.fileOrigo.z - vecTran.z;
            
            let x = toDegrees(vecDrag)
            let xLocal = x[1]
            let yLocal = x[0]
            let zLocal = x[2]

            if (picked === 'Z') { //change Z then X,Y not change
              xLocal = modelStore.xyzLocal[0]
              yLocal = modelStore.xyzLocal[1]
            } else if (picked === 'Y') { //change Y then X,Z not change
              yLocal = modelStore.xyzLocal[1]
              zLocal = modelStore.xyzLocal[2]
            } else if (picked === 'X') { //change X then Y,Z not change
              xLocal = modelStore.xyzLocal[0]
              zLocal = modelStore.xyzLocal[2]
            } else if (picked === 'F') {
              zLocal = modelStore.xyzLocal[2] //change X, Y then Z not change
            }

            if (modelStore.geoHeight) {
              zLocal = zLocal - modelStore.geoHeight
            }
            modelStore.setFileOrigo(Cartesian3.fromDegrees(yLocal, xLocal, zLocal))
            const t = CalRotationModel(modelStore.fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.scale);
            if (t) tile.root.transform = t           

            modelStore.setxyzLocal([xLocal, yLocal, zLocal])
            modelStore.setUpdateFromViewer(true)
          }
        }

        //update orginal pos = new pos
        orginalPos = newLatLong
        startMovePos = movement.endPosition
      }, ScreenSpaceEventType.MOUSE_MOVE)

      handler.setInputAction(function (movement) {
        if (!picked) return
        picked = false
       
        disableCameraMotion(true)
      }, ScreenSpaceEventType.LEFT_UP)

    }

    return () => {
      if (handler) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN)
        handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
        handler.removeInputAction(ScreenSpaceEventType.LEFT_UP)
      }
      clearAll()
      disableCameraMotion(true)
      projectStore.setCurrentInitLocation(false)
    }
  }, [])

  useEffect(() => {
    if (projectStore.isUpdateViewOrigo) {
      if( isXMLEllipsoid(model) || isIFCEllipsoid(model) || isCloudPointXDEngineEllipsoid(model) || isE57XDEngineEllipsoid(model)) return ;
      projectStore.setIsUpdateViewOrigo(false) // reset update view change origo to false
      clearAll() // clear origo LineX, LineY, LineZ if exist
      generalAxisOrigo() // Create origo axis
      const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem
      if (!model.data.ext && (model.type === 'unknown' || model.type === "kmz") && model.data.isIdenticalMatrix) {
        let xyzLocal = new Cartesian3.fromArray(modelStore.xyzLocal)
        if ((['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
          xyzLocal = new Cartesian3.fromDegrees(modelStore.xyzLocal[1], modelStore.xyzLocal[0], modelStore.xyzLocal[2])
        }

        const t = transformECFModel(modelStore.fileOrigo, xyzLocal);
        if (t)
          tile.root.transform = t.modelMatrix;
      } else {

        if (model.sourceType === 'external' && (model.type === 'unknown' || model.type === "kmz")) {

        } else {
          if (modelStore.fileOrigo && JSON.stringify(modelStore.fileOrigo) !== JSON.stringify({ x: 0, y: 0, z: 0 })) {
            if ((!['4326', '4756'].includes(coordinateSystemCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'metre') && !isCloudPointXDEngineEllipsoid(model) && !isCloudPointCesiumION(model) && projectStore.projectDetail.tilesetData.georeferenced) {
              let refProject = new Cartesian3.fromDegrees(projectStore.projectDetail.tilesetData.RefPoint[1], projectStore.projectDetail.tilesetData.RefPoint[0], projectStore.projectDetail.tilesetData.RefPoint[2])
              let refLocalProject = Cartesian3.fromArray(projectStore.projectDetail.tilesetData.refLocalProject ? projectStore.projectDetail.tilesetData.refLocalProject : model.crs.LocalOrg)

              let _modelmatrix = loadtModelOnPlane(refProject, refLocalProject, Cartesian3.fromArray(model.crs.LocalOrg), Cartesian3.fromArray(modelStore.xyzLocal), Cartesian3.fromArray(model.crs.ModelCenter), modelStore.hpr.h, modelStore.hpr.p, modelStore.hpr.r, modelStore.scale, model, projectStore.projectDetail.headingRotation)
              if (_modelmatrix && tile?.root?.transform) tile.root.transform = _modelmatrix;
            } else { // for project 4326 or model clound point
              if (!(model.sourceType === 'external' && model.type === 'unknown')) {
                const t = CalRotationModel(modelStore.fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), modelStore.realHpr.h, modelStore.realHpr.p, modelStore.realHpr.r, modelStore.scale);
                if (t && tile?.root?.transform) tile.root.transform = t
              }
            }
          }
        }

      }
    }
  }, [projectStore.isUpdateViewOrigo])
  
  useEffect(() => {
    if (!modelStore.hpr) return
    if( isXMLEllipsoid(model) || isIFCEllipsoid(model) || isCloudPointXDEngineEllipsoid(model) || isE57XDEngineEllipsoid(model)) return ;
    const { fileOrigo, xyzLocal, crs, scale, realHpr, hpr } = modelStore;
    const { projectDetail } = projectStore;

    if (fileOrigo && !model.data?.isIdenticalMatrix && fileOrigo.x !== 0 && fileOrigo.y !== 0 && fileOrigo.z !== 0) {
      projectStore.setIsUpdateViewOrigo(false);

      const coordinateSystemCode = (projectDetail.tilesetData.coordinateSystem && projectDetail.tilesetData.coordinateSystem.code) || projectDetail.coordinateSystem;
      const isMetreUnit = projectDetail.tilesetData.coordinateSystem.unit === 'metre';
      const isNot4326Or4756 = !['4326', '4756'].includes(coordinateSystemCode);
      const isNotCloudPoint = !isCloudPointXDEngineEllipsoid(model) && !isCloudPointCesiumION(model);

      if ((isNot4326Or4756 || isMetreUnit) && model.data.ext && isNotCloudPoint && projectDetail.tilesetData.georeferenced) {
        const refPoint = Cartesian3.fromDegrees(projectDetail.tilesetData.RefPoint[1], projectDetail.tilesetData.RefPoint[0], projectDetail.tilesetData.RefPoint[2]);
        const refLocalProject = Cartesian3.fromArray(projectDetail.tilesetData.refLocalProject ? projectDetail.tilesetData.refLocalProject : crs.LocalOrg);
        const _modelCenter = Cartesian3.fromArray(model.crs.ModelCenter);
        const _xyzLocal = Cartesian3.fromArray(xyzLocal);
        const _localOrg = Cartesian3.fromArray(model.crs.LocalOrg)

        const _modelmatrix = loadtModelOnPlane(refPoint, refLocalProject, _localOrg, _xyzLocal, _modelCenter, hpr.h, hpr.p, hpr.r, scale, model, projectDetail.headingRotation);

        if (_modelmatrix && tile?.root?.transform) {
          tile.root.transform = _modelmatrix;
        }
      } else {
        if (!(model.sourceType === 'external' && model.type === 'unknown')) {
          const t = CalRotationModel(fileOrigo, new Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]), realHpr.h, realHpr.p, realHpr.r, scale);

          if (t && tile?.root?.transform) {
            tile.root.transform = t;
          }
        }
      }
    }
  }, [modelStore.hpr.h, modelStore.hpr.p, modelStore.hpr.r, modelStore.scale]);

  return (<></>)
}
export default inject('projectStore', 'modelStore')(observer(MoveTile))
